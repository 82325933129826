/*  !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

    PLEASE, if you change a color here, change it in Color_Library in class.ts
    This class mirrors our colors defined here.

    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */
@import url("https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i");
/* I want all the things */
@import url("https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined");
@import url("https://use.typekit.net/fpy7xac.css");
@font-face {
  font-family: "CenturyGothicStd";
  src: url("/assets/fonts/CenturyGothicStd.ttf") format("truetype"), url("/assets/fonts/CenturyGothicStd.eot") format("eot"), url("/assets/fonts/CenturyGothicStd.svg") format("svg"), url("/assets/fonts/CenturyGothicStd.woff") format("woff"), url("/assets/fonts/CenturyGothicStd.otf") format("otf");
}

@font-face {
  font-family: "CenturyGothicBld";
  src: url("/assets/fonts/CenturyGothicStd-Bold.ttf") format("truetype"), url("/assets/fonts/CenturyGothicStd-Bold.eot") format("eot"), url("/assets/fonts/CenturyGothicStd-Bold.svg") format("svg"), url("/assets/fonts/CenturyGothicStd-Bold.woff") format("woff"), url("/assets/fonts/CenturyGothicStd-Bold.otf") format("otf");
}

@font-face {
  font-family: "BLOKK";
  src: url("/assets/fonts/BLOKKNeue-Regular.ttf") format("truetype"), url("/assets/fonts/BLOKKNeue-Regular.eot") format("eot"), url("/assets/fonts/BLOKKNeue-Regular.svg") format("svg"), url("/assets/fonts/BLOKKNeue-Regular.woff") format("woff"), url("/assets/fonts/BLOKKNeue-Regular.otf") format("otf");
}

@media (hover: none) {
  ngb-tooltip-window {
    display: none !important;
  }
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #999999;
  --gray-dark: #505050;
  --black: #000;
  --white: #fff;
  --gray: #999999;
  --blue-extra-super-light: #E5F2F2;
  --blue-super-light: #F2F6FA;
  --blue-extra-light: #C6D1E1;
  --blue-light: #0077bf;
  --blue-dark: #0066a3;
  --blue-sky: #3CA7E6;
  --blue-pale: #56AADB;
  --blue-pulse: #0076BC;
  --teal-extra-light: #BEE4E3;
  --teal-light: #67CBC9;
  --teal-dark: #43B4B2;
  --violet-extra-light: #E5CEE0;
  --violet-light: #AB5B9B;
  --violet-dark: #945487;
  --green-extra-light: #DFE8D4;
  --green-light: #B5D190;
  --green-lime: #70963D;
  --green-dark: #81AC48;
  --orange-extra-light: #FCEACE;
  --orange-light: #FC9552;
  --orange-dark: #F58238;
  --orange-gold: #FF9E00;
  --pulse-benchmark: #70963D;
  --red-extra-light: #E9CECD;
  --red-lighter: #D95350;
  --red-light: #AA3C39;
  --red-dark: #922E2B;
  --highlight-green: #E2EAD8;
  --highlight-teal: #EAF7FF;
  --covid-blue: #44A4DB;
  --primary: #0066A3;
  --secondary: #999999;
  --success: #81AC48;
  --info: #67CBC9;
  --warning: #F0AD4E;
  --danger: #D9534F;
  --light: #f8f8f8;
  --dark: #505050;
  --primary-dark: #273953;
  --mid: #999999;
  --mid-light: #f8f8f8;
  --accent: #81AC48;
  --accent-light: #B5D190;
  --accent-extra-light: #DFE8D4;
  --aha: #003087;
  --aha-blue: #B9D9EB;
  --black: #000;
  --blue-dark: #0066a3;
  --blue-light: #0077bf;
  --blue-sky: #3CA7E6;
  --blue-pale: #56AADB;
  --blue-pulse: #0076BC;
  --blue-extra-super-light: #E5F2F2;
  --blue-extra-light: #C6D1E1;
  --blue-super-light: #F2F6FA;
  --covid-blue: #44A4DB;
  --gray-dark: #505050;
  --gray-light: #999999;
  --gray-100: #f8f8f8;
  --gray-150: #F0F0F1;
  --gray-175: #EFEFEF;
  --gray-200: #e5e5e5;
  --gray-300: #dddddd;
  --gray-400: #cccccc;
  --gray-500: #ababab;
  --gray-600: #999999;
  --gray-700: #707070;
  --gray-800: #505050;
  --gray-900: #303030;
  --green-dark: #81AC48;
  --green-lime: #70963D;
  --green-light: #B5D190;
  --green-extra-light: #DFE8D4;
  --highlight-green: #E2EAD8;
  --highlight-teal: #EAF7FF;
  --orange-dark: #F58238;
  --orange-gold: #FF9E00;
  --orange-light: #FC9552;
  --orange-extra-light: #FCEACE;
  --primary-light: #F2F6FA;
  --primary-mid: #0077bf;
  --primary-mid-light: #afc6ec;
  --pulse-blue: #0176bd;
  --pulse-benchmark: #70963D;
  --red-dark: #922E2B;
  --red-light: #AA3C39;
  --red-lighter: #D95350;
  --red-extra-light: #E9CECD;
  --teal-dark: #43B4B2;
  --teal-light: #67CBC9;
  --teal-extra-light: #BEE4E3;
  --violet-dark: #945487;
  --violet-light: #AB5B9B;
  --violet-extra-light: #E5CEE0;
  --white: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #303030;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 500;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #0066A3;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #003657;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #999999;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-family: "Poppins", "proxima-nova", "Helvetica", "Arial", "sans-serif";
  font-weight: 300;
  line-height: 1.2;
  color: #505050;
}

h1, .h1 {
  font-size: 2.1875rem;
}

h2, .h2 {
  font-size: 1.75rem;
}

h3, .h3 {
  font-size: 1.53125rem;
}

h4, .h4 {
  font-size: 1.3125rem;
}

h5, .h5 {
  font-size: 1.09375rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #999999;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #999999;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #303030;
  border-radius: 0;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 500;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #303030;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -1rem;
  margin-left: -1rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #303030;
}

.table th,
.table td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dddddd;
}

.table tbody + tbody {
  border-top: 2px solid #dddddd;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #dddddd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dddddd;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f8f8f8;
}

.table-hover tbody tr:hover {
  color: #303030;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d4e5;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7aafcf;
}

.table-hover .table-primary:hover {
  background-color: #a5c9de;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #a5c9de;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e2e2e2;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-secondary:hover {
  background-color: #d5d5d5;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d5d5d5;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #dce8cc;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #bdd4a0;
}

.table-hover .table-success:hover {
  background-color: #d0e0ba;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #d0e0ba;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #d4f0f0;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #b0e4e3;
}

.table-hover .table-info:hover {
  background-color: #c1e9e9;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #c1e9e9;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fbe8cd;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f7d4a3;
}

.table-hover .table-warning:hover {
  background-color: #f9ddb5;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #f9ddb5;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f4cfce;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #eba6a3;
}

.table-hover .table-danger:hover {
  background-color: #efbbb9;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #efbbb9;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfd;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-light:hover {
  background-color: #f0f0f0;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #f0f0f0;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cecece;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a4a4a4;
}

.table-hover .table-dark:hover {
  background-color: #c1c1c1;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #c1c1c1;
}

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #c3c8cf;
}

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #8f98a6;
}

.table-hover .table-primary-dark:hover {
  background-color: #b5bbc4;
}

.table-hover .table-primary-dark:hover > td,
.table-hover .table-primary-dark:hover > th {
  background-color: #b5bbc4;
}

.table-mid,
.table-mid > th,
.table-mid > td {
  background-color: #e2e2e2;
}

.table-mid th,
.table-mid td,
.table-mid thead th,
.table-mid tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-mid:hover {
  background-color: #d5d5d5;
}

.table-hover .table-mid:hover > td,
.table-hover .table-mid:hover > th {
  background-color: #d5d5d5;
}

.table-mid-light,
.table-mid-light > th,
.table-mid-light > td {
  background-color: #fdfdfd;
}

.table-mid-light th,
.table-mid-light td,
.table-mid-light thead th,
.table-mid-light tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-mid-light:hover {
  background-color: #f0f0f0;
}

.table-hover .table-mid-light:hover > td,
.table-hover .table-mid-light:hover > th {
  background-color: #f0f0f0;
}

.table-accent,
.table-accent > th,
.table-accent > td {
  background-color: #dce8cc;
}

.table-accent th,
.table-accent td,
.table-accent thead th,
.table-accent tbody + tbody {
  border-color: #bdd4a0;
}

.table-hover .table-accent:hover {
  background-color: #d0e0ba;
}

.table-hover .table-accent:hover > td,
.table-hover .table-accent:hover > th {
  background-color: #d0e0ba;
}

.table-accent-light,
.table-accent-light > th,
.table-accent-light > td {
  background-color: #eaf2e0;
}

.table-accent-light th,
.table-accent-light td,
.table-accent-light thead th,
.table-accent-light tbody + tbody {
  border-color: #d9e7c5;
}

.table-hover .table-accent-light:hover {
  background-color: #deeace;
}

.table-hover .table-accent-light:hover > td,
.table-hover .table-accent-light:hover > th {
  background-color: #deeace;
}

.table-accent-extra-light,
.table-accent-extra-light > th,
.table-accent-extra-light > td {
  background-color: #f6f9f3;
}

.table-accent-extra-light th,
.table-accent-extra-light td,
.table-accent-extra-light thead th,
.table-accent-extra-light tbody + tbody {
  border-color: #eef3e9;
}

.table-hover .table-accent-extra-light:hover {
  background-color: #e9f1e2;
}

.table-hover .table-accent-extra-light:hover > td,
.table-hover .table-accent-extra-light:hover > th {
  background-color: #e9f1e2;
}

.table-aha,
.table-aha > th,
.table-aha > td {
  background-color: #b8c5dd;
}

.table-aha th,
.table-aha td,
.table-aha thead th,
.table-aha tbody + tbody {
  border-color: #7a93c1;
}

.table-hover .table-aha:hover {
  background-color: #a7b7d5;
}

.table-hover .table-aha:hover > td,
.table-hover .table-aha:hover > th {
  background-color: #a7b7d5;
}

.table-aha-blue,
.table-aha-blue > th,
.table-aha-blue > td {
  background-color: #ebf4f9;
}

.table-aha-blue th,
.table-aha-blue td,
.table-aha-blue thead th,
.table-aha-blue tbody + tbody {
  border-color: #dbebf5;
}

.table-hover .table-aha-blue:hover {
  background-color: #d7e9f3;
}

.table-hover .table-aha-blue:hover > td,
.table-hover .table-aha-blue:hover > th {
  background-color: #d7e9f3;
}

.table-black,
.table-black > th,
.table-black > td {
  background-color: #b8b8b8;
}

.table-black th,
.table-black td,
.table-black thead th,
.table-black tbody + tbody {
  border-color: #7a7a7a;
}

.table-hover .table-black:hover {
  background-color: #ababab;
}

.table-hover .table-black:hover > td,
.table-hover .table-black:hover > th {
  background-color: #ababab;
}

.table-blue-dark,
.table-blue-dark > th,
.table-blue-dark > td {
  background-color: #b8d4e5;
}

.table-blue-dark th,
.table-blue-dark td,
.table-blue-dark thead th,
.table-blue-dark tbody + tbody {
  border-color: #7aafcf;
}

.table-hover .table-blue-dark:hover {
  background-color: #a5c9de;
}

.table-hover .table-blue-dark:hover > td,
.table-hover .table-blue-dark:hover > th {
  background-color: #a5c9de;
}

.table-blue-light,
.table-blue-light > th,
.table-blue-light > td {
  background-color: #b8d9ed;
}

.table-blue-light th,
.table-blue-light td,
.table-blue-light thead th,
.table-blue-light tbody + tbody {
  border-color: #7ab8de;
}

.table-hover .table-blue-light:hover {
  background-color: #a4cee8;
}

.table-hover .table-blue-light:hover > td,
.table-hover .table-blue-light:hover > th {
  background-color: #a4cee8;
}

.table-blue-sky,
.table-blue-sky > th,
.table-blue-sky > td {
  background-color: #c8e6f8;
}

.table-blue-sky th,
.table-blue-sky td,
.table-blue-sky thead th,
.table-blue-sky tbody + tbody {
  border-color: #9ad1f2;
}

.table-hover .table-blue-sky:hover {
  background-color: #b1dcf5;
}

.table-hover .table-blue-sky:hover > td,
.table-hover .table-blue-sky:hover > th {
  background-color: #b1dcf5;
}

.table-blue-pale,
.table-blue-pale > th,
.table-blue-pale > td {
  background-color: #d0e7f5;
}

.table-blue-pale th,
.table-blue-pale td,
.table-blue-pale thead th,
.table-blue-pale tbody + tbody {
  border-color: #a7d3ec;
}

.table-hover .table-blue-pale:hover {
  background-color: #bbdcf1;
}

.table-hover .table-blue-pale:hover > td,
.table-hover .table-blue-pale:hover > th {
  background-color: #bbdcf1;
}

.table-blue-pulse,
.table-blue-pulse > th,
.table-blue-pulse > td {
  background-color: #b8d9ec;
}

.table-blue-pulse th,
.table-blue-pulse td,
.table-blue-pulse thead th,
.table-blue-pulse tbody + tbody {
  border-color: #7ab8dc;
}

.table-hover .table-blue-pulse:hover {
  background-color: #a4cee7;
}

.table-hover .table-blue-pulse:hover > td,
.table-hover .table-blue-pulse:hover > th {
  background-color: #a4cee7;
}

.table-blue-extra-super-light,
.table-blue-extra-super-light > th,
.table-blue-extra-super-light > td {
  background-color: #f8fbfb;
}

.table-blue-extra-super-light th,
.table-blue-extra-super-light td,
.table-blue-extra-super-light thead th,
.table-blue-extra-super-light tbody + tbody {
  border-color: #f1f8f8;
}

.table-hover .table-blue-extra-super-light:hover {
  background-color: #e8f2f2;
}

.table-hover .table-blue-extra-super-light:hover > td,
.table-hover .table-blue-extra-super-light:hover > th {
  background-color: #e8f2f2;
}

.table-blue-extra-light,
.table-blue-extra-light > th,
.table-blue-extra-light > td {
  background-color: #eff2f7;
}

.table-blue-extra-light th,
.table-blue-extra-light td,
.table-blue-extra-light thead th,
.table-blue-extra-light tbody + tbody {
  border-color: #e1e7ef;
}

.table-hover .table-blue-extra-light:hover {
  background-color: #dee4ef;
}

.table-hover .table-blue-extra-light:hover > td,
.table-hover .table-blue-extra-light:hover > th {
  background-color: #dee4ef;
}

.table-blue-super-light,
.table-blue-super-light > th,
.table-blue-super-light > td {
  background-color: #fbfcfe;
}

.table-blue-super-light th,
.table-blue-super-light td,
.table-blue-super-light thead th,
.table-blue-super-light tbody + tbody {
  border-color: #f8fafc;
}

.table-hover .table-blue-super-light:hover {
  background-color: #e7edf9;
}

.table-hover .table-blue-super-light:hover > td,
.table-hover .table-blue-super-light:hover > th {
  background-color: #e7edf9;
}

.table-covid-blue,
.table-covid-blue > th,
.table-covid-blue > td {
  background-color: #cbe6f5;
}

.table-covid-blue th,
.table-covid-blue td,
.table-covid-blue thead th,
.table-covid-blue tbody + tbody {
  border-color: #9ed0ec;
}

.table-hover .table-covid-blue:hover {
  background-color: #b6dcf1;
}

.table-hover .table-covid-blue:hover > td,
.table-hover .table-covid-blue:hover > th {
  background-color: #b6dcf1;
}

.table-gray-dark,
.table-gray-dark > th,
.table-gray-dark > td {
  background-color: #cecece;
}

.table-gray-dark th,
.table-gray-dark td,
.table-gray-dark thead th,
.table-gray-dark tbody + tbody {
  border-color: #a4a4a4;
}

.table-hover .table-gray-dark:hover {
  background-color: #c1c1c1;
}

.table-hover .table-gray-dark:hover > td,
.table-hover .table-gray-dark:hover > th {
  background-color: #c1c1c1;
}

.table-gray-light,
.table-gray-light > th,
.table-gray-light > td {
  background-color: #e2e2e2;
}

.table-gray-light th,
.table-gray-light td,
.table-gray-light thead th,
.table-gray-light tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-gray-light:hover {
  background-color: #d5d5d5;
}

.table-hover .table-gray-light:hover > td,
.table-hover .table-gray-light:hover > th {
  background-color: #d5d5d5;
}

.table-gray-100,
.table-gray-100 > th,
.table-gray-100 > td {
  background-color: #fdfdfd;
}

.table-gray-100 th,
.table-gray-100 td,
.table-gray-100 thead th,
.table-gray-100 tbody + tbody {
  border-color: #fbfbfb;
}

.table-hover .table-gray-100:hover {
  background-color: #f0f0f0;
}

.table-hover .table-gray-100:hover > td,
.table-hover .table-gray-100:hover > th {
  background-color: #f0f0f0;
}

.table-gray-150,
.table-gray-150 > th,
.table-gray-150 > td {
  background-color: #fbfbfb;
}

.table-gray-150 th,
.table-gray-150 td,
.table-gray-150 thead th,
.table-gray-150 tbody + tbody {
  border-color: #f7f7f8;
}

.table-hover .table-gray-150:hover {
  background-color: #eeeeee;
}

.table-hover .table-gray-150:hover > td,
.table-hover .table-gray-150:hover > th {
  background-color: #eeeeee;
}

.table-gray-175,
.table-gray-175 > th,
.table-gray-175 > td {
  background-color: #fbfbfb;
}

.table-gray-175 th,
.table-gray-175 td,
.table-gray-175 thead th,
.table-gray-175 tbody + tbody {
  border-color: #f7f7f7;
}

.table-hover .table-gray-175:hover {
  background-color: #eeeeee;
}

.table-hover .table-gray-175:hover > td,
.table-hover .table-gray-175:hover > th {
  background-color: #eeeeee;
}

.table-gray-200,
.table-gray-200 > th,
.table-gray-200 > td {
  background-color: #f8f8f8;
}

.table-gray-200 th,
.table-gray-200 td,
.table-gray-200 thead th,
.table-gray-200 tbody + tbody {
  border-color: #f1f1f1;
}

.table-hover .table-gray-200:hover {
  background-color: #ebebeb;
}

.table-hover .table-gray-200:hover > td,
.table-hover .table-gray-200:hover > th {
  background-color: #ebebeb;
}

.table-gray-300,
.table-gray-300 > th,
.table-gray-300 > td {
  background-color: whitesmoke;
}

.table-gray-300 th,
.table-gray-300 td,
.table-gray-300 thead th,
.table-gray-300 tbody + tbody {
  border-color: #ededed;
}

.table-hover .table-gray-300:hover {
  background-color: #e8e8e8;
}

.table-hover .table-gray-300:hover > td,
.table-hover .table-gray-300:hover > th {
  background-color: #e8e8e8;
}

.table-gray-400,
.table-gray-400 > th,
.table-gray-400 > td {
  background-color: #f1f1f1;
}

.table-gray-400 th,
.table-gray-400 td,
.table-gray-400 thead th,
.table-gray-400 tbody + tbody {
  border-color: #e4e4e4;
}

.table-hover .table-gray-400:hover {
  background-color: #e4e4e4;
}

.table-hover .table-gray-400:hover > td,
.table-hover .table-gray-400:hover > th {
  background-color: #e4e4e4;
}

.table-gray-500,
.table-gray-500 > th,
.table-gray-500 > td {
  background-color: #e7e7e7;
}

.table-gray-500 th,
.table-gray-500 td,
.table-gray-500 thead th,
.table-gray-500 tbody + tbody {
  border-color: lightgray;
}

.table-hover .table-gray-500:hover {
  background-color: #dadada;
}

.table-hover .table-gray-500:hover > td,
.table-hover .table-gray-500:hover > th {
  background-color: #dadada;
}

.table-gray-600,
.table-gray-600 > th,
.table-gray-600 > td {
  background-color: #e2e2e2;
}

.table-gray-600 th,
.table-gray-600 td,
.table-gray-600 thead th,
.table-gray-600 tbody + tbody {
  border-color: #cacaca;
}

.table-hover .table-gray-600:hover {
  background-color: #d5d5d5;
}

.table-hover .table-gray-600:hover > td,
.table-hover .table-gray-600:hover > th {
  background-color: #d5d5d5;
}

.table-gray-700,
.table-gray-700 > th,
.table-gray-700 > td {
  background-color: #d7d7d7;
}

.table-gray-700 th,
.table-gray-700 td,
.table-gray-700 thead th,
.table-gray-700 tbody + tbody {
  border-color: #b5b5b5;
}

.table-hover .table-gray-700:hover {
  background-color: #cacaca;
}

.table-hover .table-gray-700:hover > td,
.table-hover .table-gray-700:hover > th {
  background-color: #cacaca;
}

.table-gray-800,
.table-gray-800 > th,
.table-gray-800 > td {
  background-color: #cecece;
}

.table-gray-800 th,
.table-gray-800 td,
.table-gray-800 thead th,
.table-gray-800 tbody + tbody {
  border-color: #a4a4a4;
}

.table-hover .table-gray-800:hover {
  background-color: #c1c1c1;
}

.table-hover .table-gray-800:hover > td,
.table-hover .table-gray-800:hover > th {
  background-color: #c1c1c1;
}

.table-gray-900,
.table-gray-900 > th,
.table-gray-900 > td {
  background-color: #c5c5c5;
}

.table-gray-900 th,
.table-gray-900 td,
.table-gray-900 thead th,
.table-gray-900 tbody + tbody {
  border-color: #939393;
}

.table-hover .table-gray-900:hover {
  background-color: #b8b8b8;
}

.table-hover .table-gray-900:hover > td,
.table-hover .table-gray-900:hover > th {
  background-color: #b8b8b8;
}

.table-green-dark,
.table-green-dark > th,
.table-green-dark > td {
  background-color: #dce8cc;
}

.table-green-dark th,
.table-green-dark td,
.table-green-dark thead th,
.table-green-dark tbody + tbody {
  border-color: #bdd4a0;
}

.table-hover .table-green-dark:hover {
  background-color: #d0e0ba;
}

.table-hover .table-green-dark:hover > td,
.table-hover .table-green-dark:hover > th {
  background-color: #d0e0ba;
}

.table-green-lime,
.table-green-lime > th,
.table-green-lime > td {
  background-color: #d7e2c9;
}

.table-green-lime th,
.table-green-lime td,
.table-green-lime thead th,
.table-green-lime tbody + tbody {
  border-color: #b5c89a;
}

.table-hover .table-green-lime:hover {
  background-color: #cbd9b8;
}

.table-hover .table-green-lime:hover > td,
.table-hover .table-green-lime:hover > th {
  background-color: #cbd9b8;
}

.table-green-light,
.table-green-light > th,
.table-green-light > td {
  background-color: #eaf2e0;
}

.table-green-light th,
.table-green-light td,
.table-green-light thead th,
.table-green-light tbody + tbody {
  border-color: #d9e7c5;
}

.table-hover .table-green-light:hover {
  background-color: #deeace;
}

.table-hover .table-green-light:hover > td,
.table-hover .table-green-light:hover > th {
  background-color: #deeace;
}

.table-green-extra-light,
.table-green-extra-light > th,
.table-green-extra-light > td {
  background-color: #f6f9f3;
}

.table-green-extra-light th,
.table-green-extra-light td,
.table-green-extra-light thead th,
.table-green-extra-light tbody + tbody {
  border-color: #eef3e9;
}

.table-hover .table-green-extra-light:hover {
  background-color: #e9f1e2;
}

.table-hover .table-green-extra-light:hover > td,
.table-hover .table-green-extra-light:hover > th {
  background-color: #e9f1e2;
}

.table-highlight-green,
.table-highlight-green > th,
.table-highlight-green > td {
  background-color: #f7f9f4;
}

.table-highlight-green th,
.table-highlight-green td,
.table-highlight-green thead th,
.table-highlight-green tbody + tbody {
  border-color: #f0f4eb;
}

.table-hover .table-highlight-green:hover {
  background-color: #ebf0e4;
}

.table-hover .table-highlight-green:hover > td,
.table-hover .table-highlight-green:hover > th {
  background-color: #ebf0e4;
}

.table-highlight-teal,
.table-highlight-teal > th,
.table-highlight-teal > td {
  background-color: #f9fdff;
}

.table-highlight-teal th,
.table-highlight-teal td,
.table-highlight-teal thead th,
.table-highlight-teal tbody + tbody {
  border-color: #f4fbff;
}

.table-hover .table-highlight-teal:hover {
  background-color: #e0f5ff;
}

.table-hover .table-highlight-teal:hover > td,
.table-hover .table-highlight-teal:hover > th {
  background-color: #e0f5ff;
}

.table-orange-dark,
.table-orange-dark > th,
.table-orange-dark > td {
  background-color: #fcdcc7;
}

.table-orange-dark th,
.table-orange-dark td,
.table-orange-dark thead th,
.table-orange-dark tbody + tbody {
  border-color: #fabe98;
}

.table-hover .table-orange-dark:hover {
  background-color: #fbcdaf;
}

.table-hover .table-orange-dark:hover > td,
.table-hover .table-orange-dark:hover > th {
  background-color: #fbcdaf;
}

.table-orange-gold,
.table-orange-gold > th,
.table-orange-gold > td {
  background-color: #ffe4b8;
}

.table-orange-gold th,
.table-orange-gold td,
.table-orange-gold thead th,
.table-orange-gold tbody + tbody {
  border-color: #ffcd7a;
}

.table-hover .table-orange-gold:hover {
  background-color: #ffda9f;
}

.table-hover .table-orange-gold:hover > td,
.table-hover .table-orange-gold:hover > th {
  background-color: #ffda9f;
}

.table-orange-light,
.table-orange-light > th,
.table-orange-light > td {
  background-color: #fee1cf;
}

.table-orange-light th,
.table-orange-light td,
.table-orange-light thead th,
.table-orange-light tbody + tbody {
  border-color: #fdc8a5;
}

.table-hover .table-orange-light:hover {
  background-color: #fdd1b6;
}

.table-hover .table-orange-light:hover > td,
.table-hover .table-orange-light:hover > th {
  background-color: #fdd1b6;
}

.table-orange-extra-light,
.table-orange-extra-light > th,
.table-orange-extra-light > td {
  background-color: #fef9f1;
}

.table-orange-extra-light th,
.table-orange-extra-light td,
.table-orange-extra-light thead th,
.table-orange-extra-light tbody + tbody {
  border-color: #fdf4e6;
}

.table-hover .table-orange-extra-light:hover {
  background-color: #fcefd9;
}

.table-hover .table-orange-extra-light:hover > td,
.table-hover .table-orange-extra-light:hover > th {
  background-color: #fcefd9;
}

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #fbfcfe;
}

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #f8fafc;
}

.table-hover .table-primary-light:hover {
  background-color: #e7edf9;
}

.table-hover .table-primary-light:hover > td,
.table-hover .table-primary-light:hover > th {
  background-color: #e7edf9;
}

.table-primary-mid,
.table-primary-mid > th,
.table-primary-mid > td {
  background-color: #b8d9ed;
}

.table-primary-mid th,
.table-primary-mid td,
.table-primary-mid thead th,
.table-primary-mid tbody + tbody {
  border-color: #7ab8de;
}

.table-hover .table-primary-mid:hover {
  background-color: #a4cee8;
}

.table-hover .table-primary-mid:hover > td,
.table-hover .table-primary-mid:hover > th {
  background-color: #a4cee8;
}

.table-primary-mid-light,
.table-primary-mid-light > th,
.table-primary-mid-light > td {
  background-color: #e9effa;
}

.table-primary-mid-light th,
.table-primary-mid-light td,
.table-primary-mid-light thead th,
.table-primary-mid-light tbody + tbody {
  border-color: #d5e1f5;
}

.table-hover .table-primary-mid-light:hover {
  background-color: #d4e0f5;
}

.table-hover .table-primary-mid-light:hover > td,
.table-hover .table-primary-mid-light:hover > th {
  background-color: #d4e0f5;
}

.table-pulse-blue,
.table-pulse-blue > th,
.table-pulse-blue > td {
  background-color: #b8d9ed;
}

.table-pulse-blue th,
.table-pulse-blue td,
.table-pulse-blue thead th,
.table-pulse-blue tbody + tbody {
  border-color: #7bb8dd;
}

.table-hover .table-pulse-blue:hover {
  background-color: #a4cee8;
}

.table-hover .table-pulse-blue:hover > td,
.table-hover .table-pulse-blue:hover > th {
  background-color: #a4cee8;
}

.table-pulse-benchmark,
.table-pulse-benchmark > th,
.table-pulse-benchmark > td {
  background-color: #d7e2c9;
}

.table-pulse-benchmark th,
.table-pulse-benchmark td,
.table-pulse-benchmark thead th,
.table-pulse-benchmark tbody + tbody {
  border-color: #b5c89a;
}

.table-hover .table-pulse-benchmark:hover {
  background-color: #cbd9b8;
}

.table-hover .table-pulse-benchmark:hover > td,
.table-hover .table-pulse-benchmark:hover > th {
  background-color: #cbd9b8;
}

.table-red-dark,
.table-red-dark > th,
.table-red-dark > td {
  background-color: #e0c4c4;
}

.table-red-dark th,
.table-red-dark td,
.table-red-dark thead th,
.table-red-dark tbody + tbody {
  border-color: #c69291;
}

.table-hover .table-red-dark:hover {
  background-color: #d7b3b3;
}

.table-hover .table-red-dark:hover > td,
.table-hover .table-red-dark:hover > th {
  background-color: #d7b3b3;
}

.table-red-light,
.table-red-light > th,
.table-red-light > td {
  background-color: #e7c8c8;
}

.table-red-light th,
.table-red-light td,
.table-red-light thead th,
.table-red-light tbody + tbody {
  border-color: #d39a98;
}

.table-hover .table-red-light:hover {
  background-color: #dfb6b6;
}

.table-hover .table-red-light:hover > td,
.table-hover .table-red-light:hover > th {
  background-color: #dfb6b6;
}

.table-red-lighter,
.table-red-lighter > th,
.table-red-lighter > td {
  background-color: #f4cfce;
}

.table-red-lighter th,
.table-red-lighter td,
.table-red-lighter thead th,
.table-red-lighter tbody + tbody {
  border-color: #eba6a4;
}

.table-hover .table-red-lighter:hover {
  background-color: #efbbb9;
}

.table-hover .table-red-lighter:hover > td,
.table-hover .table-red-lighter:hover > th {
  background-color: #efbbb9;
}

.table-red-extra-light,
.table-red-extra-light > th,
.table-red-extra-light > td {
  background-color: #f9f1f1;
}

.table-red-extra-light th,
.table-red-extra-light td,
.table-red-extra-light thead th,
.table-red-extra-light tbody + tbody {
  border-color: #f4e6e5;
}

.table-hover .table-red-extra-light:hover {
  background-color: #f1dfdf;
}

.table-hover .table-red-extra-light:hover > td,
.table-hover .table-red-extra-light:hover > th {
  background-color: #f1dfdf;
}

.table-teal-dark,
.table-teal-dark > th,
.table-teal-dark > td {
  background-color: #caeae9;
}

.table-teal-dark th,
.table-teal-dark td,
.table-teal-dark thead th,
.table-teal-dark tbody + tbody {
  border-color: #9dd8d7;
}

.table-hover .table-teal-dark:hover {
  background-color: #b8e3e1;
}

.table-hover .table-teal-dark:hover > td,
.table-hover .table-teal-dark:hover > th {
  background-color: #b8e3e1;
}

.table-teal-light,
.table-teal-light > th,
.table-teal-light > td {
  background-color: #d4f0f0;
}

.table-teal-light th,
.table-teal-light td,
.table-teal-light thead th,
.table-teal-light tbody + tbody {
  border-color: #b0e4e3;
}

.table-hover .table-teal-light:hover {
  background-color: #c1e9e9;
}

.table-hover .table-teal-light:hover > td,
.table-hover .table-teal-light:hover > th {
  background-color: #c1e9e9;
}

.table-teal-extra-light,
.table-teal-extra-light > th,
.table-teal-extra-light > td {
  background-color: #edf7f7;
}

.table-teal-extra-light th,
.table-teal-extra-light td,
.table-teal-extra-light thead th,
.table-teal-extra-light tbody + tbody {
  border-color: #ddf1f0;
}

.table-hover .table-teal-extra-light:hover {
  background-color: #dbefef;
}

.table-hover .table-teal-extra-light:hover > td,
.table-hover .table-teal-extra-light:hover > th {
  background-color: #dbefef;
}

.table-violet-dark,
.table-violet-dark > th,
.table-violet-dark > td {
  background-color: #e1cfdd;
}

.table-violet-dark th,
.table-violet-dark td,
.table-violet-dark thead th,
.table-violet-dark tbody + tbody {
  border-color: #c7a6c1;
}

.table-hover .table-violet-dark:hover {
  background-color: #d7bfd2;
}

.table-hover .table-violet-dark:hover > td,
.table-hover .table-violet-dark:hover > th {
  background-color: #d7bfd2;
}

.table-violet-light,
.table-violet-light > th,
.table-violet-light > td {
  background-color: #e7d1e3;
}

.table-violet-light th,
.table-violet-light td,
.table-violet-light thead th,
.table-violet-light tbody + tbody {
  border-color: #d3aacb;
}

.table-hover .table-violet-light:hover {
  background-color: #dec0d9;
}

.table-hover .table-violet-light:hover > td,
.table-hover .table-violet-light:hover > th {
  background-color: #dec0d9;
}

.table-violet-extra-light,
.table-violet-extra-light > th,
.table-violet-extra-light > td {
  background-color: #f8f1f6;
}

.table-violet-extra-light th,
.table-violet-extra-light td,
.table-violet-extra-light thead th,
.table-violet-extra-light tbody + tbody {
  border-color: #f1e6ef;
}

.table-hover .table-violet-extra-light:hover {
  background-color: #f0e0eb;
}

.table-hover .table-violet-extra-light:hover > td,
.table-hover .table-violet-extra-light:hover > th {
  background-color: #f0e0eb;
}

.table-white,
.table-white > th,
.table-white > td {
  background-color: white;
}

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white;
}

.table-hover .table-white:hover {
  background-color: #f2f2f2;
}

.table-hover .table-white:hover > td,
.table-hover .table-white:hover > th {
  background-color: #f2f2f2;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #505050;
  border-color: #636363;
}

.table .thead-light th {
  color: #fff;
  background-color: #ababab;
  border-color: #dddddd;
}

.table-dark {
  color: #fff;
  background-color: #505050;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #636363;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #707070;
}

.form-control:focus {
  color: #707070;
  background-color: #fff;
  border-color: #24adff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.form-control::placeholder {
  color: #cccccc;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #e5e5e5;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #707070;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #303030;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #999999;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #81AC48;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(129, 172, 72, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .valid-tooltip,
.form-row > [class*="col-"] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #81AC48;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2381AC48' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #81AC48;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #81AC48;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23505050' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2381AC48' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #81AC48;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #81AC48;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #81AC48;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #81AC48;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #9ac067;
  background-color: #9ac067;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #81AC48;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #81AC48;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #81AC48;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #D9534F;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(217, 83, 79, 0.9);
  border-radius: 0.25rem;
}

.form-row > .col > .invalid-tooltip,
.form-row > [class*="col-"] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #D9534F;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D9534F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D9534F' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #D9534F;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #D9534F;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23505050' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23D9534F' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23D9534F' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat;
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #D9534F;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #D9534F;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #D9534F;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #D9534F;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e27c79;
  background-color: #e27c79;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #D9534F;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #D9534F;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #D9534F;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #303030;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #303030;
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.btn-primary:hover {
  color: #fff;
  background-color: #004e7d;
  border-color: #004670;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #004e7d;
  border-color: #004670;
  box-shadow: 0 0 0 0.2rem rgba(38, 125, 177, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #004670;
  border-color: #003e63;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 125, 177, 0.5);
}

.btn-secondary {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
}

.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #807f7f;
  border-color: #797979;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-success:hover {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
}

.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #668839;
  border-color: #5f7f35;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-info {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-info:hover {
  color: #303030;
  background-color: #4bc1bf;
  border-color: #41bebc;
}

.btn-info:focus, .btn-info.focus {
  color: #303030;
  background-color: #4bc1bf;
  border-color: #41bebc;
  box-shadow: 0 0 0 0.2rem rgba(95, 180, 178, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #303030;
  background-color: #41bebc;
  border-color: #3eb5b2;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 180, 178, 0.5);
}

.btn-warning {
  color: #303030;
  background-color: #F0AD4E;
  border-color: #F0AD4E;
}

.btn-warning:hover {
  color: #303030;
  background-color: #ed9d2b;
  border-color: #ec971f;
}

.btn-warning:focus, .btn-warning.focus {
  color: #303030;
  background-color: #ed9d2b;
  border-color: #ec971f;
  box-shadow: 0 0 0 0.2rem rgba(211, 154, 74, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #303030;
  background-color: #F0AD4E;
  border-color: #F0AD4E;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #303030;
  background-color: #ec971f;
  border-color: #ea9214;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 154, 74, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #D9534F;
  border-color: #D9534F;
}

.btn-danger:hover {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
}

.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #d23430;
  border-color: #c9302c;
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #D9534F;
  border-color: #D9534F;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  border-color: #bf2e29;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 105, 0.5);
}

.btn-light {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-light:hover {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
}

.btn-light:focus, .btn-light.focus {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #303030;
  background-color: #dfdede;
  border-color: #d8d8d8;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-dark:hover {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
}

.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #373636;
  border-color: #303030;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-primary-dark {
  color: #fff;
  background-color: #273953;
  border-color: #273953;
}

.btn-primary-dark:hover {
  color: #fff;
  background-color: #1b2739;
  border-color: #172130;
}

.btn-primary-dark:focus, .btn-primary-dark.focus {
  color: #fff;
  background-color: #1b2739;
  border-color: #172130;
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 109, 0.5);
}

.btn-primary-dark.disabled, .btn-primary-dark:disabled {
  color: #fff;
  background-color: #273953;
  border-color: #273953;
}

.btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
.show > .btn-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #172130;
  border-color: #131b28;
}

.btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(71, 87, 109, 0.5);
}

.btn-mid {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-mid:hover {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
}

.btn-mid:focus, .btn-mid.focus {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-mid.disabled, .btn-mid:disabled {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-mid:not(:disabled):not(.disabled):active, .btn-mid:not(:disabled):not(.disabled).active,
.show > .btn-mid.dropdown-toggle {
  color: #fff;
  background-color: #807f7f;
  border-color: #797979;
}

.btn-mid:not(:disabled):not(.disabled):active:focus, .btn-mid:not(:disabled):not(.disabled).active:focus,
.show > .btn-mid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-mid-light {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-mid-light:hover {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
}

.btn-mid-light:focus, .btn-mid-light.focus {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-mid-light.disabled, .btn-mid-light:disabled {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-mid-light:not(:disabled):not(.disabled):active, .btn-mid-light:not(:disabled):not(.disabled).active,
.show > .btn-mid-light.dropdown-toggle {
  color: #303030;
  background-color: #dfdede;
  border-color: #d8d8d8;
}

.btn-mid-light:not(:disabled):not(.disabled):active:focus, .btn-mid-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-mid-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-accent {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-accent:hover {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
}

.btn-accent:focus, .btn-accent.focus {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-accent.disabled, .btn-accent:disabled {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-accent:not(:disabled):not(.disabled):active, .btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #668839;
  border-color: #5f7f35;
}

.btn-accent:not(:disabled):not(.disabled):active:focus, .btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-accent-light {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-accent-light:hover {
  color: #303030;
  background-color: #a3c675;
  border-color: #9dc26c;
}

.btn-accent-light:focus, .btn-accent-light.focus {
  color: #303030;
  background-color: #a3c675;
  border-color: #9dc26c;
  box-shadow: 0 0 0 0.2rem rgba(161, 185, 130, 0.5);
}

.btn-accent-light.disabled, .btn-accent-light:disabled {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-accent-light:not(:disabled):not(.disabled):active, .btn-accent-light:not(:disabled):not(.disabled).active,
.show > .btn-accent-light.dropdown-toggle {
  color: #303030;
  background-color: #9dc26c;
  border-color: #97be63;
}

.btn-accent-light:not(:disabled):not(.disabled):active:focus, .btn-accent-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 185, 130, 0.5);
}

.btn-accent-extra-light {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-accent-extra-light:hover {
  color: #303030;
  background-color: #ccdbbb;
  border-color: #c6d6b3;
}

.btn-accent-extra-light:focus, .btn-accent-extra-light.focus {
  color: #303030;
  background-color: #ccdbbb;
  border-color: #c6d6b3;
  box-shadow: 0 0 0 0.2rem rgba(197, 204, 187, 0.5);
}

.btn-accent-extra-light.disabled, .btn-accent-extra-light:disabled {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-accent-extra-light:not(:disabled):not(.disabled):active, .btn-accent-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-accent-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #c6d6b3;
  border-color: #c0d2aa;
}

.btn-accent-extra-light:not(:disabled):not(.disabled):active:focus, .btn-accent-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 204, 187, 0.5);
}

.btn-aha {
  color: #fff;
  background-color: #003087;
  border-color: #003087;
}

.btn-aha:hover {
  color: #fff;
  background-color: #002261;
  border-color: #001e54;
}

.btn-aha:focus, .btn-aha.focus {
  color: #fff;
  background-color: #002261;
  border-color: #001e54;
  box-shadow: 0 0 0 0.2rem rgba(38, 79, 153, 0.5);
}

.btn-aha.disabled, .btn-aha:disabled {
  color: #fff;
  background-color: #003087;
  border-color: #003087;
}

.btn-aha:not(:disabled):not(.disabled):active, .btn-aha:not(:disabled):not(.disabled).active,
.show > .btn-aha.dropdown-toggle {
  color: #fff;
  background-color: #001e54;
  border-color: #001947;
}

.btn-aha:not(:disabled):not(.disabled):active:focus, .btn-aha:not(:disabled):not(.disabled).active:focus,
.show > .btn-aha.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 79, 153, 0.5);
}

.btn-aha-blue {
  color: #303030;
  background-color: #B9D9EB;
  border-color: #B9D9EB;
}

.btn-aha-blue:hover {
  color: #303030;
  background-color: #9bc9e3;
  border-color: #91c3e0;
}

.btn-aha-blue:focus, .btn-aha-blue.focus {
  color: #303030;
  background-color: #9bc9e3;
  border-color: #91c3e0;
  box-shadow: 0 0 0 0.2rem rgba(164, 192, 207, 0.5);
}

.btn-aha-blue.disabled, .btn-aha-blue:disabled {
  color: #303030;
  background-color: #B9D9EB;
  border-color: #B9D9EB;
}

.btn-aha-blue:not(:disabled):not(.disabled):active, .btn-aha-blue:not(:disabled):not(.disabled).active,
.show > .btn-aha-blue.dropdown-toggle {
  color: #303030;
  background-color: #91c3e0;
  border-color: #87bedd;
}

.btn-aha-blue:not(:disabled):not(.disabled):active:focus, .btn-aha-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-aha-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 192, 207, 0.5);
}

.btn-black {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:hover {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:focus, .btn-black.focus {
  color: #fff;
  background-color: black;
  border-color: black;
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-black.disabled, .btn-black:disabled {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-black:not(:disabled):not(.disabled):active, .btn-black:not(:disabled):not(.disabled).active,
.show > .btn-black.dropdown-toggle {
  color: #fff;
  background-color: black;
  border-color: black;
}

.btn-black:not(:disabled):not(.disabled):active:focus, .btn-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 38, 38, 0.5);
}

.btn-blue-dark {
  color: #fff;
  background-color: #0066a3;
  border-color: #0066a3;
}

.btn-blue-dark:hover {
  color: #fff;
  background-color: #004e7d;
  border-color: #004670;
}

.btn-blue-dark:focus, .btn-blue-dark.focus {
  color: #fff;
  background-color: #004e7d;
  border-color: #004670;
  box-shadow: 0 0 0 0.2rem rgba(38, 125, 177, 0.5);
}

.btn-blue-dark.disabled, .btn-blue-dark:disabled {
  color: #fff;
  background-color: #0066a3;
  border-color: #0066a3;
}

.btn-blue-dark:not(:disabled):not(.disabled):active, .btn-blue-dark:not(:disabled):not(.disabled).active,
.show > .btn-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #004670;
  border-color: #003e63;
}

.btn-blue-dark:not(:disabled):not(.disabled):active:focus, .btn-blue-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 125, 177, 0.5);
}

.btn-blue-light {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-blue-light:hover {
  color: #fff;
  background-color: #005f99;
  border-color: #00578c;
}

.btn-blue-light:focus, .btn-blue-light.focus {
  color: #fff;
  background-color: #005f99;
  border-color: #00578c;
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 201, 0.5);
}

.btn-blue-light.disabled, .btn-blue-light:disabled {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-blue-light:not(:disabled):not(.disabled):active, .btn-blue-light:not(:disabled):not(.disabled).active,
.show > .btn-blue-light.dropdown-toggle {
  color: #fff;
  background-color: #00578c;
  border-color: #004f7f;
}

.btn-blue-light:not(:disabled):not(.disabled):active:focus, .btn-blue-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 201, 0.5);
}

.btn-blue-sky {
  color: #fff;
  background-color: #3CA7E6;
  border-color: #3CA7E6;
}

.btn-blue-sky:hover {
  color: #fff;
  background-color: #1d97df;
  border-color: #1b8fd4;
}

.btn-blue-sky:focus, .btn-blue-sky.focus {
  color: #fff;
  background-color: #1d97df;
  border-color: #1b8fd4;
  box-shadow: 0 0 0 0.2rem rgba(89, 180, 234, 0.5);
}

.btn-blue-sky.disabled, .btn-blue-sky:disabled {
  color: #fff;
  background-color: #3CA7E6;
  border-color: #3CA7E6;
}

.btn-blue-sky:not(:disabled):not(.disabled):active, .btn-blue-sky:not(:disabled):not(.disabled).active,
.show > .btn-blue-sky.dropdown-toggle {
  color: #fff;
  background-color: #1b8fd4;
  border-color: #1a88c9;
}

.btn-blue-sky:not(:disabled):not(.disabled):active:focus, .btn-blue-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 180, 234, 0.5);
}

.btn-blue-pale {
  color: #303030;
  background-color: #56AADB;
  border-color: #56AADB;
}

.btn-blue-pale:hover {
  color: #fff;
  background-color: #369ad4;
  border-color: #2d95d1;
}

.btn-blue-pale:focus, .btn-blue-pale.focus {
  color: #fff;
  background-color: #369ad4;
  border-color: #2d95d1;
  box-shadow: 0 0 0 0.2rem rgba(80, 152, 193, 0.5);
}

.btn-blue-pale.disabled, .btn-blue-pale:disabled {
  color: #303030;
  background-color: #56AADB;
  border-color: #56AADB;
}

.btn-blue-pale:not(:disabled):not(.disabled):active, .btn-blue-pale:not(:disabled):not(.disabled).active,
.show > .btn-blue-pale.dropdown-toggle {
  color: #fff;
  background-color: #2d95d1;
  border-color: #2a8dc7;
}

.btn-blue-pale:not(:disabled):not(.disabled):active:focus, .btn-blue-pale:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-pale.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 152, 193, 0.5);
}

.btn-blue-pulse {
  color: #fff;
  background-color: #0076BC;
  border-color: #0076BC;
}

.btn-blue-pulse:hover {
  color: #fff;
  background-color: #005e96;
  border-color: #005689;
}

.btn-blue-pulse:focus, .btn-blue-pulse.focus {
  color: #fff;
  background-color: #005e96;
  border-color: #005689;
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 198, 0.5);
}

.btn-blue-pulse.disabled, .btn-blue-pulse:disabled {
  color: #fff;
  background-color: #0076BC;
  border-color: #0076BC;
}

.btn-blue-pulse:not(:disabled):not(.disabled):active, .btn-blue-pulse:not(:disabled):not(.disabled).active,
.show > .btn-blue-pulse.dropdown-toggle {
  color: #fff;
  background-color: #005689;
  border-color: #004e7c;
}

.btn-blue-pulse:not(:disabled):not(.disabled):active:focus, .btn-blue-pulse:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-pulse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 198, 0.5);
}

.btn-blue-extra-super-light {
  color: #303030;
  background-color: #E5F2F2;
  border-color: #E5F2F2;
}

.btn-blue-extra-super-light:hover {
  color: #303030;
  background-color: #cce5e5;
  border-color: #c3e1e1;
}

.btn-blue-extra-super-light:focus, .btn-blue-extra-super-light.focus {
  color: #303030;
  background-color: #cce5e5;
  border-color: #c3e1e1;
  box-shadow: 0 0 0 0.2rem rgba(202, 213, 213, 0.5);
}

.btn-blue-extra-super-light.disabled, .btn-blue-extra-super-light:disabled {
  color: #303030;
  background-color: #E5F2F2;
  border-color: #E5F2F2;
}

.btn-blue-extra-super-light:not(:disabled):not(.disabled):active, .btn-blue-extra-super-light:not(:disabled):not(.disabled).active,
.show > .btn-blue-extra-super-light.dropdown-toggle {
  color: #303030;
  background-color: #c3e1e1;
  border-color: #bbdddd;
}

.btn-blue-extra-super-light:not(:disabled):not(.disabled):active:focus, .btn-blue-extra-super-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-extra-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 213, 213, 0.5);
}

.btn-blue-extra-light {
  color: #303030;
  background-color: #C6D1E1;
  border-color: #C6D1E1;
}

.btn-blue-extra-light:hover {
  color: #303030;
  background-color: #adbdd4;
  border-color: #a5b6cf;
}

.btn-blue-extra-light:focus, .btn-blue-extra-light.focus {
  color: #303030;
  background-color: #adbdd4;
  border-color: #a5b6cf;
  box-shadow: 0 0 0 0.2rem rgba(176, 185, 198, 0.5);
}

.btn-blue-extra-light.disabled, .btn-blue-extra-light:disabled {
  color: #303030;
  background-color: #C6D1E1;
  border-color: #C6D1E1;
}

.btn-blue-extra-light:not(:disabled):not(.disabled):active, .btn-blue-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-blue-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #a5b6cf;
  border-color: #9cafcb;
}

.btn-blue-extra-light:not(:disabled):not(.disabled):active:focus, .btn-blue-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(176, 185, 198, 0.5);
}

.btn-blue-super-light {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-blue-super-light:hover {
  color: #303030;
  background-color: #d6e3ef;
  border-color: #cdddec;
}

.btn-blue-super-light:focus, .btn-blue-super-light.focus {
  color: #303030;
  background-color: #d6e3ef;
  border-color: #cdddec;
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-blue-super-light.disabled, .btn-blue-super-light:disabled {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-blue-super-light:not(:disabled):not(.disabled):active, .btn-blue-super-light:not(:disabled):not(.disabled).active,
.show > .btn-blue-super-light.dropdown-toggle {
  color: #303030;
  background-color: #cdddec;
  border-color: #c4d6e8;
}

.btn-blue-super-light:not(:disabled):not(.disabled):active:focus, .btn-blue-super-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-blue-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-covid-blue {
  color: #fff;
  background-color: #44A4DB;
  border-color: #44A4DB;
}

.btn-covid-blue:hover {
  color: #fff;
  background-color: #2893d1;
  border-color: #268cc6;
}

.btn-covid-blue:focus, .btn-covid-blue.focus {
  color: #fff;
  background-color: #2893d1;
  border-color: #268cc6;
  box-shadow: 0 0 0 0.2rem rgba(96, 178, 224, 0.5);
}

.btn-covid-blue.disabled, .btn-covid-blue:disabled {
  color: #fff;
  background-color: #44A4DB;
  border-color: #44A4DB;
}

.btn-covid-blue:not(:disabled):not(.disabled):active, .btn-covid-blue:not(:disabled):not(.disabled).active,
.show > .btn-covid-blue.dropdown-toggle {
  color: #fff;
  background-color: #268cc6;
  border-color: #2484bb;
}

.btn-covid-blue:not(:disabled):not(.disabled):active:focus, .btn-covid-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-covid-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(96, 178, 224, 0.5);
}

.btn-gray-dark {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-gray-dark:hover {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
}

.btn-gray-dark:focus, .btn-gray-dark.focus {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-gray-dark.disabled, .btn-gray-dark:disabled {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-gray-dark:not(:disabled):not(.disabled):active, .btn-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #373636;
  border-color: #303030;
}

.btn-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-gray-light {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-gray-light:hover {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
}

.btn-gray-light:focus, .btn-gray-light.focus {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-gray-light.disabled, .btn-gray-light:disabled {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-gray-light:not(:disabled):not(.disabled):active, .btn-gray-light:not(:disabled):not(.disabled).active,
.show > .btn-gray-light.dropdown-toggle {
  color: #fff;
  background-color: #807f7f;
  border-color: #797979;
}

.btn-gray-light:not(:disabled):not(.disabled):active:focus, .btn-gray-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-gray-100 {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-gray-100:hover {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
}

.btn-gray-100:focus, .btn-gray-100.focus {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #dfdede;
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-gray-100.disabled, .btn-gray-100:disabled {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-gray-100:not(:disabled):not(.disabled):active, .btn-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-gray-100.dropdown-toggle {
  color: #303030;
  background-color: #dfdede;
  border-color: #d8d8d8;
}

.btn-gray-100:not(:disabled):not(.disabled):active:focus, .btn-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(218, 218, 218, 0.5);
}

.btn-gray-150 {
  color: #303030;
  background-color: #F0F0F1;
  border-color: #F0F0F1;
}

.btn-gray-150:hover {
  color: #303030;
  background-color: #dcdcdf;
  border-color: #d6d6d8;
}

.btn-gray-150:focus, .btn-gray-150.focus {
  color: #303030;
  background-color: #dcdcdf;
  border-color: #d6d6d8;
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-gray-150.disabled, .btn-gray-150:disabled {
  color: #303030;
  background-color: #F0F0F1;
  border-color: #F0F0F1;
}

.btn-gray-150:not(:disabled):not(.disabled):active, .btn-gray-150:not(:disabled):not(.disabled).active,
.show > .btn-gray-150.dropdown-toggle {
  color: #303030;
  background-color: #d6d6d8;
  border-color: #cfcfd2;
}

.btn-gray-150:not(:disabled):not(.disabled):active:focus, .btn-gray-150:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-150.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 211, 212, 0.5);
}

.btn-gray-175 {
  color: #303030;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.btn-gray-175:hover {
  color: #303030;
  background-color: gainsboro;
  border-color: #d6d5d5;
}

.btn-gray-175:focus, .btn-gray-175.focus {
  color: #303030;
  background-color: gainsboro;
  border-color: #d6d5d5;
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 210, 0.5);
}

.btn-gray-175.disabled, .btn-gray-175:disabled {
  color: #303030;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.btn-gray-175:not(:disabled):not(.disabled):active, .btn-gray-175:not(:disabled):not(.disabled).active,
.show > .btn-gray-175.dropdown-toggle {
  color: #303030;
  background-color: #d6d5d5;
  border-color: #cfcfcf;
}

.btn-gray-175:not(:disabled):not(.disabled):active:focus, .btn-gray-175:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-175.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 210, 210, 0.5);
}

.btn-gray-200 {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-gray-200:hover {
  color: #303030;
  background-color: #d2d2d2;
  border-color: #cccbcb;
}

.btn-gray-200:focus, .btn-gray-200.focus {
  color: #303030;
  background-color: #d2d2d2;
  border-color: #cccbcb;
  box-shadow: 0 0 0 0.2rem rgba(202, 202, 202, 0.5);
}

.btn-gray-200.disabled, .btn-gray-200:disabled {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-gray-200:not(:disabled):not(.disabled):active, .btn-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-gray-200.dropdown-toggle {
  color: #303030;
  background-color: #cccbcb;
  border-color: #c5c5c5;
}

.btn-gray-200:not(:disabled):not(.disabled):active:focus, .btn-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 202, 202, 0.5);
}

.btn-gray-300 {
  color: #303030;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-gray-300:hover {
  color: #303030;
  background-color: #cacaca;
  border-color: #c4c3c3;
}

.btn-gray-300:focus, .btn-gray-300.focus {
  color: #303030;
  background-color: #cacaca;
  border-color: #c4c3c3;
  box-shadow: 0 0 0 0.2rem rgba(195, 195, 195, 0.5);
}

.btn-gray-300.disabled, .btn-gray-300:disabled {
  color: #303030;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-gray-300:not(:disabled):not(.disabled):active, .btn-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-gray-300.dropdown-toggle {
  color: #303030;
  background-color: #c4c3c3;
  border-color: #bdbdbd;
}

.btn-gray-300:not(:disabled):not(.disabled):active:focus, .btn-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(195, 195, 195, 0.5);
}

.btn-gray-400 {
  color: #303030;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-gray-400:hover {
  color: #303030;
  background-color: #b9b9b9;
  border-color: #b3b2b2;
}

.btn-gray-400:focus, .btn-gray-400.focus {
  color: #303030;
  background-color: #b9b9b9;
  border-color: #b3b2b2;
  box-shadow: 0 0 0 0.2rem rgba(181, 181, 181, 0.5);
}

.btn-gray-400.disabled, .btn-gray-400:disabled {
  color: #303030;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-gray-400:not(:disabled):not(.disabled):active, .btn-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-gray-400.dropdown-toggle {
  color: #303030;
  background-color: #b3b2b2;
  border-color: #acacac;
}

.btn-gray-400:not(:disabled):not(.disabled):active:focus, .btn-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 181, 181, 0.5);
}

.btn-gray-500 {
  color: #303030;
  background-color: #ababab;
  border-color: #ababab;
}

.btn-gray-500:hover {
  color: #303030;
  background-color: #989898;
  border-color: #929191;
}

.btn-gray-500:focus, .btn-gray-500.focus {
  color: #303030;
  background-color: #989898;
  border-color: #929191;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-gray-500.disabled, .btn-gray-500:disabled {
  color: #303030;
  background-color: #ababab;
  border-color: #ababab;
}

.btn-gray-500:not(:disabled):not(.disabled):active, .btn-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #929191;
  border-color: #8b8b8b;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus, .btn-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-gray-600 {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-gray-600:hover {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
}

.btn-gray-600:focus, .btn-gray-600.focus {
  color: #fff;
  background-color: #868686;
  border-color: #807f7f;
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-gray-600.disabled, .btn-gray-600:disabled {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-gray-600:not(:disabled):not(.disabled):active, .btn-gray-600:not(:disabled):not(.disabled).active,
.show > .btn-gray-600.dropdown-toggle {
  color: #fff;
  background-color: #807f7f;
  border-color: #797979;
}

.btn-gray-600:not(:disabled):not(.disabled):active:focus, .btn-gray-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(137, 137, 137, 0.5);
}

.btn-gray-700 {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-gray-700:hover {
  color: #fff;
  background-color: #5d5d5d;
  border-color: #575656;
}

.btn-gray-700:focus, .btn-gray-700.focus {
  color: #fff;
  background-color: #5d5d5d;
  border-color: #575656;
  box-shadow: 0 0 0 0.2rem rgba(133, 133, 133, 0.5);
}

.btn-gray-700.disabled, .btn-gray-700:disabled {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-gray-700:not(:disabled):not(.disabled):active, .btn-gray-700:not(:disabled):not(.disabled).active,
.show > .btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #575656;
  border-color: #505050;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus, .btn-gray-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 133, 133, 0.5);
}

.btn-gray-800 {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-gray-800:hover {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
}

.btn-gray-800:focus, .btn-gray-800.focus {
  color: #fff;
  background-color: #3d3d3d;
  border-color: #373636;
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-gray-800.disabled, .btn-gray-800:disabled {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-gray-800:not(:disabled):not(.disabled):active, .btn-gray-800:not(:disabled):not(.disabled).active,
.show > .btn-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #373636;
  border-color: #303030;
}

.btn-gray-800:not(:disabled):not(.disabled):active:focus, .btn-gray-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(106, 106, 106, 0.5);
}

.btn-gray-900 {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
}

.btn-gray-900:hover {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #171616;
}

.btn-gray-900:focus, .btn-gray-900.focus {
  color: #fff;
  background-color: #1d1d1d;
  border-color: #171616;
  box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5);
}

.btn-gray-900.disabled, .btn-gray-900:disabled {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
}

.btn-gray-900:not(:disabled):not(.disabled):active, .btn-gray-900:not(:disabled):not(.disabled).active,
.show > .btn-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #171616;
  border-color: #101010;
}

.btn-gray-900:not(:disabled):not(.disabled):active:focus, .btn-gray-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(79, 79, 79, 0.5);
}

.btn-green-dark {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-green-dark:hover {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
}

.btn-green-dark:focus, .btn-green-dark.focus {
  color: #fff;
  background-color: #6d913d;
  border-color: #668839;
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-green-dark.disabled, .btn-green-dark:disabled {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-green-dark:not(:disabled):not(.disabled):active, .btn-green-dark:not(:disabled):not(.disabled).active,
.show > .btn-green-dark.dropdown-toggle {
  color: #fff;
  background-color: #668839;
  border-color: #5f7f35;
}

.btn-green-dark:not(:disabled):not(.disabled):active:focus, .btn-green-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-green-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 184, 99, 0.5);
}

.btn-green-lime {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-green-lime:hover {
  color: #fff;
  background-color: #5c7b32;
  border-color: #55722e;
}

.btn-green-lime:focus, .btn-green-lime.focus {
  color: #fff;
  background-color: #5c7b32;
  border-color: #55722e;
  box-shadow: 0 0 0 0.2rem rgba(133, 166, 90, 0.5);
}

.btn-green-lime.disabled, .btn-green-lime:disabled {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-green-lime:not(:disabled):not(.disabled):active, .btn-green-lime:not(:disabled):not(.disabled).active,
.show > .btn-green-lime.dropdown-toggle {
  color: #fff;
  background-color: #55722e;
  border-color: #4e692b;
}

.btn-green-lime:not(:disabled):not(.disabled):active:focus, .btn-green-lime:not(:disabled):not(.disabled).active:focus,
.show > .btn-green-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 166, 90, 0.5);
}

.btn-green-light {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-green-light:hover {
  color: #303030;
  background-color: #a3c675;
  border-color: #9dc26c;
}

.btn-green-light:focus, .btn-green-light.focus {
  color: #303030;
  background-color: #a3c675;
  border-color: #9dc26c;
  box-shadow: 0 0 0 0.2rem rgba(161, 185, 130, 0.5);
}

.btn-green-light.disabled, .btn-green-light:disabled {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-green-light:not(:disabled):not(.disabled):active, .btn-green-light:not(:disabled):not(.disabled).active,
.show > .btn-green-light.dropdown-toggle {
  color: #303030;
  background-color: #9dc26c;
  border-color: #97be63;
}

.btn-green-light:not(:disabled):not(.disabled):active:focus, .btn-green-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-green-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(161, 185, 130, 0.5);
}

.btn-green-extra-light {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-green-extra-light:hover {
  color: #303030;
  background-color: #ccdbbb;
  border-color: #c6d6b3;
}

.btn-green-extra-light:focus, .btn-green-extra-light.focus {
  color: #303030;
  background-color: #ccdbbb;
  border-color: #c6d6b3;
  box-shadow: 0 0 0 0.2rem rgba(197, 204, 187, 0.5);
}

.btn-green-extra-light.disabled, .btn-green-extra-light:disabled {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-green-extra-light:not(:disabled):not(.disabled):active, .btn-green-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-green-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #c6d6b3;
  border-color: #c0d2aa;
}

.btn-green-extra-light:not(:disabled):not(.disabled):active:focus, .btn-green-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-green-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 204, 187, 0.5);
}

.btn-highlight-green {
  color: #303030;
  background-color: #E2EAD8;
  border-color: #E2EAD8;
}

.btn-highlight-green:hover {
  color: #303030;
  background-color: #d0ddbf;
  border-color: #c9d8b7;
}

.btn-highlight-green:focus, .btn-highlight-green.focus {
  color: #303030;
  background-color: #d0ddbf;
  border-color: #c9d8b7;
  box-shadow: 0 0 0 0.2rem rgba(199, 206, 191, 0.5);
}

.btn-highlight-green.disabled, .btn-highlight-green:disabled {
  color: #303030;
  background-color: #E2EAD8;
  border-color: #E2EAD8;
}

.btn-highlight-green:not(:disabled):not(.disabled):active, .btn-highlight-green:not(:disabled):not(.disabled).active,
.show > .btn-highlight-green.dropdown-toggle {
  color: #303030;
  background-color: #c9d8b7;
  border-color: #c3d4af;
}

.btn-highlight-green:not(:disabled):not(.disabled):active:focus, .btn-highlight-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-highlight-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(199, 206, 191, 0.5);
}

.btn-highlight-teal {
  color: #303030;
  background-color: #EAF7FF;
  border-color: #EAF7FF;
}

.btn-highlight-teal:hover {
  color: #303030;
  background-color: #c4e8ff;
  border-color: #b7e4ff;
}

.btn-highlight-teal:focus, .btn-highlight-teal.focus {
  color: #303030;
  background-color: #c4e8ff;
  border-color: #b7e4ff;
  box-shadow: 0 0 0 0.2rem rgba(206, 217, 224, 0.5);
}

.btn-highlight-teal.disabled, .btn-highlight-teal:disabled {
  color: #303030;
  background-color: #EAF7FF;
  border-color: #EAF7FF;
}

.btn-highlight-teal:not(:disabled):not(.disabled):active, .btn-highlight-teal:not(:disabled):not(.disabled).active,
.show > .btn-highlight-teal.dropdown-toggle {
  color: #303030;
  background-color: #b7e4ff;
  border-color: #aadfff;
}

.btn-highlight-teal:not(:disabled):not(.disabled):active:focus, .btn-highlight-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-highlight-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 217, 224, 0.5);
}

.btn-orange-dark {
  color: #303030;
  background-color: #F58238;
  border-color: #F58238;
}

.btn-orange-dark:hover {
  color: #fff;
  background-color: #f36b14;
  border-color: #ee640c;
}

.btn-orange-dark:focus, .btn-orange-dark.focus {
  color: #fff;
  background-color: #f36b14;
  border-color: #ee640c;
  box-shadow: 0 0 0 0.2rem rgba(215, 118, 55, 0.5);
}

.btn-orange-dark.disabled, .btn-orange-dark:disabled {
  color: #303030;
  background-color: #F58238;
  border-color: #F58238;
}

.btn-orange-dark:not(:disabled):not(.disabled):active, .btn-orange-dark:not(:disabled):not(.disabled).active,
.show > .btn-orange-dark.dropdown-toggle {
  color: #fff;
  background-color: #ee640c;
  border-color: #e25f0b;
}

.btn-orange-dark:not(:disabled):not(.disabled):active:focus, .btn-orange-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(215, 118, 55, 0.5);
}

.btn-orange-gold {
  color: #303030;
  background-color: #FF9E00;
  border-color: #FF9E00;
}

.btn-orange-gold:hover {
  color: #fff;
  background-color: #d98600;
  border-color: #cc7e00;
}

.btn-orange-gold:focus, .btn-orange-gold.focus {
  color: #fff;
  background-color: #d98600;
  border-color: #cc7e00;
  box-shadow: 0 0 0 0.2rem rgba(224, 142, 7, 0.5);
}

.btn-orange-gold.disabled, .btn-orange-gold:disabled {
  color: #303030;
  background-color: #FF9E00;
  border-color: #FF9E00;
}

.btn-orange-gold:not(:disabled):not(.disabled):active, .btn-orange-gold:not(:disabled):not(.disabled).active,
.show > .btn-orange-gold.dropdown-toggle {
  color: #fff;
  background-color: #cc7e00;
  border-color: #bf7700;
}

.btn-orange-gold:not(:disabled):not(.disabled):active:focus, .btn-orange-gold:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 142, 7, 0.5);
}

.btn-orange-light {
  color: #303030;
  background-color: #FC9552;
  border-color: #FC9552;
}

.btn-orange-light:hover {
  color: #303030;
  background-color: #fb7e2c;
  border-color: #fb7620;
}

.btn-orange-light:focus, .btn-orange-light.focus {
  color: #303030;
  background-color: #fb7e2c;
  border-color: #fb7620;
  box-shadow: 0 0 0 0.2rem rgba(221, 134, 77, 0.5);
}

.btn-orange-light.disabled, .btn-orange-light:disabled {
  color: #303030;
  background-color: #FC9552;
  border-color: #FC9552;
}

.btn-orange-light:not(:disabled):not(.disabled):active, .btn-orange-light:not(:disabled):not(.disabled).active,
.show > .btn-orange-light.dropdown-toggle {
  color: #fff;
  background-color: #fb7620;
  border-color: #fb6f13;
}

.btn-orange-light:not(:disabled):not(.disabled):active:focus, .btn-orange-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 134, 77, 0.5);
}

.btn-orange-extra-light {
  color: #303030;
  background-color: #FCEACE;
  border-color: #FCEACE;
}

.btn-orange-extra-light:hover {
  color: #303030;
  background-color: #fadbaa;
  border-color: #f9d59e;
}

.btn-orange-extra-light:focus, .btn-orange-extra-light.focus {
  color: #303030;
  background-color: #fadbaa;
  border-color: #f9d59e;
  box-shadow: 0 0 0 0.2rem rgba(221, 206, 182, 0.5);
}

.btn-orange-extra-light.disabled, .btn-orange-extra-light:disabled {
  color: #303030;
  background-color: #FCEACE;
  border-color: #FCEACE;
}

.btn-orange-extra-light:not(:disabled):not(.disabled):active, .btn-orange-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-orange-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #f9d59e;
  border-color: #f8d092;
}

.btn-orange-extra-light:not(:disabled):not(.disabled):active:focus, .btn-orange-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-orange-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 206, 182, 0.5);
}

.btn-primary-light {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-primary-light:hover {
  color: #303030;
  background-color: #d6e3ef;
  border-color: #cdddec;
}

.btn-primary-light:focus, .btn-primary-light.focus {
  color: #303030;
  background-color: #d6e3ef;
  border-color: #cdddec;
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-light.dropdown-toggle {
  color: #303030;
  background-color: #cdddec;
  border-color: #c4d6e8;
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(213, 216, 220, 0.5);
}

.btn-primary-mid {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-primary-mid:hover {
  color: #fff;
  background-color: #005f99;
  border-color: #00578c;
}

.btn-primary-mid:focus, .btn-primary-mid.focus {
  color: #fff;
  background-color: #005f99;
  border-color: #00578c;
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 201, 0.5);
}

.btn-primary-mid.disabled, .btn-primary-mid:disabled {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-primary-mid:not(:disabled):not(.disabled):active, .btn-primary-mid:not(:disabled):not(.disabled).active,
.show > .btn-primary-mid.dropdown-toggle {
  color: #fff;
  background-color: #00578c;
  border-color: #004f7f;
}

.btn-primary-mid:not(:disabled):not(.disabled):active:focus, .btn-primary-mid:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-mid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 139, 201, 0.5);
}

.btn-primary-mid-light {
  color: #303030;
  background-color: #afc6ec;
  border-color: #afc6ec;
}

.btn-primary-mid-light:hover {
  color: #303030;
  background-color: #90b0e5;
  border-color: #86a9e2;
}

.btn-primary-mid-light:focus, .btn-primary-mid-light.focus {
  color: #303030;
  background-color: #90b0e5;
  border-color: #86a9e2;
  box-shadow: 0 0 0 0.2rem rgba(156, 176, 208, 0.5);
}

.btn-primary-mid-light.disabled, .btn-primary-mid-light:disabled {
  color: #303030;
  background-color: #afc6ec;
  border-color: #afc6ec;
}

.btn-primary-mid-light:not(:disabled):not(.disabled):active, .btn-primary-mid-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-mid-light.dropdown-toggle {
  color: #303030;
  background-color: #86a9e2;
  border-color: #7ba1e0;
}

.btn-primary-mid-light:not(:disabled):not(.disabled):active:focus, .btn-primary-mid-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-mid-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(156, 176, 208, 0.5);
}

.btn-pulse-blue {
  color: #fff;
  background-color: #0176bd;
  border-color: #0176bd;
}

.btn-pulse-blue:hover {
  color: #fff;
  background-color: #015e97;
  border-color: #01568a;
}

.btn-pulse-blue:focus, .btn-pulse-blue.focus {
  color: #fff;
  background-color: #015e97;
  border-color: #01568a;
  box-shadow: 0 0 0 0.2rem rgba(39, 139, 199, 0.5);
}

.btn-pulse-blue.disabled, .btn-pulse-blue:disabled {
  color: #fff;
  background-color: #0176bd;
  border-color: #0176bd;
}

.btn-pulse-blue:not(:disabled):not(.disabled):active, .btn-pulse-blue:not(:disabled):not(.disabled).active,
.show > .btn-pulse-blue.dropdown-toggle {
  color: #fff;
  background-color: #01568a;
  border-color: #014e7e;
}

.btn-pulse-blue:not(:disabled):not(.disabled):active:focus, .btn-pulse-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-pulse-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 139, 199, 0.5);
}

.btn-pulse-benchmark {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-pulse-benchmark:hover {
  color: #fff;
  background-color: #5c7b32;
  border-color: #55722e;
}

.btn-pulse-benchmark:focus, .btn-pulse-benchmark.focus {
  color: #fff;
  background-color: #5c7b32;
  border-color: #55722e;
  box-shadow: 0 0 0 0.2rem rgba(133, 166, 90, 0.5);
}

.btn-pulse-benchmark.disabled, .btn-pulse-benchmark:disabled {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-pulse-benchmark:not(:disabled):not(.disabled):active, .btn-pulse-benchmark:not(:disabled):not(.disabled).active,
.show > .btn-pulse-benchmark.dropdown-toggle {
  color: #fff;
  background-color: #55722e;
  border-color: #4e692b;
}

.btn-pulse-benchmark:not(:disabled):not(.disabled):active:focus, .btn-pulse-benchmark:not(:disabled):not(.disabled).active:focus,
.show > .btn-pulse-benchmark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(133, 166, 90, 0.5);
}

.btn-red-dark {
  color: #fff;
  background-color: #922E2B;
  border-color: #922E2B;
}

.btn-red-dark:hover {
  color: #fff;
  background-color: #742522;
  border-color: #6b221f;
}

.btn-red-dark:focus, .btn-red-dark.focus {
  color: #fff;
  background-color: #742522;
  border-color: #6b221f;
  box-shadow: 0 0 0 0.2rem rgba(162, 77, 75, 0.5);
}

.btn-red-dark.disabled, .btn-red-dark:disabled {
  color: #fff;
  background-color: #922E2B;
  border-color: #922E2B;
}

.btn-red-dark:not(:disabled):not(.disabled):active, .btn-red-dark:not(:disabled):not(.disabled).active,
.show > .btn-red-dark.dropdown-toggle {
  color: #fff;
  background-color: #6b221f;
  border-color: #611e1c;
}

.btn-red-dark:not(:disabled):not(.disabled):active:focus, .btn-red-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-red-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(162, 77, 75, 0.5);
}

.btn-red-light {
  color: #fff;
  background-color: #AA3C39;
  border-color: #AA3C39;
}

.btn-red-light:hover {
  color: #fff;
  background-color: #8d322f;
  border-color: #842f2c;
}

.btn-red-light:focus, .btn-red-light.focus {
  color: #fff;
  background-color: #8d322f;
  border-color: #842f2c;
  box-shadow: 0 0 0 0.2rem rgba(183, 89, 87, 0.5);
}

.btn-red-light.disabled, .btn-red-light:disabled {
  color: #fff;
  background-color: #AA3C39;
  border-color: #AA3C39;
}

.btn-red-light:not(:disabled):not(.disabled):active, .btn-red-light:not(:disabled):not(.disabled).active,
.show > .btn-red-light.dropdown-toggle {
  color: #fff;
  background-color: #842f2c;
  border-color: #7a2b29;
}

.btn-red-light:not(:disabled):not(.disabled):active:focus, .btn-red-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-red-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(183, 89, 87, 0.5);
}

.btn-red-lighter {
  color: #fff;
  background-color: #D95350;
  border-color: #D95350;
}

.btn-red-lighter:hover {
  color: #fff;
  background-color: #d23431;
  border-color: #ca2f2c;
}

.btn-red-lighter:focus, .btn-red-lighter.focus {
  color: #fff;
  background-color: #d23431;
  border-color: #ca2f2c;
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 106, 0.5);
}

.btn-red-lighter.disabled, .btn-red-lighter:disabled {
  color: #fff;
  background-color: #D95350;
  border-color: #D95350;
}

.btn-red-lighter:not(:disabled):not(.disabled):active, .btn-red-lighter:not(:disabled):not(.disabled).active,
.show > .btn-red-lighter.dropdown-toggle {
  color: #fff;
  background-color: #ca2f2c;
  border-color: #c02d2a;
}

.btn-red-lighter:not(:disabled):not(.disabled):active:focus, .btn-red-lighter:not(:disabled):not(.disabled).active:focus,
.show > .btn-red-lighter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 109, 106, 0.5);
}

.btn-red-extra-light {
  color: #303030;
  background-color: #E9CECD;
  border-color: #E9CECD;
}

.btn-red-extra-light:hover {
  color: #303030;
  background-color: #ddb4b2;
  border-color: #d9abaa;
}

.btn-red-extra-light:focus, .btn-red-extra-light.focus {
  color: #303030;
  background-color: #ddb4b2;
  border-color: #d9abaa;
  box-shadow: 0 0 0 0.2rem rgba(205, 182, 181, 0.5);
}

.btn-red-extra-light.disabled, .btn-red-extra-light:disabled {
  color: #303030;
  background-color: #E9CECD;
  border-color: #E9CECD;
}

.btn-red-extra-light:not(:disabled):not(.disabled):active, .btn-red-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-red-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #d9abaa;
  border-color: #d6a3a1;
}

.btn-red-extra-light:not(:disabled):not(.disabled):active:focus, .btn-red-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-red-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(205, 182, 181, 0.5);
}

.btn-teal-dark {
  color: #fff;
  background-color: #43B4B2;
  border-color: #43B4B2;
}

.btn-teal-dark:hover {
  color: #fff;
  background-color: #399896;
  border-color: #358f8d;
}

.btn-teal-dark:focus, .btn-teal-dark.focus {
  color: #fff;
  background-color: #399896;
  border-color: #358f8d;
  box-shadow: 0 0 0 0.2rem rgba(95, 191, 190, 0.5);
}

.btn-teal-dark.disabled, .btn-teal-dark:disabled {
  color: #fff;
  background-color: #43B4B2;
  border-color: #43B4B2;
}

.btn-teal-dark:not(:disabled):not(.disabled):active, .btn-teal-dark:not(:disabled):not(.disabled).active,
.show > .btn-teal-dark.dropdown-toggle {
  color: #fff;
  background-color: #358f8d;
  border-color: #328684;
}

.btn-teal-dark:not(:disabled):not(.disabled):active:focus, .btn-teal-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 191, 190, 0.5);
}

.btn-teal-light {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-teal-light:hover {
  color: #303030;
  background-color: #4bc1bf;
  border-color: #41bebc;
}

.btn-teal-light:focus, .btn-teal-light.focus {
  color: #303030;
  background-color: #4bc1bf;
  border-color: #41bebc;
  box-shadow: 0 0 0 0.2rem rgba(95, 180, 178, 0.5);
}

.btn-teal-light.disabled, .btn-teal-light:disabled {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-teal-light:not(:disabled):not(.disabled):active, .btn-teal-light:not(:disabled):not(.disabled).active,
.show > .btn-teal-light.dropdown-toggle {
  color: #303030;
  background-color: #41bebc;
  border-color: #3eb5b2;
}

.btn-teal-light:not(:disabled):not(.disabled):active:focus, .btn-teal-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 180, 178, 0.5);
}

.btn-teal-extra-light {
  color: #303030;
  background-color: #BEE4E3;
  border-color: #BEE4E3;
}

.btn-teal-extra-light:hover {
  color: #303030;
  background-color: #a3d9d7;
  border-color: #9ad5d3;
}

.btn-teal-extra-light:focus, .btn-teal-extra-light.focus {
  color: #303030;
  background-color: #a3d9d7;
  border-color: #9ad5d3;
  box-shadow: 0 0 0 0.2rem rgba(169, 201, 200, 0.5);
}

.btn-teal-extra-light.disabled, .btn-teal-extra-light:disabled {
  color: #303030;
  background-color: #BEE4E3;
  border-color: #BEE4E3;
}

.btn-teal-extra-light:not(:disabled):not(.disabled):active, .btn-teal-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-teal-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #9ad5d3;
  border-color: #91d1d0;
}

.btn-teal-extra-light:not(:disabled):not(.disabled):active:focus, .btn-teal-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-teal-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(169, 201, 200, 0.5);
}

.btn-violet-dark {
  color: #fff;
  background-color: #945487;
  border-color: #945487;
}

.btn-violet-dark:hover {
  color: #fff;
  background-color: #7c4671;
  border-color: #734269;
}

.btn-violet-dark:focus, .btn-violet-dark.focus {
  color: #fff;
  background-color: #7c4671;
  border-color: #734269;
  box-shadow: 0 0 0 0.2rem rgba(164, 110, 153, 0.5);
}

.btn-violet-dark.disabled, .btn-violet-dark:disabled {
  color: #fff;
  background-color: #945487;
  border-color: #945487;
}

.btn-violet-dark:not(:disabled):not(.disabled):active, .btn-violet-dark:not(:disabled):not(.disabled).active,
.show > .btn-violet-dark.dropdown-toggle {
  color: #fff;
  background-color: #734269;
  border-color: #6b3d62;
}

.btn-violet-dark:not(:disabled):not(.disabled):active:focus, .btn-violet-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(164, 110, 153, 0.5);
}

.btn-violet-light {
  color: #fff;
  background-color: #AB5B9B;
  border-color: #AB5B9B;
}

.btn-violet-light:hover {
  color: #fff;
  background-color: #944c86;
  border-color: #8c477e;
}

.btn-violet-light:focus, .btn-violet-light.focus {
  color: #fff;
  background-color: #944c86;
  border-color: #8c477e;
  box-shadow: 0 0 0 0.2rem rgba(184, 116, 170, 0.5);
}

.btn-violet-light.disabled, .btn-violet-light:disabled {
  color: #fff;
  background-color: #AB5B9B;
  border-color: #AB5B9B;
}

.btn-violet-light:not(:disabled):not(.disabled):active, .btn-violet-light:not(:disabled):not(.disabled).active,
.show > .btn-violet-light.dropdown-toggle {
  color: #fff;
  background-color: #8c477e;
  border-color: #834376;
}

.btn-violet-light:not(:disabled):not(.disabled):active:focus, .btn-violet-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(184, 116, 170, 0.5);
}

.btn-violet-extra-light {
  color: #303030;
  background-color: #E5CEE0;
  border-color: #E5CEE0;
}

.btn-violet-extra-light:hover {
  color: #303030;
  background-color: #d8b5d0;
  border-color: #d3adcb;
}

.btn-violet-extra-light:focus, .btn-violet-extra-light.focus {
  color: #303030;
  background-color: #d8b5d0;
  border-color: #d3adcb;
  box-shadow: 0 0 0 0.2rem rgba(202, 182, 198, 0.5);
}

.btn-violet-extra-light.disabled, .btn-violet-extra-light:disabled {
  color: #303030;
  background-color: #E5CEE0;
  border-color: #E5CEE0;
}

.btn-violet-extra-light:not(:disabled):not(.disabled):active, .btn-violet-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-violet-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #d3adcb;
  border-color: #cfa4c6;
}

.btn-violet-extra-light:not(:disabled):not(.disabled):active:focus, .btn-violet-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-violet-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(202, 182, 198, 0.5);
}

.btn-white {
  color: #303030;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #303030;
  background-color: #ececec;
  border-color: #e6e5e5;
}

.btn-white:focus, .btn-white.focus {
  color: #303030;
  background-color: #ececec;
  border-color: #e6e5e5;
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #303030;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #303030;
  background-color: #e6e5e5;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5);
}

.btn-outline-primary {
  color: #0066A3;
  border-color: #0066A3;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #0066A3;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.btn-outline-secondary {
  color: #999999;
  border-color: #999999;
}

.btn-outline-secondary:hover {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #999999;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-success {
  color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #81AC48;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-info {
  color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-info:hover {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #67CBC9;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.btn-outline-warning {
  color: #F0AD4E;
  border-color: #F0AD4E;
}

.btn-outline-warning:hover {
  color: #303030;
  background-color: #F0AD4E;
  border-color: #F0AD4E;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #F0AD4E;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #303030;
  background-color: #F0AD4E;
  border-color: #F0AD4E;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}

.btn-outline-danger {
  color: #D9534F;
  border-color: #D9534F;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #D9534F;
  border-color: #D9534F;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #D9534F;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #D9534F;
  border-color: #D9534F;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.btn-outline-light {
  color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-light:hover {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f8f8;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-dark {
  color: #505050;
  border-color: #505050;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #505050;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-primary-dark {
  color: #273953;
  border-color: #273953;
}

.btn-outline-primary-dark:hover {
  color: #fff;
  background-color: #273953;
  border-color: #273953;
}

.btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 57, 83, 0.5);
}

.btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
  color: #273953;
  background-color: transparent;
}

.btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-dark.dropdown-toggle {
  color: #fff;
  background-color: #273953;
  border-color: #273953;
}

.btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(39, 57, 83, 0.5);
}

.btn-outline-mid {
  color: #999999;
  border-color: #999999;
}

.btn-outline-mid:hover {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-mid:focus, .btn-outline-mid.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-mid.disabled, .btn-outline-mid:disabled {
  color: #999999;
  background-color: transparent;
}

.btn-outline-mid:not(:disabled):not(.disabled):active, .btn-outline-mid:not(:disabled):not(.disabled).active,
.show > .btn-outline-mid.dropdown-toggle {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-mid:not(:disabled):not(.disabled):active:focus, .btn-outline-mid:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-mid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-mid-light {
  color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-mid-light:hover {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-mid-light:focus, .btn-outline-mid-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-mid-light.disabled, .btn-outline-mid-light:disabled {
  color: #f8f8f8;
  background-color: transparent;
}

.btn-outline-mid-light:not(:disabled):not(.disabled):active, .btn-outline-mid-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-mid-light.dropdown-toggle {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-mid-light:not(:disabled):not(.disabled):active:focus, .btn-outline-mid-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-mid-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-accent {
  color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-accent:focus, .btn-outline-accent.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-accent.disabled, .btn-outline-accent:disabled {
  color: #81AC48;
  background-color: transparent;
}

.btn-outline-accent:not(:disabled):not(.disabled):active, .btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus, .btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-accent-light {
  color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-accent-light:hover {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-accent-light:focus, .btn-outline-accent-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.btn-outline-accent-light.disabled, .btn-outline-accent-light:disabled {
  color: #B5D190;
  background-color: transparent;
}

.btn-outline-accent-light:not(:disabled):not(.disabled):active, .btn-outline-accent-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-light.dropdown-toggle {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-accent-light:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.btn-outline-accent-extra-light {
  color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-accent-extra-light:hover {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-accent-extra-light:focus, .btn-outline-accent-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.btn-outline-accent-extra-light.disabled, .btn-outline-accent-extra-light:disabled {
  color: #DFE8D4;
  background-color: transparent;
}

.btn-outline-accent-extra-light:not(:disabled):not(.disabled):active, .btn-outline-accent-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-accent-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-accent-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.btn-outline-aha {
  color: #003087;
  border-color: #003087;
}

.btn-outline-aha:hover {
  color: #fff;
  background-color: #003087;
  border-color: #003087;
}

.btn-outline-aha:focus, .btn-outline-aha.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}

.btn-outline-aha.disabled, .btn-outline-aha:disabled {
  color: #003087;
  background-color: transparent;
}

.btn-outline-aha:not(:disabled):not(.disabled):active, .btn-outline-aha:not(:disabled):not(.disabled).active,
.show > .btn-outline-aha.dropdown-toggle {
  color: #fff;
  background-color: #003087;
  border-color: #003087;
}

.btn-outline-aha:not(:disabled):not(.disabled):active:focus, .btn-outline-aha:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-aha.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}

.btn-outline-aha-blue {
  color: #B9D9EB;
  border-color: #B9D9EB;
}

.btn-outline-aha-blue:hover {
  color: #303030;
  background-color: #B9D9EB;
  border-color: #B9D9EB;
}

.btn-outline-aha-blue:focus, .btn-outline-aha-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(185, 217, 235, 0.5);
}

.btn-outline-aha-blue.disabled, .btn-outline-aha-blue:disabled {
  color: #B9D9EB;
  background-color: transparent;
}

.btn-outline-aha-blue:not(:disabled):not(.disabled):active, .btn-outline-aha-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-aha-blue.dropdown-toggle {
  color: #303030;
  background-color: #B9D9EB;
  border-color: #B9D9EB;
}

.btn-outline-aha-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-aha-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-aha-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(185, 217, 235, 0.5);
}

.btn-outline-black {
  color: #000;
  border-color: #000;
}

.btn-outline-black:hover {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:focus, .btn-outline-black.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-black.disabled, .btn-outline-black:disabled {
  color: #000;
  background-color: transparent;
}

.btn-outline-black:not(:disabled):not(.disabled):active, .btn-outline-black:not(:disabled):not(.disabled).active,
.show > .btn-outline-black.dropdown-toggle {
  color: #fff;
  background-color: #000;
  border-color: #000;
}

.btn-outline-black:not(:disabled):not(.disabled):active:focus, .btn-outline-black:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-black.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-outline-blue-dark {
  color: #0066a3;
  border-color: #0066a3;
}

.btn-outline-blue-dark:hover {
  color: #fff;
  background-color: #0066a3;
  border-color: #0066a3;
}

.btn-outline-blue-dark:focus, .btn-outline-blue-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.btn-outline-blue-dark.disabled, .btn-outline-blue-dark:disabled {
  color: #0066a3;
  background-color: transparent;
}

.btn-outline-blue-dark:not(:disabled):not(.disabled):active, .btn-outline-blue-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-dark.dropdown-toggle {
  color: #fff;
  background-color: #0066a3;
  border-color: #0066a3;
}

.btn-outline-blue-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.btn-outline-blue-light {
  color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-blue-light:hover {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-blue-light:focus, .btn-outline-blue-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.btn-outline-blue-light.disabled, .btn-outline-blue-light:disabled {
  color: #0077bf;
  background-color: transparent;
}

.btn-outline-blue-light:not(:disabled):not(.disabled):active, .btn-outline-blue-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-light.dropdown-toggle {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-blue-light:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.btn-outline-blue-sky {
  color: #3CA7E6;
  border-color: #3CA7E6;
}

.btn-outline-blue-sky:hover {
  color: #fff;
  background-color: #3CA7E6;
  border-color: #3CA7E6;
}

.btn-outline-blue-sky:focus, .btn-outline-blue-sky.focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 167, 230, 0.5);
}

.btn-outline-blue-sky.disabled, .btn-outline-blue-sky:disabled {
  color: #3CA7E6;
  background-color: transparent;
}

.btn-outline-blue-sky:not(:disabled):not(.disabled):active, .btn-outline-blue-sky:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-sky.dropdown-toggle {
  color: #fff;
  background-color: #3CA7E6;
  border-color: #3CA7E6;
}

.btn-outline-blue-sky:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-sky:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-sky.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(60, 167, 230, 0.5);
}

.btn-outline-blue-pale {
  color: #56AADB;
  border-color: #56AADB;
}

.btn-outline-blue-pale:hover {
  color: #303030;
  background-color: #56AADB;
  border-color: #56AADB;
}

.btn-outline-blue-pale:focus, .btn-outline-blue-pale.focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 170, 219, 0.5);
}

.btn-outline-blue-pale.disabled, .btn-outline-blue-pale:disabled {
  color: #56AADB;
  background-color: transparent;
}

.btn-outline-blue-pale:not(:disabled):not(.disabled):active, .btn-outline-blue-pale:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-pale.dropdown-toggle {
  color: #303030;
  background-color: #56AADB;
  border-color: #56AADB;
}

.btn-outline-blue-pale:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-pale:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-pale.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(86, 170, 219, 0.5);
}

.btn-outline-blue-pulse {
  color: #0076BC;
  border-color: #0076BC;
}

.btn-outline-blue-pulse:hover {
  color: #fff;
  background-color: #0076BC;
  border-color: #0076BC;
}

.btn-outline-blue-pulse:focus, .btn-outline-blue-pulse.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 118, 188, 0.5);
}

.btn-outline-blue-pulse.disabled, .btn-outline-blue-pulse:disabled {
  color: #0076BC;
  background-color: transparent;
}

.btn-outline-blue-pulse:not(:disabled):not(.disabled):active, .btn-outline-blue-pulse:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-pulse.dropdown-toggle {
  color: #fff;
  background-color: #0076BC;
  border-color: #0076BC;
}

.btn-outline-blue-pulse:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-pulse:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-pulse.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 118, 188, 0.5);
}

.btn-outline-blue-extra-super-light {
  color: #E5F2F2;
  border-color: #E5F2F2;
}

.btn-outline-blue-extra-super-light:hover {
  color: #303030;
  background-color: #E5F2F2;
  border-color: #E5F2F2;
}

.btn-outline-blue-extra-super-light:focus, .btn-outline-blue-extra-super-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 242, 242, 0.5);
}

.btn-outline-blue-extra-super-light.disabled, .btn-outline-blue-extra-super-light:disabled {
  color: #E5F2F2;
  background-color: transparent;
}

.btn-outline-blue-extra-super-light:not(:disabled):not(.disabled):active, .btn-outline-blue-extra-super-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-extra-super-light.dropdown-toggle {
  color: #303030;
  background-color: #E5F2F2;
  border-color: #E5F2F2;
}

.btn-outline-blue-extra-super-light:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-extra-super-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-extra-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 242, 242, 0.5);
}

.btn-outline-blue-extra-light {
  color: #C6D1E1;
  border-color: #C6D1E1;
}

.btn-outline-blue-extra-light:hover {
  color: #303030;
  background-color: #C6D1E1;
  border-color: #C6D1E1;
}

.btn-outline-blue-extra-light:focus, .btn-outline-blue-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 209, 225, 0.5);
}

.btn-outline-blue-extra-light.disabled, .btn-outline-blue-extra-light:disabled {
  color: #C6D1E1;
  background-color: transparent;
}

.btn-outline-blue-extra-light:not(:disabled):not(.disabled):active, .btn-outline-blue-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #C6D1E1;
  border-color: #C6D1E1;
}

.btn-outline-blue-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(198, 209, 225, 0.5);
}

.btn-outline-blue-super-light {
  color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-blue-super-light:hover {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-blue-super-light:focus, .btn-outline-blue-super-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.btn-outline-blue-super-light.disabled, .btn-outline-blue-super-light:disabled {
  color: #F2F6FA;
  background-color: transparent;
}

.btn-outline-blue-super-light:not(:disabled):not(.disabled):active, .btn-outline-blue-super-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-blue-super-light.dropdown-toggle {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-blue-super-light:not(:disabled):not(.disabled):active:focus, .btn-outline-blue-super-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-blue-super-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.btn-outline-covid-blue {
  color: #44A4DB;
  border-color: #44A4DB;
}

.btn-outline-covid-blue:hover {
  color: #fff;
  background-color: #44A4DB;
  border-color: #44A4DB;
}

.btn-outline-covid-blue:focus, .btn-outline-covid-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 164, 219, 0.5);
}

.btn-outline-covid-blue.disabled, .btn-outline-covid-blue:disabled {
  color: #44A4DB;
  background-color: transparent;
}

.btn-outline-covid-blue:not(:disabled):not(.disabled):active, .btn-outline-covid-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-covid-blue.dropdown-toggle {
  color: #fff;
  background-color: #44A4DB;
  border-color: #44A4DB;
}

.btn-outline-covid-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-covid-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-covid-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(68, 164, 219, 0.5);
}

.btn-outline-gray-dark {
  color: #505050;
  border-color: #505050;
}

.btn-outline-gray-dark:hover {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-gray-dark:focus, .btn-outline-gray-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-gray-dark.disabled, .btn-outline-gray-dark:disabled {
  color: #505050;
  background-color: transparent;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active, .btn-outline-gray-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-dark.dropdown-toggle {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-gray-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-gray-light {
  color: #999999;
  border-color: #999999;
}

.btn-outline-gray-light:hover {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-gray-light:focus, .btn-outline-gray-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-gray-light.disabled, .btn-outline-gray-light:disabled {
  color: #999999;
  background-color: transparent;
}

.btn-outline-gray-light:not(:disabled):not(.disabled):active, .btn-outline-gray-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-light.dropdown-toggle {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-gray-light:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-gray-100 {
  color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-gray-100:hover {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-gray-100:focus, .btn-outline-gray-100.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-gray-100.disabled, .btn-outline-gray-100:disabled {
  color: #f8f8f8;
  background-color: transparent;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active, .btn-outline-gray-100:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-100.dropdown-toggle {
  color: #303030;
  background-color: #f8f8f8;
  border-color: #f8f8f8;
}

.btn-outline-gray-100:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-100:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-100.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.btn-outline-gray-150 {
  color: #F0F0F1;
  border-color: #F0F0F1;
}

.btn-outline-gray-150:hover {
  color: #303030;
  background-color: #F0F0F1;
  border-color: #F0F0F1;
}

.btn-outline-gray-150:focus, .btn-outline-gray-150.focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 241, 0.5);
}

.btn-outline-gray-150.disabled, .btn-outline-gray-150:disabled {
  color: #F0F0F1;
  background-color: transparent;
}

.btn-outline-gray-150:not(:disabled):not(.disabled):active, .btn-outline-gray-150:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-150.dropdown-toggle {
  color: #303030;
  background-color: #F0F0F1;
  border-color: #F0F0F1;
}

.btn-outline-gray-150:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-150:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-150.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 241, 0.5);
}

.btn-outline-gray-175 {
  color: #EFEFEF;
  border-color: #EFEFEF;
}

.btn-outline-gray-175:hover {
  color: #303030;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.btn-outline-gray-175:focus, .btn-outline-gray-175.focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5);
}

.btn-outline-gray-175.disabled, .btn-outline-gray-175:disabled {
  color: #EFEFEF;
  background-color: transparent;
}

.btn-outline-gray-175:not(:disabled):not(.disabled):active, .btn-outline-gray-175:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-175.dropdown-toggle {
  color: #303030;
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}

.btn-outline-gray-175:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-175:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-175.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5);
}

.btn-outline-gray-200 {
  color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-gray-200:hover {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-gray-200:focus, .btn-outline-gray-200.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.btn-outline-gray-200.disabled, .btn-outline-gray-200:disabled {
  color: #e5e5e5;
  background-color: transparent;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active, .btn-outline-gray-200:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-200.dropdown-toggle {
  color: #303030;
  background-color: #e5e5e5;
  border-color: #e5e5e5;
}

.btn-outline-gray-200:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-200:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-200.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.btn-outline-gray-300 {
  color: #dddddd;
  border-color: #dddddd;
}

.btn-outline-gray-300:hover {
  color: #303030;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-outline-gray-300:focus, .btn-outline-gray-300.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}

.btn-outline-gray-300.disabled, .btn-outline-gray-300:disabled {
  color: #dddddd;
  background-color: transparent;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active, .btn-outline-gray-300:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-300.dropdown-toggle {
  color: #303030;
  background-color: #dddddd;
  border-color: #dddddd;
}

.btn-outline-gray-300:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-300:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-300.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}

.btn-outline-gray-400 {
  color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-gray-400:hover {
  color: #303030;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-gray-400:focus, .btn-outline-gray-400.focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-outline-gray-400.disabled, .btn-outline-gray-400:disabled {
  color: #cccccc;
  background-color: transparent;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active, .btn-outline-gray-400:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-400.dropdown-toggle {
  color: #303030;
  background-color: #cccccc;
  border-color: #cccccc;
}

.btn-outline-gray-400:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-400:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-400.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.btn-outline-gray-500 {
  color: #ababab;
  border-color: #ababab;
}

.btn-outline-gray-500:hover {
  color: #303030;
  background-color: #ababab;
  border-color: #ababab;
}

.btn-outline-gray-500:focus, .btn-outline-gray-500.focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 171, 171, 0.5);
}

.btn-outline-gray-500.disabled, .btn-outline-gray-500:disabled {
  color: #ababab;
  background-color: transparent;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active, .btn-outline-gray-500:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-500.dropdown-toggle {
  color: #303030;
  background-color: #ababab;
  border-color: #ababab;
}

.btn-outline-gray-500:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-500:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 171, 171, 0.5);
}

.btn-outline-gray-600 {
  color: #999999;
  border-color: #999999;
}

.btn-outline-gray-600:hover {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-gray-600:focus, .btn-outline-gray-600.focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-gray-600.disabled, .btn-outline-gray-600:disabled {
  color: #999999;
  background-color: transparent;
}

.btn-outline-gray-600:not(:disabled):not(.disabled):active, .btn-outline-gray-600:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-600.dropdown-toggle {
  color: #303030;
  background-color: #999999;
  border-color: #999999;
}

.btn-outline-gray-600:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-600:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-600.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.btn-outline-gray-700 {
  color: #707070;
  border-color: #707070;
}

.btn-outline-gray-700:hover {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-outline-gray-700:focus, .btn-outline-gray-700.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.5);
}

.btn-outline-gray-700.disabled, .btn-outline-gray-700:disabled {
  color: #707070;
  background-color: transparent;
}

.btn-outline-gray-700:not(:disabled):not(.disabled):active, .btn-outline-gray-700:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #707070;
  border-color: #707070;
}

.btn-outline-gray-700:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-700:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.5);
}

.btn-outline-gray-800 {
  color: #505050;
  border-color: #505050;
}

.btn-outline-gray-800:hover {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-gray-800:focus, .btn-outline-gray-800.focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-gray-800.disabled, .btn-outline-gray-800:disabled {
  color: #505050;
  background-color: transparent;
}

.btn-outline-gray-800:not(:disabled):not(.disabled):active, .btn-outline-gray-800:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-800.dropdown-toggle {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.btn-outline-gray-800:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-800:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-800.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.btn-outline-gray-900 {
  color: #303030;
  border-color: #303030;
}

.btn-outline-gray-900:hover {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
}

.btn-outline-gray-900:focus, .btn-outline-gray-900.focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
}

.btn-outline-gray-900.disabled, .btn-outline-gray-900:disabled {
  color: #303030;
  background-color: transparent;
}

.btn-outline-gray-900:not(:disabled):not(.disabled):active, .btn-outline-gray-900:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray-900.dropdown-toggle {
  color: #fff;
  background-color: #303030;
  border-color: #303030;
}

.btn-outline-gray-900:not(:disabled):not(.disabled):active:focus, .btn-outline-gray-900:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray-900.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
}

.btn-outline-green-dark {
  color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-green-dark:hover {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-green-dark:focus, .btn-outline-green-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-green-dark.disabled, .btn-outline-green-dark:disabled {
  color: #81AC48;
  background-color: transparent;
}

.btn-outline-green-dark:not(:disabled):not(.disabled):active, .btn-outline-green-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-green-dark.dropdown-toggle {
  color: #fff;
  background-color: #81AC48;
  border-color: #81AC48;
}

.btn-outline-green-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-green-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.btn-outline-green-lime {
  color: #70963D;
  border-color: #70963D;
}

.btn-outline-green-lime:hover {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-outline-green-lime:focus, .btn-outline-green-lime.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.btn-outline-green-lime.disabled, .btn-outline-green-lime:disabled {
  color: #70963D;
  background-color: transparent;
}

.btn-outline-green-lime:not(:disabled):not(.disabled):active, .btn-outline-green-lime:not(:disabled):not(.disabled).active,
.show > .btn-outline-green-lime.dropdown-toggle {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-outline-green-lime:not(:disabled):not(.disabled):active:focus, .btn-outline-green-lime:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green-lime.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.btn-outline-green-light {
  color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-green-light:hover {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-green-light:focus, .btn-outline-green-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.btn-outline-green-light.disabled, .btn-outline-green-light:disabled {
  color: #B5D190;
  background-color: transparent;
}

.btn-outline-green-light:not(:disabled):not(.disabled):active, .btn-outline-green-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-green-light.dropdown-toggle {
  color: #303030;
  background-color: #B5D190;
  border-color: #B5D190;
}

.btn-outline-green-light:not(:disabled):not(.disabled):active:focus, .btn-outline-green-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.btn-outline-green-extra-light {
  color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-green-extra-light:hover {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-green-extra-light:focus, .btn-outline-green-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.btn-outline-green-extra-light.disabled, .btn-outline-green-extra-light:disabled {
  color: #DFE8D4;
  background-color: transparent;
}

.btn-outline-green-extra-light:not(:disabled):not(.disabled):active, .btn-outline-green-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-green-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #DFE8D4;
  border-color: #DFE8D4;
}

.btn-outline-green-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-green-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-green-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.btn-outline-highlight-green {
  color: #E2EAD8;
  border-color: #E2EAD8;
}

.btn-outline-highlight-green:hover {
  color: #303030;
  background-color: #E2EAD8;
  border-color: #E2EAD8;
}

.btn-outline-highlight-green:focus, .btn-outline-highlight-green.focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 234, 216, 0.5);
}

.btn-outline-highlight-green.disabled, .btn-outline-highlight-green:disabled {
  color: #E2EAD8;
  background-color: transparent;
}

.btn-outline-highlight-green:not(:disabled):not(.disabled):active, .btn-outline-highlight-green:not(:disabled):not(.disabled).active,
.show > .btn-outline-highlight-green.dropdown-toggle {
  color: #303030;
  background-color: #E2EAD8;
  border-color: #E2EAD8;
}

.btn-outline-highlight-green:not(:disabled):not(.disabled):active:focus, .btn-outline-highlight-green:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-highlight-green.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(226, 234, 216, 0.5);
}

.btn-outline-highlight-teal {
  color: #EAF7FF;
  border-color: #EAF7FF;
}

.btn-outline-highlight-teal:hover {
  color: #303030;
  background-color: #EAF7FF;
  border-color: #EAF7FF;
}

.btn-outline-highlight-teal:focus, .btn-outline-highlight-teal.focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 247, 255, 0.5);
}

.btn-outline-highlight-teal.disabled, .btn-outline-highlight-teal:disabled {
  color: #EAF7FF;
  background-color: transparent;
}

.btn-outline-highlight-teal:not(:disabled):not(.disabled):active, .btn-outline-highlight-teal:not(:disabled):not(.disabled).active,
.show > .btn-outline-highlight-teal.dropdown-toggle {
  color: #303030;
  background-color: #EAF7FF;
  border-color: #EAF7FF;
}

.btn-outline-highlight-teal:not(:disabled):not(.disabled):active:focus, .btn-outline-highlight-teal:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-highlight-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(234, 247, 255, 0.5);
}

.btn-outline-orange-dark {
  color: #F58238;
  border-color: #F58238;
}

.btn-outline-orange-dark:hover {
  color: #303030;
  background-color: #F58238;
  border-color: #F58238;
}

.btn-outline-orange-dark:focus, .btn-outline-orange-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 130, 56, 0.5);
}

.btn-outline-orange-dark.disabled, .btn-outline-orange-dark:disabled {
  color: #F58238;
  background-color: transparent;
}

.btn-outline-orange-dark:not(:disabled):not(.disabled):active, .btn-outline-orange-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange-dark.dropdown-toggle {
  color: #303030;
  background-color: #F58238;
  border-color: #F58238;
}

.btn-outline-orange-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-orange-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 130, 56, 0.5);
}

.btn-outline-orange-gold {
  color: #FF9E00;
  border-color: #FF9E00;
}

.btn-outline-orange-gold:hover {
  color: #303030;
  background-color: #FF9E00;
  border-color: #FF9E00;
}

.btn-outline-orange-gold:focus, .btn-outline-orange-gold.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 158, 0, 0.5);
}

.btn-outline-orange-gold.disabled, .btn-outline-orange-gold:disabled {
  color: #FF9E00;
  background-color: transparent;
}

.btn-outline-orange-gold:not(:disabled):not(.disabled):active, .btn-outline-orange-gold:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange-gold.dropdown-toggle {
  color: #303030;
  background-color: #FF9E00;
  border-color: #FF9E00;
}

.btn-outline-orange-gold:not(:disabled):not(.disabled):active:focus, .btn-outline-orange-gold:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange-gold.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 158, 0, 0.5);
}

.btn-outline-orange-light {
  color: #FC9552;
  border-color: #FC9552;
}

.btn-outline-orange-light:hover {
  color: #303030;
  background-color: #FC9552;
  border-color: #FC9552;
}

.btn-outline-orange-light:focus, .btn-outline-orange-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 149, 82, 0.5);
}

.btn-outline-orange-light.disabled, .btn-outline-orange-light:disabled {
  color: #FC9552;
  background-color: transparent;
}

.btn-outline-orange-light:not(:disabled):not(.disabled):active, .btn-outline-orange-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange-light.dropdown-toggle {
  color: #303030;
  background-color: #FC9552;
  border-color: #FC9552;
}

.btn-outline-orange-light:not(:disabled):not(.disabled):active:focus, .btn-outline-orange-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 149, 82, 0.5);
}

.btn-outline-orange-extra-light {
  color: #FCEACE;
  border-color: #FCEACE;
}

.btn-outline-orange-extra-light:hover {
  color: #303030;
  background-color: #FCEACE;
  border-color: #FCEACE;
}

.btn-outline-orange-extra-light:focus, .btn-outline-orange-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 234, 206, 0.5);
}

.btn-outline-orange-extra-light.disabled, .btn-outline-orange-extra-light:disabled {
  color: #FCEACE;
  background-color: transparent;
}

.btn-outline-orange-extra-light:not(:disabled):not(.disabled):active, .btn-outline-orange-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-orange-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #FCEACE;
  border-color: #FCEACE;
}

.btn-outline-orange-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-orange-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-orange-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(252, 234, 206, 0.5);
}

.btn-outline-primary-light {
  color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-primary-light:hover {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: #F2F6FA;
  background-color: transparent;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-light.dropdown-toggle {
  color: #303030;
  background-color: #F2F6FA;
  border-color: #F2F6FA;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.btn-outline-primary-mid {
  color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-primary-mid:hover {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-primary-mid:focus, .btn-outline-primary-mid.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.btn-outline-primary-mid.disabled, .btn-outline-primary-mid:disabled {
  color: #0077bf;
  background-color: transparent;
}

.btn-outline-primary-mid:not(:disabled):not(.disabled):active, .btn-outline-primary-mid:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-mid.dropdown-toggle {
  color: #fff;
  background-color: #0077bf;
  border-color: #0077bf;
}

.btn-outline-primary-mid:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-mid:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-mid.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.btn-outline-primary-mid-light {
  color: #afc6ec;
  border-color: #afc6ec;
}

.btn-outline-primary-mid-light:hover {
  color: #303030;
  background-color: #afc6ec;
  border-color: #afc6ec;
}

.btn-outline-primary-mid-light:focus, .btn-outline-primary-mid-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 198, 236, 0.5);
}

.btn-outline-primary-mid-light.disabled, .btn-outline-primary-mid-light:disabled {
  color: #afc6ec;
  background-color: transparent;
}

.btn-outline-primary-mid-light:not(:disabled):not(.disabled):active, .btn-outline-primary-mid-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-mid-light.dropdown-toggle {
  color: #303030;
  background-color: #afc6ec;
  border-color: #afc6ec;
}

.btn-outline-primary-mid-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-mid-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-mid-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(175, 198, 236, 0.5);
}

.btn-outline-pulse-blue {
  color: #0176bd;
  border-color: #0176bd;
}

.btn-outline-pulse-blue:hover {
  color: #fff;
  background-color: #0176bd;
  border-color: #0176bd;
}

.btn-outline-pulse-blue:focus, .btn-outline-pulse-blue.focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 118, 189, 0.5);
}

.btn-outline-pulse-blue.disabled, .btn-outline-pulse-blue:disabled {
  color: #0176bd;
  background-color: transparent;
}

.btn-outline-pulse-blue:not(:disabled):not(.disabled):active, .btn-outline-pulse-blue:not(:disabled):not(.disabled).active,
.show > .btn-outline-pulse-blue.dropdown-toggle {
  color: #fff;
  background-color: #0176bd;
  border-color: #0176bd;
}

.btn-outline-pulse-blue:not(:disabled):not(.disabled):active:focus, .btn-outline-pulse-blue:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pulse-blue.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(1, 118, 189, 0.5);
}

.btn-outline-pulse-benchmark {
  color: #70963D;
  border-color: #70963D;
}

.btn-outline-pulse-benchmark:hover {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-outline-pulse-benchmark:focus, .btn-outline-pulse-benchmark.focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.btn-outline-pulse-benchmark.disabled, .btn-outline-pulse-benchmark:disabled {
  color: #70963D;
  background-color: transparent;
}

.btn-outline-pulse-benchmark:not(:disabled):not(.disabled):active, .btn-outline-pulse-benchmark:not(:disabled):not(.disabled).active,
.show > .btn-outline-pulse-benchmark.dropdown-toggle {
  color: #fff;
  background-color: #70963D;
  border-color: #70963D;
}

.btn-outline-pulse-benchmark:not(:disabled):not(.disabled):active:focus, .btn-outline-pulse-benchmark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-pulse-benchmark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.btn-outline-red-dark {
  color: #922E2B;
  border-color: #922E2B;
}

.btn-outline-red-dark:hover {
  color: #fff;
  background-color: #922E2B;
  border-color: #922E2B;
}

.btn-outline-red-dark:focus, .btn-outline-red-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 46, 43, 0.5);
}

.btn-outline-red-dark.disabled, .btn-outline-red-dark:disabled {
  color: #922E2B;
  background-color: transparent;
}

.btn-outline-red-dark:not(:disabled):not(.disabled):active, .btn-outline-red-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-red-dark.dropdown-toggle {
  color: #fff;
  background-color: #922E2B;
  border-color: #922E2B;
}

.btn-outline-red-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-red-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 46, 43, 0.5);
}

.btn-outline-red-light {
  color: #AA3C39;
  border-color: #AA3C39;
}

.btn-outline-red-light:hover {
  color: #fff;
  background-color: #AA3C39;
  border-color: #AA3C39;
}

.btn-outline-red-light:focus, .btn-outline-red-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 60, 57, 0.5);
}

.btn-outline-red-light.disabled, .btn-outline-red-light:disabled {
  color: #AA3C39;
  background-color: transparent;
}

.btn-outline-red-light:not(:disabled):not(.disabled):active, .btn-outline-red-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-red-light.dropdown-toggle {
  color: #fff;
  background-color: #AA3C39;
  border-color: #AA3C39;
}

.btn-outline-red-light:not(:disabled):not(.disabled):active:focus, .btn-outline-red-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(170, 60, 57, 0.5);
}

.btn-outline-red-lighter {
  color: #D95350;
  border-color: #D95350;
}

.btn-outline-red-lighter:hover {
  color: #fff;
  background-color: #D95350;
  border-color: #D95350;
}

.btn-outline-red-lighter:focus, .btn-outline-red-lighter.focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 80, 0.5);
}

.btn-outline-red-lighter.disabled, .btn-outline-red-lighter:disabled {
  color: #D95350;
  background-color: transparent;
}

.btn-outline-red-lighter:not(:disabled):not(.disabled):active, .btn-outline-red-lighter:not(:disabled):not(.disabled).active,
.show > .btn-outline-red-lighter.dropdown-toggle {
  color: #fff;
  background-color: #D95350;
  border-color: #D95350;
}

.btn-outline-red-lighter:not(:disabled):not(.disabled):active:focus, .btn-outline-red-lighter:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red-lighter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 80, 0.5);
}

.btn-outline-red-extra-light {
  color: #E9CECD;
  border-color: #E9CECD;
}

.btn-outline-red-extra-light:hover {
  color: #303030;
  background-color: #E9CECD;
  border-color: #E9CECD;
}

.btn-outline-red-extra-light:focus, .btn-outline-red-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 206, 205, 0.5);
}

.btn-outline-red-extra-light.disabled, .btn-outline-red-extra-light:disabled {
  color: #E9CECD;
  background-color: transparent;
}

.btn-outline-red-extra-light:not(:disabled):not(.disabled):active, .btn-outline-red-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-red-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #E9CECD;
  border-color: #E9CECD;
}

.btn-outline-red-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-red-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-red-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(233, 206, 205, 0.5);
}

.btn-outline-teal-dark {
  color: #43B4B2;
  border-color: #43B4B2;
}

.btn-outline-teal-dark:hover {
  color: #fff;
  background-color: #43B4B2;
  border-color: #43B4B2;
}

.btn-outline-teal-dark:focus, .btn-outline-teal-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 180, 178, 0.5);
}

.btn-outline-teal-dark.disabled, .btn-outline-teal-dark:disabled {
  color: #43B4B2;
  background-color: transparent;
}

.btn-outline-teal-dark:not(:disabled):not(.disabled):active, .btn-outline-teal-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal-dark.dropdown-toggle {
  color: #fff;
  background-color: #43B4B2;
  border-color: #43B4B2;
}

.btn-outline-teal-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-teal-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 180, 178, 0.5);
}

.btn-outline-teal-light {
  color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-teal-light:hover {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-teal-light:focus, .btn-outline-teal-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.btn-outline-teal-light.disabled, .btn-outline-teal-light:disabled {
  color: #67CBC9;
  background-color: transparent;
}

.btn-outline-teal-light:not(:disabled):not(.disabled):active, .btn-outline-teal-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal-light.dropdown-toggle {
  color: #303030;
  background-color: #67CBC9;
  border-color: #67CBC9;
}

.btn-outline-teal-light:not(:disabled):not(.disabled):active:focus, .btn-outline-teal-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.btn-outline-teal-extra-light {
  color: #BEE4E3;
  border-color: #BEE4E3;
}

.btn-outline-teal-extra-light:hover {
  color: #303030;
  background-color: #BEE4E3;
  border-color: #BEE4E3;
}

.btn-outline-teal-extra-light:focus, .btn-outline-teal-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 228, 227, 0.5);
}

.btn-outline-teal-extra-light.disabled, .btn-outline-teal-extra-light:disabled {
  color: #BEE4E3;
  background-color: transparent;
}

.btn-outline-teal-extra-light:not(:disabled):not(.disabled):active, .btn-outline-teal-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-teal-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #BEE4E3;
  border-color: #BEE4E3;
}

.btn-outline-teal-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-teal-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-teal-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(190, 228, 227, 0.5);
}

.btn-outline-violet-dark {
  color: #945487;
  border-color: #945487;
}

.btn-outline-violet-dark:hover {
  color: #fff;
  background-color: #945487;
  border-color: #945487;
}

.btn-outline-violet-dark:focus, .btn-outline-violet-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 84, 135, 0.5);
}

.btn-outline-violet-dark.disabled, .btn-outline-violet-dark:disabled {
  color: #945487;
  background-color: transparent;
}

.btn-outline-violet-dark:not(:disabled):not(.disabled):active, .btn-outline-violet-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet-dark.dropdown-toggle {
  color: #fff;
  background-color: #945487;
  border-color: #945487;
}

.btn-outline-violet-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-violet-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(148, 84, 135, 0.5);
}

.btn-outline-violet-light {
  color: #AB5B9B;
  border-color: #AB5B9B;
}

.btn-outline-violet-light:hover {
  color: #fff;
  background-color: #AB5B9B;
  border-color: #AB5B9B;
}

.btn-outline-violet-light:focus, .btn-outline-violet-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 91, 155, 0.5);
}

.btn-outline-violet-light.disabled, .btn-outline-violet-light:disabled {
  color: #AB5B9B;
  background-color: transparent;
}

.btn-outline-violet-light:not(:disabled):not(.disabled):active, .btn-outline-violet-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet-light.dropdown-toggle {
  color: #fff;
  background-color: #AB5B9B;
  border-color: #AB5B9B;
}

.btn-outline-violet-light:not(:disabled):not(.disabled):active:focus, .btn-outline-violet-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(171, 91, 155, 0.5);
}

.btn-outline-violet-extra-light {
  color: #E5CEE0;
  border-color: #E5CEE0;
}

.btn-outline-violet-extra-light:hover {
  color: #303030;
  background-color: #E5CEE0;
  border-color: #E5CEE0;
}

.btn-outline-violet-extra-light:focus, .btn-outline-violet-extra-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 206, 224, 0.5);
}

.btn-outline-violet-extra-light.disabled, .btn-outline-violet-extra-light:disabled {
  color: #E5CEE0;
  background-color: transparent;
}

.btn-outline-violet-extra-light:not(:disabled):not(.disabled):active, .btn-outline-violet-extra-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-violet-extra-light.dropdown-toggle {
  color: #303030;
  background-color: #E5CEE0;
  border-color: #E5CEE0;
}

.btn-outline-violet-extra-light:not(:disabled):not(.disabled):active:focus, .btn-outline-violet-extra-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-violet-extra-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 206, 224, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #303030;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #303030;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #0066A3;
  text-decoration: none;
}

.btn-link:hover {
  color: #003657;
  text-decoration: underline;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #999999;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 4px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #303030;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e5e5e5;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #303030;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #232323;
  text-decoration: none;
  background-color: #e5e5e5;
}

.dropdown-item.active, .dropdown-item:active {
  color: #303030;
  text-decoration: none;
  background-color: #e5e5e5;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #ababab;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #999999;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #303030;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group.has-validation > .form-control:nth-last-child(n + 3),
.input-group.has-validation > .custom-select:nth-last-child(n + 3),
.input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  text-align: center;
  white-space: nowrap;
  background-color: #e5e5e5;
  border: 1px solid #cccccc;
  border-radius: 2px;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 2px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
  border-radius: 2px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.3125rem;
  padding-left: 1.5rem;
  color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #0066A3;
  background-color: #0066A3;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #24adff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #57c0ff;
  border-color: #57c0ff;
}

.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #999999;
}

.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e5e5e5;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #ababab solid 1px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.15625rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50% / 50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0066A3;
  background-color: #0066A3;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 102, 163, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(0, 102, 163, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 102, 163, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #ababab;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(0, 102, 163, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23505050' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #cccccc;
  border-radius: 0.25rem;
  appearance: none;
}

.custom-select:focus {
  border-color: #24adff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-select:focus::-ms-value {
  color: #707070;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: #999999;
  background-color: #e5e5e5;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #707070;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.75rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #24adff;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #e5e5e5;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #707070;
  background-color: #fff;
  border: 1px solid #cccccc;
  border-radius: 2px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #707070;
  content: "Browse";
  background-color: #e5e5e5;
  border-left: inherit;
  border-radius: 0 2px 2px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: 0;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0066A3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #57c0ff;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddddd;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0066A3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #57c0ff;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dddddd;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #0066A3;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #57c0ff;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dddddd;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dddddd;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #ababab;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #ababab;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #ababab;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #999999;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dddddd;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e5e5e5 #e5e5e5 #dddddd;
}

.nav-tabs .nav-link.disabled {
  color: #999999;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #707070;
  background-color: #fff;
  border-color: #dddddd #dddddd #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0066A3;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.40625rem;
  padding-bottom: 0.40625rem;
  margin-right: 1rem;
  font-size: 1rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: #cccccc;
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 0 0 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: #cccccc;
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 0 0;
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 0;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.card-deck .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -1rem;
    margin-left: -1rem;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 1rem;
    margin-bottom: 0;
    margin-left: 1rem;
  }
}

.card-group > .card {
  margin-bottom: 1rem;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.5rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e5e5e5;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #999999;
  content: "/";
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #999999;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0066A3;
  background-color: #fff;
  border: 1px solid #dddddd;
}

.page-link:hover {
  z-index: 2;
  color: #003657;
  text-decoration: none;
  background-color: #e5e5e5;
  border-color: #dddddd;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.page-item.disabled .page-link {
  color: #999999;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dddddd;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.5em;
  font-size: 75%;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 0.1875rem;
}

.badge-primary {
  color: #fff;
  background-color: #0066A3;
}

a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #004670;
}

a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.badge-secondary {
  color: #303030;
  background-color: #999999;
}

a.badge-secondary:hover, a.badge-secondary:focus {
  color: #303030;
  background-color: #807f7f;
}

a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #81AC48;
}

a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #668839;
}

a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.badge-info {
  color: #303030;
  background-color: #67CBC9;
}

a.badge-info:hover, a.badge-info:focus {
  color: #303030;
  background-color: #41bebc;
}

a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.badge-warning {
  color: #303030;
  background-color: #F0AD4E;
}

a.badge-warning:hover, a.badge-warning:focus {
  color: #303030;
  background-color: #ec971f;
}

a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 173, 78, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #D9534F;
}

a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #c9302c;
}

a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 79, 0.5);
}

.badge-light {
  color: #303030;
  background-color: #f8f8f8;
}

a.badge-light:hover, a.badge-light:focus {
  color: #303030;
  background-color: #dfdede;
}

a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #505050;
}

a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #373636;
}

a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.badge-primary-dark {
  color: #fff;
  background-color: #273953;
}

a.badge-primary-dark:hover, a.badge-primary-dark:focus {
  color: #fff;
  background-color: #172130;
}

a.badge-primary-dark:focus, a.badge-primary-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(39, 57, 83, 0.5);
}

.badge-mid {
  color: #303030;
  background-color: #999999;
}

a.badge-mid:hover, a.badge-mid:focus {
  color: #303030;
  background-color: #807f7f;
}

a.badge-mid:focus, a.badge-mid.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-mid-light {
  color: #303030;
  background-color: #f8f8f8;
}

a.badge-mid-light:hover, a.badge-mid-light:focus {
  color: #303030;
  background-color: #dfdede;
}

a.badge-mid-light:focus, a.badge-mid-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.badge-accent {
  color: #fff;
  background-color: #81AC48;
}

a.badge-accent:hover, a.badge-accent:focus {
  color: #fff;
  background-color: #668839;
}

a.badge-accent:focus, a.badge-accent.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.badge-accent-light {
  color: #303030;
  background-color: #B5D190;
}

a.badge-accent-light:hover, a.badge-accent-light:focus {
  color: #303030;
  background-color: #9dc26c;
}

a.badge-accent-light:focus, a.badge-accent-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.badge-accent-extra-light {
  color: #303030;
  background-color: #DFE8D4;
}

a.badge-accent-extra-light:hover, a.badge-accent-extra-light:focus {
  color: #303030;
  background-color: #c6d6b3;
}

a.badge-accent-extra-light:focus, a.badge-accent-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.badge-aha {
  color: #fff;
  background-color: #003087;
}

a.badge-aha:hover, a.badge-aha:focus {
  color: #fff;
  background-color: #001e54;
}

a.badge-aha:focus, a.badge-aha.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 48, 135, 0.5);
}

.badge-aha-blue {
  color: #303030;
  background-color: #B9D9EB;
}

a.badge-aha-blue:hover, a.badge-aha-blue:focus {
  color: #303030;
  background-color: #91c3e0;
}

a.badge-aha-blue:focus, a.badge-aha-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(185, 217, 235, 0.5);
}

.badge-black {
  color: #fff;
  background-color: #000;
}

a.badge-black:hover, a.badge-black:focus {
  color: #fff;
  background-color: black;
}

a.badge-black:focus, a.badge-black.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.badge-blue-dark {
  color: #fff;
  background-color: #0066a3;
}

a.badge-blue-dark:hover, a.badge-blue-dark:focus {
  color: #fff;
  background-color: #004670;
}

a.badge-blue-dark:focus, a.badge-blue-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 102, 163, 0.5);
}

.badge-blue-light {
  color: #fff;
  background-color: #0077bf;
}

a.badge-blue-light:hover, a.badge-blue-light:focus {
  color: #fff;
  background-color: #00578c;
}

a.badge-blue-light:focus, a.badge-blue-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.badge-blue-sky {
  color: #fff;
  background-color: #3CA7E6;
}

a.badge-blue-sky:hover, a.badge-blue-sky:focus {
  color: #fff;
  background-color: #1b8fd4;
}

a.badge-blue-sky:focus, a.badge-blue-sky.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(60, 167, 230, 0.5);
}

.badge-blue-pale {
  color: #303030;
  background-color: #56AADB;
}

a.badge-blue-pale:hover, a.badge-blue-pale:focus {
  color: #303030;
  background-color: #2d95d1;
}

a.badge-blue-pale:focus, a.badge-blue-pale.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(86, 170, 219, 0.5);
}

.badge-blue-pulse {
  color: #fff;
  background-color: #0076BC;
}

a.badge-blue-pulse:hover, a.badge-blue-pulse:focus {
  color: #fff;
  background-color: #005689;
}

a.badge-blue-pulse:focus, a.badge-blue-pulse.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 118, 188, 0.5);
}

.badge-blue-extra-super-light {
  color: #303030;
  background-color: #E5F2F2;
}

a.badge-blue-extra-super-light:hover, a.badge-blue-extra-super-light:focus {
  color: #303030;
  background-color: #c3e1e1;
}

a.badge-blue-extra-super-light:focus, a.badge-blue-extra-super-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 242, 242, 0.5);
}

.badge-blue-extra-light {
  color: #303030;
  background-color: #C6D1E1;
}

a.badge-blue-extra-light:hover, a.badge-blue-extra-light:focus {
  color: #303030;
  background-color: #a5b6cf;
}

a.badge-blue-extra-light:focus, a.badge-blue-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(198, 209, 225, 0.5);
}

.badge-blue-super-light {
  color: #303030;
  background-color: #F2F6FA;
}

a.badge-blue-super-light:hover, a.badge-blue-super-light:focus {
  color: #303030;
  background-color: #cdddec;
}

a.badge-blue-super-light:focus, a.badge-blue-super-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.badge-covid-blue {
  color: #fff;
  background-color: #44A4DB;
}

a.badge-covid-blue:hover, a.badge-covid-blue:focus {
  color: #fff;
  background-color: #268cc6;
}

a.badge-covid-blue:focus, a.badge-covid-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(68, 164, 219, 0.5);
}

.badge-gray-dark {
  color: #fff;
  background-color: #505050;
}

a.badge-gray-dark:hover, a.badge-gray-dark:focus {
  color: #fff;
  background-color: #373636;
}

a.badge-gray-dark:focus, a.badge-gray-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.badge-gray-light {
  color: #303030;
  background-color: #999999;
}

a.badge-gray-light:hover, a.badge-gray-light:focus {
  color: #303030;
  background-color: #807f7f;
}

a.badge-gray-light:focus, a.badge-gray-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-gray-100 {
  color: #303030;
  background-color: #f8f8f8;
}

a.badge-gray-100:hover, a.badge-gray-100:focus {
  color: #303030;
  background-color: #dfdede;
}

a.badge-gray-100:focus, a.badge-gray-100.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 248, 248, 0.5);
}

.badge-gray-150 {
  color: #303030;
  background-color: #F0F0F1;
}

a.badge-gray-150:hover, a.badge-gray-150:focus {
  color: #303030;
  background-color: #d6d6d8;
}

a.badge-gray-150:focus, a.badge-gray-150.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(240, 240, 241, 0.5);
}

.badge-gray-175 {
  color: #303030;
  background-color: #EFEFEF;
}

a.badge-gray-175:hover, a.badge-gray-175:focus {
  color: #303030;
  background-color: #d6d5d5;
}

a.badge-gray-175:focus, a.badge-gray-175.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(239, 239, 239, 0.5);
}

.badge-gray-200 {
  color: #303030;
  background-color: #e5e5e5;
}

a.badge-gray-200:hover, a.badge-gray-200:focus {
  color: #303030;
  background-color: #cccbcb;
}

a.badge-gray-200:focus, a.badge-gray-200.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 229, 229, 0.5);
}

.badge-gray-300 {
  color: #303030;
  background-color: #dddddd;
}

a.badge-gray-300:hover, a.badge-gray-300:focus {
  color: #303030;
  background-color: #c4c3c3;
}

a.badge-gray-300:focus, a.badge-gray-300.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(221, 221, 221, 0.5);
}

.badge-gray-400 {
  color: #303030;
  background-color: #cccccc;
}

a.badge-gray-400:hover, a.badge-gray-400:focus {
  color: #303030;
  background-color: #b3b2b2;
}

a.badge-gray-400:focus, a.badge-gray-400.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(204, 204, 204, 0.5);
}

.badge-gray-500 {
  color: #303030;
  background-color: #ababab;
}

a.badge-gray-500:hover, a.badge-gray-500:focus {
  color: #303030;
  background-color: #929191;
}

a.badge-gray-500:focus, a.badge-gray-500.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(171, 171, 171, 0.5);
}

.badge-gray-600 {
  color: #303030;
  background-color: #999999;
}

a.badge-gray-600:hover, a.badge-gray-600:focus {
  color: #303030;
  background-color: #807f7f;
}

a.badge-gray-600:focus, a.badge-gray-600.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(153, 153, 153, 0.5);
}

.badge-gray-700 {
  color: #fff;
  background-color: #707070;
}

a.badge-gray-700:hover, a.badge-gray-700:focus {
  color: #fff;
  background-color: #575656;
}

a.badge-gray-700:focus, a.badge-gray-700.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(112, 112, 112, 0.5);
}

.badge-gray-800 {
  color: #fff;
  background-color: #505050;
}

a.badge-gray-800:hover, a.badge-gray-800:focus {
  color: #fff;
  background-color: #373636;
}

a.badge-gray-800:focus, a.badge-gray-800.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(80, 80, 80, 0.5);
}

.badge-gray-900 {
  color: #fff;
  background-color: #303030;
}

a.badge-gray-900:hover, a.badge-gray-900:focus {
  color: #fff;
  background-color: #171616;
}

a.badge-gray-900:focus, a.badge-gray-900.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(48, 48, 48, 0.5);
}

.badge-green-dark {
  color: #fff;
  background-color: #81AC48;
}

a.badge-green-dark:hover, a.badge-green-dark:focus {
  color: #fff;
  background-color: #668839;
}

a.badge-green-dark:focus, a.badge-green-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(129, 172, 72, 0.5);
}

.badge-green-lime {
  color: #fff;
  background-color: #70963D;
}

a.badge-green-lime:hover, a.badge-green-lime:focus {
  color: #fff;
  background-color: #55722e;
}

a.badge-green-lime:focus, a.badge-green-lime.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.badge-green-light {
  color: #303030;
  background-color: #B5D190;
}

a.badge-green-light:hover, a.badge-green-light:focus {
  color: #303030;
  background-color: #9dc26c;
}

a.badge-green-light:focus, a.badge-green-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(181, 209, 144, 0.5);
}

.badge-green-extra-light {
  color: #303030;
  background-color: #DFE8D4;
}

a.badge-green-extra-light:hover, a.badge-green-extra-light:focus {
  color: #303030;
  background-color: #c6d6b3;
}

a.badge-green-extra-light:focus, a.badge-green-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(223, 232, 212, 0.5);
}

.badge-highlight-green {
  color: #303030;
  background-color: #E2EAD8;
}

a.badge-highlight-green:hover, a.badge-highlight-green:focus {
  color: #303030;
  background-color: #c9d8b7;
}

a.badge-highlight-green:focus, a.badge-highlight-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(226, 234, 216, 0.5);
}

.badge-highlight-teal {
  color: #303030;
  background-color: #EAF7FF;
}

a.badge-highlight-teal:hover, a.badge-highlight-teal:focus {
  color: #303030;
  background-color: #b7e4ff;
}

a.badge-highlight-teal:focus, a.badge-highlight-teal.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(234, 247, 255, 0.5);
}

.badge-orange-dark {
  color: #303030;
  background-color: #F58238;
}

a.badge-orange-dark:hover, a.badge-orange-dark:focus {
  color: #303030;
  background-color: #ee640c;
}

a.badge-orange-dark:focus, a.badge-orange-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 130, 56, 0.5);
}

.badge-orange-gold {
  color: #303030;
  background-color: #FF9E00;
}

a.badge-orange-gold:hover, a.badge-orange-gold:focus {
  color: #303030;
  background-color: #cc7e00;
}

a.badge-orange-gold:focus, a.badge-orange-gold.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 158, 0, 0.5);
}

.badge-orange-light {
  color: #303030;
  background-color: #FC9552;
}

a.badge-orange-light:hover, a.badge-orange-light:focus {
  color: #303030;
  background-color: #fb7620;
}

a.badge-orange-light:focus, a.badge-orange-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 149, 82, 0.5);
}

.badge-orange-extra-light {
  color: #303030;
  background-color: #FCEACE;
}

a.badge-orange-extra-light:hover, a.badge-orange-extra-light:focus {
  color: #303030;
  background-color: #f9d59e;
}

a.badge-orange-extra-light:focus, a.badge-orange-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(252, 234, 206, 0.5);
}

.badge-primary-light {
  color: #303030;
  background-color: #F2F6FA;
}

a.badge-primary-light:hover, a.badge-primary-light:focus {
  color: #303030;
  background-color: #cdddec;
}

a.badge-primary-light:focus, a.badge-primary-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(242, 246, 250, 0.5);
}

.badge-primary-mid {
  color: #fff;
  background-color: #0077bf;
}

a.badge-primary-mid:hover, a.badge-primary-mid:focus {
  color: #fff;
  background-color: #00578c;
}

a.badge-primary-mid:focus, a.badge-primary-mid.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 119, 191, 0.5);
}

.badge-primary-mid-light {
  color: #303030;
  background-color: #afc6ec;
}

a.badge-primary-mid-light:hover, a.badge-primary-mid-light:focus {
  color: #303030;
  background-color: #86a9e2;
}

a.badge-primary-mid-light:focus, a.badge-primary-mid-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(175, 198, 236, 0.5);
}

.badge-pulse-blue {
  color: #fff;
  background-color: #0176bd;
}

a.badge-pulse-blue:hover, a.badge-pulse-blue:focus {
  color: #fff;
  background-color: #01568a;
}

a.badge-pulse-blue:focus, a.badge-pulse-blue.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(1, 118, 189, 0.5);
}

.badge-pulse-benchmark {
  color: #fff;
  background-color: #70963D;
}

a.badge-pulse-benchmark:hover, a.badge-pulse-benchmark:focus {
  color: #fff;
  background-color: #55722e;
}

a.badge-pulse-benchmark:focus, a.badge-pulse-benchmark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(112, 150, 61, 0.5);
}

.badge-red-dark {
  color: #fff;
  background-color: #922E2B;
}

a.badge-red-dark:hover, a.badge-red-dark:focus {
  color: #fff;
  background-color: #6b221f;
}

a.badge-red-dark:focus, a.badge-red-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(146, 46, 43, 0.5);
}

.badge-red-light {
  color: #fff;
  background-color: #AA3C39;
}

a.badge-red-light:hover, a.badge-red-light:focus {
  color: #fff;
  background-color: #842f2c;
}

a.badge-red-light:focus, a.badge-red-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(170, 60, 57, 0.5);
}

.badge-red-lighter {
  color: #fff;
  background-color: #D95350;
}

a.badge-red-lighter:hover, a.badge-red-lighter:focus {
  color: #fff;
  background-color: #ca2f2c;
}

a.badge-red-lighter:focus, a.badge-red-lighter.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(217, 83, 80, 0.5);
}

.badge-red-extra-light {
  color: #303030;
  background-color: #E9CECD;
}

a.badge-red-extra-light:hover, a.badge-red-extra-light:focus {
  color: #303030;
  background-color: #d9abaa;
}

a.badge-red-extra-light:focus, a.badge-red-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(233, 206, 205, 0.5);
}

.badge-teal-dark {
  color: #fff;
  background-color: #43B4B2;
}

a.badge-teal-dark:hover, a.badge-teal-dark:focus {
  color: #fff;
  background-color: #358f8d;
}

a.badge-teal-dark:focus, a.badge-teal-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 180, 178, 0.5);
}

.badge-teal-light {
  color: #303030;
  background-color: #67CBC9;
}

a.badge-teal-light:hover, a.badge-teal-light:focus {
  color: #303030;
  background-color: #41bebc;
}

a.badge-teal-light:focus, a.badge-teal-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(103, 203, 201, 0.5);
}

.badge-teal-extra-light {
  color: #303030;
  background-color: #BEE4E3;
}

a.badge-teal-extra-light:hover, a.badge-teal-extra-light:focus {
  color: #303030;
  background-color: #9ad5d3;
}

a.badge-teal-extra-light:focus, a.badge-teal-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(190, 228, 227, 0.5);
}

.badge-violet-dark {
  color: #fff;
  background-color: #945487;
}

a.badge-violet-dark:hover, a.badge-violet-dark:focus {
  color: #fff;
  background-color: #734269;
}

a.badge-violet-dark:focus, a.badge-violet-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(148, 84, 135, 0.5);
}

.badge-violet-light {
  color: #fff;
  background-color: #AB5B9B;
}

a.badge-violet-light:hover, a.badge-violet-light:focus {
  color: #fff;
  background-color: #8c477e;
}

a.badge-violet-light:focus, a.badge-violet-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(171, 91, 155, 0.5);
}

.badge-violet-extra-light {
  color: #303030;
  background-color: #E5CEE0;
}

a.badge-violet-extra-light:hover, a.badge-violet-extra-light:focus {
  color: #303030;
  background-color: #d3adcb;
}

a.badge-violet-extra-light:focus, a.badge-violet-extra-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 206, 224, 0.5);
}

.badge-white {
  color: #303030;
  background-color: #fff;
}

a.badge-white:hover, a.badge-white:focus {
  color: #303030;
  background-color: #e6e5e5;
}

a.badge-white:focus, a.badge-white.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e5e5e5;
  border-radius: 0;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 500;
}

.alert-dismissible {
  padding-right: 3.8125rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #003555;
  background-color: #cce0ed;
  border-color: #b8d4e5;
}

.alert-primary hr {
  border-top-color: #a5c9de;
}

.alert-primary .alert-link {
  color: #001522;
}

.alert-secondary {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2;
}

.alert-secondary hr {
  border-top-color: #d5d5d5;
}

.alert-secondary .alert-link {
  color: #373636;
}

.alert-success {
  color: #435925;
  background-color: #e6eeda;
  border-color: #dce8cc;
}

.alert-success hr {
  border-top-color: #d0e0ba;
}

.alert-success .alert-link {
  color: #283516;
}

.alert-info {
  color: #366a69;
  background-color: #e1f5f4;
  border-color: #d4f0f0;
}

.alert-info hr {
  border-top-color: #c1e9e9;
}

.alert-info .alert-link {
  color: #254848;
}

.alert-warning {
  color: #7d5a29;
  background-color: #fcefdc;
  border-color: #fbe8cd;
}

.alert-warning hr {
  border-top-color: #f9ddb5;
}

.alert-warning .alert-link {
  color: #573e1c;
}

.alert-danger {
  color: #712b29;
  background-color: #f7dddc;
  border-color: #f4cfce;
}

.alert-danger hr {
  border-top-color: #efbbb9;
}

.alert-danger .alert-link {
  color: #4c1d1b;
}

.alert-light {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd;
}

.alert-light hr {
  border-top-color: #f0f0f0;
}

.alert-light .alert-link {
  color: #686767;
}

.alert-dark {
  color: #2a2a2a;
  background-color: gainsboro;
  border-color: #cecece;
}

.alert-dark hr {
  border-top-color: #c1c1c1;
}

.alert-dark .alert-link {
  color: #111010;
}

.alert-primary-dark {
  color: #141e2b;
  background-color: #d4d7dd;
  border-color: #c3c8cf;
}

.alert-primary-dark hr {
  border-top-color: #b5bbc4;
}

.alert-primary-dark .alert-link {
  color: #040608;
}

.alert-mid {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2;
}

.alert-mid hr {
  border-top-color: #d5d5d5;
}

.alert-mid .alert-link {
  color: #373636;
}

.alert-mid-light {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd;
}

.alert-mid-light hr {
  border-top-color: #f0f0f0;
}

.alert-mid-light .alert-link {
  color: #686767;
}

.alert-accent {
  color: #435925;
  background-color: #e6eeda;
  border-color: #dce8cc;
}

.alert-accent hr {
  border-top-color: #d0e0ba;
}

.alert-accent .alert-link {
  color: #283516;
}

.alert-accent-light {
  color: #5e6d4b;
  background-color: #f0f6e9;
  border-color: #eaf2e0;
}

.alert-accent-light hr {
  border-top-color: #deeace;
}

.alert-accent-light .alert-link {
  color: #444f36;
}

.alert-accent-extra-light {
  color: #74796e;
  background-color: #f9faf6;
  border-color: #f6f9f3;
}

.alert-accent-extra-light hr {
  border-top-color: #e9f1e2;
}

.alert-accent-extra-light .alert-link {
  color: #5a5e56;
}

.alert-aha {
  color: #001946;
  background-color: #ccd6e7;
  border-color: #b8c5dd;
}

.alert-aha hr {
  border-top-color: #a7b7d5;
}

.alert-aha .alert-link {
  color: #000713;
}

.alert-aha-blue {
  color: #60717a;
  background-color: #f1f7fb;
  border-color: #ebf4f9;
}

.alert-aha-blue hr {
  border-top-color: #d7e9f3;
}

.alert-aha-blue .alert-link {
  color: #4a575d;
}

.alert-black {
  color: black;
  background-color: #cccccc;
  border-color: #b8b8b8;
}

.alert-black hr {
  border-top-color: #ababab;
}

.alert-black .alert-link {
  color: black;
}

.alert-blue-dark {
  color: #003555;
  background-color: #cce0ed;
  border-color: #b8d4e5;
}

.alert-blue-dark hr {
  border-top-color: #a5c9de;
}

.alert-blue-dark .alert-link {
  color: #001522;
}

.alert-blue-light {
  color: #003e63;
  background-color: #cce4f2;
  border-color: #b8d9ed;
}

.alert-blue-light hr {
  border-top-color: #a4cee8;
}

.alert-blue-light .alert-link {
  color: #001e30;
}

.alert-blue-sky {
  color: #1f5778;
  background-color: #d8edfa;
  border-color: #c8e6f8;
}

.alert-blue-sky hr {
  border-top-color: #b1dcf5;
}

.alert-blue-sky .alert-link {
  color: #153a4f;
}

.alert-blue-pale {
  color: #2d5872;
  background-color: #ddeef8;
  border-color: #d0e7f5;
}

.alert-blue-pale hr {
  border-top-color: #bbdcf1;
}

.alert-blue-pale .alert-link {
  color: #1f3c4d;
}

.alert-blue-pulse {
  color: #003d62;
  background-color: #cce4f2;
  border-color: #b8d9ec;
}

.alert-blue-pulse hr {
  border-top-color: #a4cee7;
}

.alert-blue-pulse .alert-link {
  color: #001d2f;
}

.alert-blue-extra-super-light {
  color: #777e7e;
  background-color: #fafcfc;
  border-color: #f8fbfb;
}

.alert-blue-extra-super-light hr {
  border-top-color: #e8f2f2;
}

.alert-blue-extra-super-light .alert-link {
  color: #5e6464;
}

.alert-blue-extra-light {
  color: #676d75;
  background-color: #f4f6f9;
  border-color: #eff2f7;
}

.alert-blue-extra-light hr {
  border-top-color: #dee4ef;
}

.alert-blue-extra-light .alert-link {
  color: #4f545a;
}

.alert-blue-super-light {
  color: #7e8082;
  background-color: #fcfdfe;
  border-color: #fbfcfe;
}

.alert-blue-super-light hr {
  border-top-color: #e7edf9;
}

.alert-blue-super-light .alert-link {
  color: #656768;
}

.alert-covid-blue {
  color: #235572;
  background-color: #daedf8;
  border-color: #cbe6f5;
}

.alert-covid-blue hr {
  border-top-color: #b6dcf1;
}

.alert-covid-blue .alert-link {
  color: #17384b;
}

.alert-gray-dark {
  color: #2a2a2a;
  background-color: gainsboro;
  border-color: #cecece;
}

.alert-gray-dark hr {
  border-top-color: #c1c1c1;
}

.alert-gray-dark .alert-link {
  color: #111010;
}

.alert-gray-light {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2;
}

.alert-gray-light hr {
  border-top-color: #d5d5d5;
}

.alert-gray-light .alert-link {
  color: #373636;
}

.alert-gray-100 {
  color: #818181;
  background-color: #fefefe;
  border-color: #fdfdfd;
}

.alert-gray-100 hr {
  border-top-color: #f0f0f0;
}

.alert-gray-100 .alert-link {
  color: #686767;
}

.alert-gray-150 {
  color: #7d7d7d;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}

.alert-gray-150 hr {
  border-top-color: #eeeeee;
}

.alert-gray-150 .alert-link {
  color: #646363;
}

.alert-gray-175 {
  color: #7c7c7c;
  background-color: #fcfcfc;
  border-color: #fbfbfb;
}

.alert-gray-175 hr {
  border-top-color: #eeeeee;
}

.alert-gray-175 .alert-link {
  color: #636262;
}

.alert-gray-200 {
  color: #777777;
  background-color: #fafafa;
  border-color: #f8f8f8;
}

.alert-gray-200 hr {
  border-top-color: #ebebeb;
}

.alert-gray-200 .alert-link {
  color: #5e5d5d;
}

.alert-gray-300 {
  color: #737373;
  background-color: #f8f8f8;
  border-color: whitesmoke;
}

.alert-gray-300 hr {
  border-top-color: #e8e8e8;
}

.alert-gray-300 .alert-link {
  color: #5a5959;
}

.alert-gray-400 {
  color: #6a6a6a;
  background-color: whitesmoke;
  border-color: #f1f1f1;
}

.alert-gray-400 hr {
  border-top-color: #e4e4e4;
}

.alert-gray-400 .alert-link {
  color: #515050;
}

.alert-gray-500 {
  color: #595959;
  background-color: #eeeeee;
  border-color: #e7e7e7;
}

.alert-gray-500 hr {
  border-top-color: #dadada;
}

.alert-gray-500 .alert-link {
  color: #403f3f;
}

.alert-gray-600 {
  color: #505050;
  background-color: #ebebeb;
  border-color: #e2e2e2;
}

.alert-gray-600 hr {
  border-top-color: #d5d5d5;
}

.alert-gray-600 .alert-link {
  color: #373636;
}

.alert-gray-700 {
  color: #3a3a3a;
  background-color: #e2e2e2;
  border-color: #d7d7d7;
}

.alert-gray-700 hr {
  border-top-color: #cacaca;
}

.alert-gray-700 .alert-link {
  color: #212020;
}

.alert-gray-800 {
  color: #2a2a2a;
  background-color: gainsboro;
  border-color: #cecece;
}

.alert-gray-800 hr {
  border-top-color: #c1c1c1;
}

.alert-gray-800 .alert-link {
  color: #111010;
}

.alert-gray-900 {
  color: #191919;
  background-color: #d6d6d6;
  border-color: #c5c5c5;
}

.alert-gray-900 hr {
  border-top-color: #b8b8b8;
}

.alert-gray-900 .alert-link {
  color: black;
}

.alert-green-dark {
  color: #435925;
  background-color: #e6eeda;
  border-color: #dce8cc;
}

.alert-green-dark hr {
  border-top-color: #d0e0ba;
}

.alert-green-dark .alert-link {
  color: #283516;
}

.alert-green-lime {
  color: #3a4e20;
  background-color: #e2ead8;
  border-color: #d7e2c9;
}

.alert-green-lime hr {
  border-top-color: #cbd9b8;
}

.alert-green-lime .alert-link {
  color: #1f2a11;
}

.alert-green-light {
  color: #5e6d4b;
  background-color: #f0f6e9;
  border-color: #eaf2e0;
}

.alert-green-light hr {
  border-top-color: #deeace;
}

.alert-green-light .alert-link {
  color: #444f36;
}

.alert-green-extra-light {
  color: #74796e;
  background-color: #f9faf6;
  border-color: #f6f9f3;
}

.alert-green-extra-light hr {
  border-top-color: #e9f1e2;
}

.alert-green-extra-light .alert-link {
  color: #5a5e56;
}

.alert-highlight-green {
  color: #767a70;
  background-color: #f9fbf7;
  border-color: #f7f9f4;
}

.alert-highlight-green hr {
  border-top-color: #ebf0e4;
}

.alert-highlight-green .alert-link {
  color: #5c5f58;
}

.alert-highlight-teal {
  color: #7a8085;
  background-color: #fbfdff;
  border-color: #f9fdff;
}

.alert-highlight-teal hr {
  border-top-color: #e0f5ff;
}

.alert-highlight-teal .alert-link {
  color: #62666a;
}

.alert-orange-dark {
  color: #7f441d;
  background-color: #fde6d7;
  border-color: #fcdcc7;
}

.alert-orange-dark hr {
  border-top-color: #fbcdaf;
}

.alert-orange-dark .alert-link {
  color: #552e14;
}

.alert-orange-gold {
  color: #855200;
  background-color: #ffeccc;
  border-color: #ffe4b8;
}

.alert-orange-gold hr {
  border-top-color: #ffda9f;
}

.alert-orange-gold .alert-link {
  color: #523300;
}

.alert-orange-light {
  color: #834d2b;
  background-color: #feeadc;
  border-color: #fee1cf;
}

.alert-orange-light hr {
  border-top-color: #fdd1b6;
}

.alert-orange-light .alert-link {
  color: #5d361e;
}

.alert-orange-extra-light {
  color: #837a6b;
  background-color: #fefbf5;
  border-color: #fef9f1;
}

.alert-orange-extra-light hr {
  border-top-color: #fcefd9;
}

.alert-orange-extra-light .alert-link {
  color: #676054;
}

.alert-primary-light {
  color: #7e8082;
  background-color: #fcfdfe;
  border-color: #fbfcfe;
}

.alert-primary-light hr {
  border-top-color: #e7edf9;
}

.alert-primary-light .alert-link {
  color: #656768;
}

.alert-primary-mid {
  color: #003e63;
  background-color: #cce4f2;
  border-color: #b8d9ed;
}

.alert-primary-mid hr {
  border-top-color: #a4cee8;
}

.alert-primary-mid .alert-link {
  color: #001e30;
}

.alert-primary-mid-light {
  color: #5b677b;
  background-color: #eff4fb;
  border-color: #e9effa;
}

.alert-primary-mid-light hr {
  border-top-color: #d4e0f5;
}

.alert-primary-mid-light .alert-link {
  color: #454e5e;
}

.alert-pulse-blue {
  color: #013d62;
  background-color: #cce4f2;
  border-color: #b8d9ed;
}

.alert-pulse-blue hr {
  border-top-color: #a4cee8;
}

.alert-pulse-blue .alert-link {
  color: #001e30;
}

.alert-pulse-benchmark {
  color: #3a4e20;
  background-color: #e2ead8;
  border-color: #d7e2c9;
}

.alert-pulse-benchmark hr {
  border-top-color: #cbd9b8;
}

.alert-pulse-benchmark .alert-link {
  color: #1f2a11;
}

.alert-red-dark {
  color: #4c1816;
  background-color: #e9d5d5;
  border-color: #e0c4c4;
}

.alert-red-dark hr {
  border-top-color: #d7b3b3;
}

.alert-red-dark .alert-link {
  color: #240c0b;
}

.alert-red-light {
  color: #581f1e;
  background-color: #eed8d7;
  border-color: #e7c8c8;
}

.alert-red-light hr {
  border-top-color: #dfb6b6;
}

.alert-red-light .alert-link {
  color: #321211;
}

.alert-red-lighter {
  color: #712b2a;
  background-color: #f7dddc;
  border-color: #f4cfce;
}

.alert-red-lighter hr {
  border-top-color: #efbbb9;
}

.alert-red-lighter .alert-link {
  color: #4c1d1c;
}

.alert-red-extra-light {
  color: #796b6b;
  background-color: #fbf5f5;
  border-color: #f9f1f1;
}

.alert-red-extra-light hr {
  border-top-color: #f1dfdf;
}

.alert-red-extra-light .alert-link {
  color: #5e5353;
}

.alert-teal-dark {
  color: #235e5d;
  background-color: #d9f0f0;
  border-color: #caeae9;
}

.alert-teal-dark hr {
  border-top-color: #b8e3e1;
}

.alert-teal-dark .alert-link {
  color: #153938;
}

.alert-teal-light {
  color: #366a69;
  background-color: #e1f5f4;
  border-color: #d4f0f0;
}

.alert-teal-light hr {
  border-top-color: #c1e9e9;
}

.alert-teal-light .alert-link {
  color: #254848;
}

.alert-teal-extra-light {
  color: #637776;
  background-color: #f2faf9;
  border-color: #edf7f7;
}

.alert-teal-extra-light hr {
  border-top-color: #dbefef;
}

.alert-teal-extra-light .alert-link {
  color: #4c5b5a;
}

.alert-violet-dark {
  color: #4d2c46;
  background-color: #eadde7;
  border-color: #e1cfdd;
}

.alert-violet-dark hr {
  border-top-color: #d7bfd2;
}

.alert-violet-dark .alert-link {
  color: #2d1928;
}

.alert-violet-light {
  color: #592f51;
  background-color: #eedeeb;
  border-color: #e7d1e3;
}

.alert-violet-light hr {
  border-top-color: #dec0d9;
}

.alert-violet-light .alert-link {
  color: #381d33;
}

.alert-violet-extra-light {
  color: #776b74;
  background-color: #faf5f9;
  border-color: #f8f1f6;
}

.alert-violet-extra-light hr {
  border-top-color: #f0e0eb;
}

.alert-violet-extra-light .alert-link {
  color: #5c535a;
}

.alert-white {
  color: #858585;
  background-color: white;
  border-color: white;
}

.alert-white hr {
  border-top-color: #f2f2f2;
}

.alert-white .alert-link {
  color: #6c6b6b;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.65625rem;
  background-color: #e5e5e5;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0066A3;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #707070;
  text-align: inherit;
}

.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #707070;
  text-decoration: none;
  background-color: #f8f8f8;
}

.list-group-item-action:active {
  color: #303030;
  background-color: #e5e5e5;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #999999;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0066A3;
  border-color: #0066A3;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #003555;
  background-color: #b8d4e5;
}

.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #003555;
  background-color: #a5c9de;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #003555;
  border-color: #003555;
}

.list-group-item-secondary {
  color: #505050;
  background-color: #e2e2e2;
}

.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-success {
  color: #435925;
  background-color: #dce8cc;
}

.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #435925;
  background-color: #d0e0ba;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #435925;
  border-color: #435925;
}

.list-group-item-info {
  color: #366a69;
  background-color: #d4f0f0;
}

.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #366a69;
  background-color: #c1e9e9;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #366a69;
  border-color: #366a69;
}

.list-group-item-warning {
  color: #7d5a29;
  background-color: #fbe8cd;
}

.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #7d5a29;
  background-color: #f9ddb5;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7d5a29;
  border-color: #7d5a29;
}

.list-group-item-danger {
  color: #712b29;
  background-color: #f4cfce;
}

.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #712b29;
  background-color: #efbbb9;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #712b29;
  border-color: #712b29;
}

.list-group-item-light {
  color: #818181;
  background-color: #fdfdfd;
}

.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818181;
  background-color: #f0f0f0;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
}

.list-group-item-dark {
  color: #2a2a2a;
  background-color: #cecece;
}

.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #2a2a2a;
  background-color: #c1c1c1;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

.list-group-item-primary-dark {
  color: #141e2b;
  background-color: #c3c8cf;
}

.list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
  color: #141e2b;
  background-color: #b5bbc4;
}

.list-group-item-primary-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141e2b;
  border-color: #141e2b;
}

.list-group-item-mid {
  color: #505050;
  background-color: #e2e2e2;
}

.list-group-item-mid.list-group-item-action:hover, .list-group-item-mid.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}

.list-group-item-mid.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-mid-light {
  color: #818181;
  background-color: #fdfdfd;
}

.list-group-item-mid-light.list-group-item-action:hover, .list-group-item-mid-light.list-group-item-action:focus {
  color: #818181;
  background-color: #f0f0f0;
}

.list-group-item-mid-light.list-group-item-action.active {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
}

.list-group-item-accent {
  color: #435925;
  background-color: #dce8cc;
}

.list-group-item-accent.list-group-item-action:hover, .list-group-item-accent.list-group-item-action:focus {
  color: #435925;
  background-color: #d0e0ba;
}

.list-group-item-accent.list-group-item-action.active {
  color: #fff;
  background-color: #435925;
  border-color: #435925;
}

.list-group-item-accent-light {
  color: #5e6d4b;
  background-color: #eaf2e0;
}

.list-group-item-accent-light.list-group-item-action:hover, .list-group-item-accent-light.list-group-item-action:focus {
  color: #5e6d4b;
  background-color: #deeace;
}

.list-group-item-accent-light.list-group-item-action.active {
  color: #fff;
  background-color: #5e6d4b;
  border-color: #5e6d4b;
}

.list-group-item-accent-extra-light {
  color: #74796e;
  background-color: #f6f9f3;
}

.list-group-item-accent-extra-light.list-group-item-action:hover, .list-group-item-accent-extra-light.list-group-item-action:focus {
  color: #74796e;
  background-color: #e9f1e2;
}

.list-group-item-accent-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #74796e;
  border-color: #74796e;
}

.list-group-item-aha {
  color: #001946;
  background-color: #b8c5dd;
}

.list-group-item-aha.list-group-item-action:hover, .list-group-item-aha.list-group-item-action:focus {
  color: #001946;
  background-color: #a7b7d5;
}

.list-group-item-aha.list-group-item-action.active {
  color: #fff;
  background-color: #001946;
  border-color: #001946;
}

.list-group-item-aha-blue {
  color: #60717a;
  background-color: #ebf4f9;
}

.list-group-item-aha-blue.list-group-item-action:hover, .list-group-item-aha-blue.list-group-item-action:focus {
  color: #60717a;
  background-color: #d7e9f3;
}

.list-group-item-aha-blue.list-group-item-action.active {
  color: #fff;
  background-color: #60717a;
  border-color: #60717a;
}

.list-group-item-black {
  color: black;
  background-color: #b8b8b8;
}

.list-group-item-black.list-group-item-action:hover, .list-group-item-black.list-group-item-action:focus {
  color: black;
  background-color: #ababab;
}

.list-group-item-black.list-group-item-action.active {
  color: #fff;
  background-color: black;
  border-color: black;
}

.list-group-item-blue-dark {
  color: #003555;
  background-color: #b8d4e5;
}

.list-group-item-blue-dark.list-group-item-action:hover, .list-group-item-blue-dark.list-group-item-action:focus {
  color: #003555;
  background-color: #a5c9de;
}

.list-group-item-blue-dark.list-group-item-action.active {
  color: #fff;
  background-color: #003555;
  border-color: #003555;
}

.list-group-item-blue-light {
  color: #003e63;
  background-color: #b8d9ed;
}

.list-group-item-blue-light.list-group-item-action:hover, .list-group-item-blue-light.list-group-item-action:focus {
  color: #003e63;
  background-color: #a4cee8;
}

.list-group-item-blue-light.list-group-item-action.active {
  color: #fff;
  background-color: #003e63;
  border-color: #003e63;
}

.list-group-item-blue-sky {
  color: #1f5778;
  background-color: #c8e6f8;
}

.list-group-item-blue-sky.list-group-item-action:hover, .list-group-item-blue-sky.list-group-item-action:focus {
  color: #1f5778;
  background-color: #b1dcf5;
}

.list-group-item-blue-sky.list-group-item-action.active {
  color: #fff;
  background-color: #1f5778;
  border-color: #1f5778;
}

.list-group-item-blue-pale {
  color: #2d5872;
  background-color: #d0e7f5;
}

.list-group-item-blue-pale.list-group-item-action:hover, .list-group-item-blue-pale.list-group-item-action:focus {
  color: #2d5872;
  background-color: #bbdcf1;
}

.list-group-item-blue-pale.list-group-item-action.active {
  color: #fff;
  background-color: #2d5872;
  border-color: #2d5872;
}

.list-group-item-blue-pulse {
  color: #003d62;
  background-color: #b8d9ec;
}

.list-group-item-blue-pulse.list-group-item-action:hover, .list-group-item-blue-pulse.list-group-item-action:focus {
  color: #003d62;
  background-color: #a4cee7;
}

.list-group-item-blue-pulse.list-group-item-action.active {
  color: #fff;
  background-color: #003d62;
  border-color: #003d62;
}

.list-group-item-blue-extra-super-light {
  color: #777e7e;
  background-color: #f8fbfb;
}

.list-group-item-blue-extra-super-light.list-group-item-action:hover, .list-group-item-blue-extra-super-light.list-group-item-action:focus {
  color: #777e7e;
  background-color: #e8f2f2;
}

.list-group-item-blue-extra-super-light.list-group-item-action.active {
  color: #fff;
  background-color: #777e7e;
  border-color: #777e7e;
}

.list-group-item-blue-extra-light {
  color: #676d75;
  background-color: #eff2f7;
}

.list-group-item-blue-extra-light.list-group-item-action:hover, .list-group-item-blue-extra-light.list-group-item-action:focus {
  color: #676d75;
  background-color: #dee4ef;
}

.list-group-item-blue-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #676d75;
  border-color: #676d75;
}

.list-group-item-blue-super-light {
  color: #7e8082;
  background-color: #fbfcfe;
}

.list-group-item-blue-super-light.list-group-item-action:hover, .list-group-item-blue-super-light.list-group-item-action:focus {
  color: #7e8082;
  background-color: #e7edf9;
}

.list-group-item-blue-super-light.list-group-item-action.active {
  color: #fff;
  background-color: #7e8082;
  border-color: #7e8082;
}

.list-group-item-covid-blue {
  color: #235572;
  background-color: #cbe6f5;
}

.list-group-item-covid-blue.list-group-item-action:hover, .list-group-item-covid-blue.list-group-item-action:focus {
  color: #235572;
  background-color: #b6dcf1;
}

.list-group-item-covid-blue.list-group-item-action.active {
  color: #fff;
  background-color: #235572;
  border-color: #235572;
}

.list-group-item-gray-dark {
  color: #2a2a2a;
  background-color: #cecece;
}

.list-group-item-gray-dark.list-group-item-action:hover, .list-group-item-gray-dark.list-group-item-action:focus {
  color: #2a2a2a;
  background-color: #c1c1c1;
}

.list-group-item-gray-dark.list-group-item-action.active {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

.list-group-item-gray-light {
  color: #505050;
  background-color: #e2e2e2;
}

.list-group-item-gray-light.list-group-item-action:hover, .list-group-item-gray-light.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}

.list-group-item-gray-light.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-gray-100 {
  color: #818181;
  background-color: #fdfdfd;
}

.list-group-item-gray-100.list-group-item-action:hover, .list-group-item-gray-100.list-group-item-action:focus {
  color: #818181;
  background-color: #f0f0f0;
}

.list-group-item-gray-100.list-group-item-action.active {
  color: #fff;
  background-color: #818181;
  border-color: #818181;
}

.list-group-item-gray-150 {
  color: #7d7d7d;
  background-color: #fbfbfb;
}

.list-group-item-gray-150.list-group-item-action:hover, .list-group-item-gray-150.list-group-item-action:focus {
  color: #7d7d7d;
  background-color: #eeeeee;
}

.list-group-item-gray-150.list-group-item-action.active {
  color: #fff;
  background-color: #7d7d7d;
  border-color: #7d7d7d;
}

.list-group-item-gray-175 {
  color: #7c7c7c;
  background-color: #fbfbfb;
}

.list-group-item-gray-175.list-group-item-action:hover, .list-group-item-gray-175.list-group-item-action:focus {
  color: #7c7c7c;
  background-color: #eeeeee;
}

.list-group-item-gray-175.list-group-item-action.active {
  color: #fff;
  background-color: #7c7c7c;
  border-color: #7c7c7c;
}

.list-group-item-gray-200 {
  color: #777777;
  background-color: #f8f8f8;
}

.list-group-item-gray-200.list-group-item-action:hover, .list-group-item-gray-200.list-group-item-action:focus {
  color: #777777;
  background-color: #ebebeb;
}

.list-group-item-gray-200.list-group-item-action.active {
  color: #fff;
  background-color: #777777;
  border-color: #777777;
}

.list-group-item-gray-300 {
  color: #737373;
  background-color: whitesmoke;
}

.list-group-item-gray-300.list-group-item-action:hover, .list-group-item-gray-300.list-group-item-action:focus {
  color: #737373;
  background-color: #e8e8e8;
}

.list-group-item-gray-300.list-group-item-action.active {
  color: #fff;
  background-color: #737373;
  border-color: #737373;
}

.list-group-item-gray-400 {
  color: #6a6a6a;
  background-color: #f1f1f1;
}

.list-group-item-gray-400.list-group-item-action:hover, .list-group-item-gray-400.list-group-item-action:focus {
  color: #6a6a6a;
  background-color: #e4e4e4;
}

.list-group-item-gray-400.list-group-item-action.active {
  color: #fff;
  background-color: #6a6a6a;
  border-color: #6a6a6a;
}

.list-group-item-gray-500 {
  color: #595959;
  background-color: #e7e7e7;
}

.list-group-item-gray-500.list-group-item-action:hover, .list-group-item-gray-500.list-group-item-action:focus {
  color: #595959;
  background-color: #dadada;
}

.list-group-item-gray-500.list-group-item-action.active {
  color: #fff;
  background-color: #595959;
  border-color: #595959;
}

.list-group-item-gray-600 {
  color: #505050;
  background-color: #e2e2e2;
}

.list-group-item-gray-600.list-group-item-action:hover, .list-group-item-gray-600.list-group-item-action:focus {
  color: #505050;
  background-color: #d5d5d5;
}

.list-group-item-gray-600.list-group-item-action.active {
  color: #fff;
  background-color: #505050;
  border-color: #505050;
}

.list-group-item-gray-700 {
  color: #3a3a3a;
  background-color: #d7d7d7;
}

.list-group-item-gray-700.list-group-item-action:hover, .list-group-item-gray-700.list-group-item-action:focus {
  color: #3a3a3a;
  background-color: #cacaca;
}

.list-group-item-gray-700.list-group-item-action.active {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #3a3a3a;
}

.list-group-item-gray-800 {
  color: #2a2a2a;
  background-color: #cecece;
}

.list-group-item-gray-800.list-group-item-action:hover, .list-group-item-gray-800.list-group-item-action:focus {
  color: #2a2a2a;
  background-color: #c1c1c1;
}

.list-group-item-gray-800.list-group-item-action.active {
  color: #fff;
  background-color: #2a2a2a;
  border-color: #2a2a2a;
}

.list-group-item-gray-900 {
  color: #191919;
  background-color: #c5c5c5;
}

.list-group-item-gray-900.list-group-item-action:hover, .list-group-item-gray-900.list-group-item-action:focus {
  color: #191919;
  background-color: #b8b8b8;
}

.list-group-item-gray-900.list-group-item-action.active {
  color: #fff;
  background-color: #191919;
  border-color: #191919;
}

.list-group-item-green-dark {
  color: #435925;
  background-color: #dce8cc;
}

.list-group-item-green-dark.list-group-item-action:hover, .list-group-item-green-dark.list-group-item-action:focus {
  color: #435925;
  background-color: #d0e0ba;
}

.list-group-item-green-dark.list-group-item-action.active {
  color: #fff;
  background-color: #435925;
  border-color: #435925;
}

.list-group-item-green-lime {
  color: #3a4e20;
  background-color: #d7e2c9;
}

.list-group-item-green-lime.list-group-item-action:hover, .list-group-item-green-lime.list-group-item-action:focus {
  color: #3a4e20;
  background-color: #cbd9b8;
}

.list-group-item-green-lime.list-group-item-action.active {
  color: #fff;
  background-color: #3a4e20;
  border-color: #3a4e20;
}

.list-group-item-green-light {
  color: #5e6d4b;
  background-color: #eaf2e0;
}

.list-group-item-green-light.list-group-item-action:hover, .list-group-item-green-light.list-group-item-action:focus {
  color: #5e6d4b;
  background-color: #deeace;
}

.list-group-item-green-light.list-group-item-action.active {
  color: #fff;
  background-color: #5e6d4b;
  border-color: #5e6d4b;
}

.list-group-item-green-extra-light {
  color: #74796e;
  background-color: #f6f9f3;
}

.list-group-item-green-extra-light.list-group-item-action:hover, .list-group-item-green-extra-light.list-group-item-action:focus {
  color: #74796e;
  background-color: #e9f1e2;
}

.list-group-item-green-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #74796e;
  border-color: #74796e;
}

.list-group-item-highlight-green {
  color: #767a70;
  background-color: #f7f9f4;
}

.list-group-item-highlight-green.list-group-item-action:hover, .list-group-item-highlight-green.list-group-item-action:focus {
  color: #767a70;
  background-color: #ebf0e4;
}

.list-group-item-highlight-green.list-group-item-action.active {
  color: #fff;
  background-color: #767a70;
  border-color: #767a70;
}

.list-group-item-highlight-teal {
  color: #7a8085;
  background-color: #f9fdff;
}

.list-group-item-highlight-teal.list-group-item-action:hover, .list-group-item-highlight-teal.list-group-item-action:focus {
  color: #7a8085;
  background-color: #e0f5ff;
}

.list-group-item-highlight-teal.list-group-item-action.active {
  color: #fff;
  background-color: #7a8085;
  border-color: #7a8085;
}

.list-group-item-orange-dark {
  color: #7f441d;
  background-color: #fcdcc7;
}

.list-group-item-orange-dark.list-group-item-action:hover, .list-group-item-orange-dark.list-group-item-action:focus {
  color: #7f441d;
  background-color: #fbcdaf;
}

.list-group-item-orange-dark.list-group-item-action.active {
  color: #fff;
  background-color: #7f441d;
  border-color: #7f441d;
}

.list-group-item-orange-gold {
  color: #855200;
  background-color: #ffe4b8;
}

.list-group-item-orange-gold.list-group-item-action:hover, .list-group-item-orange-gold.list-group-item-action:focus {
  color: #855200;
  background-color: #ffda9f;
}

.list-group-item-orange-gold.list-group-item-action.active {
  color: #fff;
  background-color: #855200;
  border-color: #855200;
}

.list-group-item-orange-light {
  color: #834d2b;
  background-color: #fee1cf;
}

.list-group-item-orange-light.list-group-item-action:hover, .list-group-item-orange-light.list-group-item-action:focus {
  color: #834d2b;
  background-color: #fdd1b6;
}

.list-group-item-orange-light.list-group-item-action.active {
  color: #fff;
  background-color: #834d2b;
  border-color: #834d2b;
}

.list-group-item-orange-extra-light {
  color: #837a6b;
  background-color: #fef9f1;
}

.list-group-item-orange-extra-light.list-group-item-action:hover, .list-group-item-orange-extra-light.list-group-item-action:focus {
  color: #837a6b;
  background-color: #fcefd9;
}

.list-group-item-orange-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #837a6b;
  border-color: #837a6b;
}

.list-group-item-primary-light {
  color: #7e8082;
  background-color: #fbfcfe;
}

.list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
  color: #7e8082;
  background-color: #e7edf9;
}

.list-group-item-primary-light.list-group-item-action.active {
  color: #fff;
  background-color: #7e8082;
  border-color: #7e8082;
}

.list-group-item-primary-mid {
  color: #003e63;
  background-color: #b8d9ed;
}

.list-group-item-primary-mid.list-group-item-action:hover, .list-group-item-primary-mid.list-group-item-action:focus {
  color: #003e63;
  background-color: #a4cee8;
}

.list-group-item-primary-mid.list-group-item-action.active {
  color: #fff;
  background-color: #003e63;
  border-color: #003e63;
}

.list-group-item-primary-mid-light {
  color: #5b677b;
  background-color: #e9effa;
}

.list-group-item-primary-mid-light.list-group-item-action:hover, .list-group-item-primary-mid-light.list-group-item-action:focus {
  color: #5b677b;
  background-color: #d4e0f5;
}

.list-group-item-primary-mid-light.list-group-item-action.active {
  color: #fff;
  background-color: #5b677b;
  border-color: #5b677b;
}

.list-group-item-pulse-blue {
  color: #013d62;
  background-color: #b8d9ed;
}

.list-group-item-pulse-blue.list-group-item-action:hover, .list-group-item-pulse-blue.list-group-item-action:focus {
  color: #013d62;
  background-color: #a4cee8;
}

.list-group-item-pulse-blue.list-group-item-action.active {
  color: #fff;
  background-color: #013d62;
  border-color: #013d62;
}

.list-group-item-pulse-benchmark {
  color: #3a4e20;
  background-color: #d7e2c9;
}

.list-group-item-pulse-benchmark.list-group-item-action:hover, .list-group-item-pulse-benchmark.list-group-item-action:focus {
  color: #3a4e20;
  background-color: #cbd9b8;
}

.list-group-item-pulse-benchmark.list-group-item-action.active {
  color: #fff;
  background-color: #3a4e20;
  border-color: #3a4e20;
}

.list-group-item-red-dark {
  color: #4c1816;
  background-color: #e0c4c4;
}

.list-group-item-red-dark.list-group-item-action:hover, .list-group-item-red-dark.list-group-item-action:focus {
  color: #4c1816;
  background-color: #d7b3b3;
}

.list-group-item-red-dark.list-group-item-action.active {
  color: #fff;
  background-color: #4c1816;
  border-color: #4c1816;
}

.list-group-item-red-light {
  color: #581f1e;
  background-color: #e7c8c8;
}

.list-group-item-red-light.list-group-item-action:hover, .list-group-item-red-light.list-group-item-action:focus {
  color: #581f1e;
  background-color: #dfb6b6;
}

.list-group-item-red-light.list-group-item-action.active {
  color: #fff;
  background-color: #581f1e;
  border-color: #581f1e;
}

.list-group-item-red-lighter {
  color: #712b2a;
  background-color: #f4cfce;
}

.list-group-item-red-lighter.list-group-item-action:hover, .list-group-item-red-lighter.list-group-item-action:focus {
  color: #712b2a;
  background-color: #efbbb9;
}

.list-group-item-red-lighter.list-group-item-action.active {
  color: #fff;
  background-color: #712b2a;
  border-color: #712b2a;
}

.list-group-item-red-extra-light {
  color: #796b6b;
  background-color: #f9f1f1;
}

.list-group-item-red-extra-light.list-group-item-action:hover, .list-group-item-red-extra-light.list-group-item-action:focus {
  color: #796b6b;
  background-color: #f1dfdf;
}

.list-group-item-red-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #796b6b;
  border-color: #796b6b;
}

.list-group-item-teal-dark {
  color: #235e5d;
  background-color: #caeae9;
}

.list-group-item-teal-dark.list-group-item-action:hover, .list-group-item-teal-dark.list-group-item-action:focus {
  color: #235e5d;
  background-color: #b8e3e1;
}

.list-group-item-teal-dark.list-group-item-action.active {
  color: #fff;
  background-color: #235e5d;
  border-color: #235e5d;
}

.list-group-item-teal-light {
  color: #366a69;
  background-color: #d4f0f0;
}

.list-group-item-teal-light.list-group-item-action:hover, .list-group-item-teal-light.list-group-item-action:focus {
  color: #366a69;
  background-color: #c1e9e9;
}

.list-group-item-teal-light.list-group-item-action.active {
  color: #fff;
  background-color: #366a69;
  border-color: #366a69;
}

.list-group-item-teal-extra-light {
  color: #637776;
  background-color: #edf7f7;
}

.list-group-item-teal-extra-light.list-group-item-action:hover, .list-group-item-teal-extra-light.list-group-item-action:focus {
  color: #637776;
  background-color: #dbefef;
}

.list-group-item-teal-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #637776;
  border-color: #637776;
}

.list-group-item-violet-dark {
  color: #4d2c46;
  background-color: #e1cfdd;
}

.list-group-item-violet-dark.list-group-item-action:hover, .list-group-item-violet-dark.list-group-item-action:focus {
  color: #4d2c46;
  background-color: #d7bfd2;
}

.list-group-item-violet-dark.list-group-item-action.active {
  color: #fff;
  background-color: #4d2c46;
  border-color: #4d2c46;
}

.list-group-item-violet-light {
  color: #592f51;
  background-color: #e7d1e3;
}

.list-group-item-violet-light.list-group-item-action:hover, .list-group-item-violet-light.list-group-item-action:focus {
  color: #592f51;
  background-color: #dec0d9;
}

.list-group-item-violet-light.list-group-item-action.active {
  color: #fff;
  background-color: #592f51;
  border-color: #592f51;
}

.list-group-item-violet-extra-light {
  color: #776b74;
  background-color: #f8f1f6;
}

.list-group-item-violet-extra-light.list-group-item-action:hover, .list-group-item-violet-extra-light.list-group-item-action:focus {
  color: #776b74;
  background-color: #f0e0eb;
}

.list-group-item-violet-extra-light.list-group-item-action.active {
  color: #fff;
  background-color: #776b74;
  border-color: #776b74;
}

.list-group-item-white {
  color: #858585;
  background-color: white;
}

.list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
  color: #858585;
  background-color: #f2f2f2;
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: #858585;
  border-color: #858585;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 500;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: .75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #999999;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid transparent;
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 0 solid #dddddd;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 0 solid #dddddd;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0;
}

.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #505050;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #303030;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10%;
  color: #505050;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #505050;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50% / 100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23505050' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23505050' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 10%;
  margin-left: 10%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: .75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    animation-duration: 1.5s;
  }
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #0066A3 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #004670 !important;
}

.bg-secondary {
  background-color: #999999 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #807f7f !important;
}

.bg-success {
  background-color: #81AC48 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #668839 !important;
}

.bg-info {
  background-color: #67CBC9 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #41bebc !important;
}

.bg-warning {
  background-color: #F0AD4E !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ec971f !important;
}

.bg-danger {
  background-color: #D9534F !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #c9302c !important;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dfdede !important;
}

.bg-dark {
  background-color: #505050 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #373636 !important;
}

.bg-primary-dark {
  background-color: #273953 !important;
}

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #172130 !important;
}

.bg-mid {
  background-color: #999999 !important;
}

a.bg-mid:hover, a.bg-mid:focus,
button.bg-mid:hover,
button.bg-mid:focus {
  background-color: #807f7f !important;
}

.bg-mid-light {
  background-color: #f8f8f8 !important;
}

a.bg-mid-light:hover, a.bg-mid-light:focus,
button.bg-mid-light:hover,
button.bg-mid-light:focus {
  background-color: #dfdede !important;
}

.bg-accent {
  background-color: #81AC48 !important;
}

a.bg-accent:hover, a.bg-accent:focus,
button.bg-accent:hover,
button.bg-accent:focus {
  background-color: #668839 !important;
}

.bg-accent-light {
  background-color: #B5D190 !important;
}

a.bg-accent-light:hover, a.bg-accent-light:focus,
button.bg-accent-light:hover,
button.bg-accent-light:focus {
  background-color: #9dc26c !important;
}

.bg-accent-extra-light {
  background-color: #DFE8D4 !important;
}

a.bg-accent-extra-light:hover, a.bg-accent-extra-light:focus,
button.bg-accent-extra-light:hover,
button.bg-accent-extra-light:focus {
  background-color: #c6d6b3 !important;
}

.bg-aha {
  background-color: #003087 !important;
}

a.bg-aha:hover, a.bg-aha:focus,
button.bg-aha:hover,
button.bg-aha:focus {
  background-color: #001e54 !important;
}

.bg-aha-blue {
  background-color: #B9D9EB !important;
}

a.bg-aha-blue:hover, a.bg-aha-blue:focus,
button.bg-aha-blue:hover,
button.bg-aha-blue:focus {
  background-color: #91c3e0 !important;
}

.bg-black {
  background-color: #000 !important;
}

a.bg-black:hover, a.bg-black:focus,
button.bg-black:hover,
button.bg-black:focus {
  background-color: black !important;
}

.bg-blue-dark {
  background-color: #0066a3 !important;
}

a.bg-blue-dark:hover, a.bg-blue-dark:focus,
button.bg-blue-dark:hover,
button.bg-blue-dark:focus {
  background-color: #004670 !important;
}

.bg-blue-light {
  background-color: #0077bf !important;
}

a.bg-blue-light:hover, a.bg-blue-light:focus,
button.bg-blue-light:hover,
button.bg-blue-light:focus {
  background-color: #00578c !important;
}

.bg-blue-sky {
  background-color: #3CA7E6 !important;
}

a.bg-blue-sky:hover, a.bg-blue-sky:focus,
button.bg-blue-sky:hover,
button.bg-blue-sky:focus {
  background-color: #1b8fd4 !important;
}

.bg-blue-pale {
  background-color: #56AADB !important;
}

a.bg-blue-pale:hover, a.bg-blue-pale:focus,
button.bg-blue-pale:hover,
button.bg-blue-pale:focus {
  background-color: #2d95d1 !important;
}

.bg-blue-pulse {
  background-color: #0076BC !important;
}

a.bg-blue-pulse:hover, a.bg-blue-pulse:focus,
button.bg-blue-pulse:hover,
button.bg-blue-pulse:focus {
  background-color: #005689 !important;
}

.bg-blue-extra-super-light {
  background-color: #E5F2F2 !important;
}

a.bg-blue-extra-super-light:hover, a.bg-blue-extra-super-light:focus,
button.bg-blue-extra-super-light:hover,
button.bg-blue-extra-super-light:focus {
  background-color: #c3e1e1 !important;
}

.bg-blue-extra-light {
  background-color: #C6D1E1 !important;
}

a.bg-blue-extra-light:hover, a.bg-blue-extra-light:focus,
button.bg-blue-extra-light:hover,
button.bg-blue-extra-light:focus {
  background-color: #a5b6cf !important;
}

.bg-blue-super-light {
  background-color: #F2F6FA !important;
}

a.bg-blue-super-light:hover, a.bg-blue-super-light:focus,
button.bg-blue-super-light:hover,
button.bg-blue-super-light:focus {
  background-color: #cdddec !important;
}

.bg-covid-blue {
  background-color: #44A4DB !important;
}

a.bg-covid-blue:hover, a.bg-covid-blue:focus,
button.bg-covid-blue:hover,
button.bg-covid-blue:focus {
  background-color: #268cc6 !important;
}

.bg-gray-dark {
  background-color: #505050 !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
  background-color: #373636 !important;
}

.bg-gray-light {
  background-color: #999999 !important;
}

a.bg-gray-light:hover, a.bg-gray-light:focus,
button.bg-gray-light:hover,
button.bg-gray-light:focus {
  background-color: #807f7f !important;
}

.bg-gray-100 {
  background-color: #f8f8f8 !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
  background-color: #dfdede !important;
}

.bg-gray-150 {
  background-color: #F0F0F1 !important;
}

a.bg-gray-150:hover, a.bg-gray-150:focus,
button.bg-gray-150:hover,
button.bg-gray-150:focus {
  background-color: #d6d6d8 !important;
}

.bg-gray-175 {
  background-color: #EFEFEF !important;
}

a.bg-gray-175:hover, a.bg-gray-175:focus,
button.bg-gray-175:hover,
button.bg-gray-175:focus {
  background-color: #d6d5d5 !important;
}

.bg-gray-200 {
  background-color: #e5e5e5 !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
  background-color: #cccbcb !important;
}

.bg-gray-300 {
  background-color: #dddddd !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
  background-color: #c4c3c3 !important;
}

.bg-gray-400 {
  background-color: #cccccc !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
  background-color: #b3b2b2 !important;
}

.bg-gray-500 {
  background-color: #ababab !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
  background-color: #929191 !important;
}

.bg-gray-600 {
  background-color: #999999 !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
  background-color: #807f7f !important;
}

.bg-gray-700 {
  background-color: #707070 !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
  background-color: #575656 !important;
}

.bg-gray-800 {
  background-color: #505050 !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
  background-color: #373636 !important;
}

.bg-gray-900 {
  background-color: #303030 !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
  background-color: #171616 !important;
}

.bg-green-dark {
  background-color: #81AC48 !important;
}

a.bg-green-dark:hover, a.bg-green-dark:focus,
button.bg-green-dark:hover,
button.bg-green-dark:focus {
  background-color: #668839 !important;
}

.bg-green-lime {
  background-color: #70963D !important;
}

a.bg-green-lime:hover, a.bg-green-lime:focus,
button.bg-green-lime:hover,
button.bg-green-lime:focus {
  background-color: #55722e !important;
}

.bg-green-light {
  background-color: #B5D190 !important;
}

a.bg-green-light:hover, a.bg-green-light:focus,
button.bg-green-light:hover,
button.bg-green-light:focus {
  background-color: #9dc26c !important;
}

.bg-green-extra-light {
  background-color: #DFE8D4 !important;
}

a.bg-green-extra-light:hover, a.bg-green-extra-light:focus,
button.bg-green-extra-light:hover,
button.bg-green-extra-light:focus {
  background-color: #c6d6b3 !important;
}

.bg-highlight-green {
  background-color: #E2EAD8 !important;
}

a.bg-highlight-green:hover, a.bg-highlight-green:focus,
button.bg-highlight-green:hover,
button.bg-highlight-green:focus {
  background-color: #c9d8b7 !important;
}

.bg-highlight-teal {
  background-color: #EAF7FF !important;
}

a.bg-highlight-teal:hover, a.bg-highlight-teal:focus,
button.bg-highlight-teal:hover,
button.bg-highlight-teal:focus {
  background-color: #b7e4ff !important;
}

.bg-orange-dark {
  background-color: #F58238 !important;
}

a.bg-orange-dark:hover, a.bg-orange-dark:focus,
button.bg-orange-dark:hover,
button.bg-orange-dark:focus {
  background-color: #ee640c !important;
}

.bg-orange-gold {
  background-color: #FF9E00 !important;
}

a.bg-orange-gold:hover, a.bg-orange-gold:focus,
button.bg-orange-gold:hover,
button.bg-orange-gold:focus {
  background-color: #cc7e00 !important;
}

.bg-orange-light {
  background-color: #FC9552 !important;
}

a.bg-orange-light:hover, a.bg-orange-light:focus,
button.bg-orange-light:hover,
button.bg-orange-light:focus {
  background-color: #fb7620 !important;
}

.bg-orange-extra-light {
  background-color: #FCEACE !important;
}

a.bg-orange-extra-light:hover, a.bg-orange-extra-light:focus,
button.bg-orange-extra-light:hover,
button.bg-orange-extra-light:focus {
  background-color: #f9d59e !important;
}

.bg-primary-light {
  background-color: #F2F6FA !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #cdddec !important;
}

.bg-primary-mid {
  background-color: #0077bf !important;
}

a.bg-primary-mid:hover, a.bg-primary-mid:focus,
button.bg-primary-mid:hover,
button.bg-primary-mid:focus {
  background-color: #00578c !important;
}

.bg-primary-mid-light {
  background-color: #afc6ec !important;
}

a.bg-primary-mid-light:hover, a.bg-primary-mid-light:focus,
button.bg-primary-mid-light:hover,
button.bg-primary-mid-light:focus {
  background-color: #86a9e2 !important;
}

.bg-pulse-blue {
  background-color: #0176bd !important;
}

a.bg-pulse-blue:hover, a.bg-pulse-blue:focus,
button.bg-pulse-blue:hover,
button.bg-pulse-blue:focus {
  background-color: #01568a !important;
}

.bg-pulse-benchmark {
  background-color: #70963D !important;
}

a.bg-pulse-benchmark:hover, a.bg-pulse-benchmark:focus,
button.bg-pulse-benchmark:hover,
button.bg-pulse-benchmark:focus {
  background-color: #55722e !important;
}

.bg-red-dark {
  background-color: #922E2B !important;
}

a.bg-red-dark:hover, a.bg-red-dark:focus,
button.bg-red-dark:hover,
button.bg-red-dark:focus {
  background-color: #6b221f !important;
}

.bg-red-light {
  background-color: #AA3C39 !important;
}

a.bg-red-light:hover, a.bg-red-light:focus,
button.bg-red-light:hover,
button.bg-red-light:focus {
  background-color: #842f2c !important;
}

.bg-red-lighter {
  background-color: #D95350 !important;
}

a.bg-red-lighter:hover, a.bg-red-lighter:focus,
button.bg-red-lighter:hover,
button.bg-red-lighter:focus {
  background-color: #ca2f2c !important;
}

.bg-red-extra-light {
  background-color: #E9CECD !important;
}

a.bg-red-extra-light:hover, a.bg-red-extra-light:focus,
button.bg-red-extra-light:hover,
button.bg-red-extra-light:focus {
  background-color: #d9abaa !important;
}

.bg-teal-dark {
  background-color: #43B4B2 !important;
}

a.bg-teal-dark:hover, a.bg-teal-dark:focus,
button.bg-teal-dark:hover,
button.bg-teal-dark:focus {
  background-color: #358f8d !important;
}

.bg-teal-light {
  background-color: #67CBC9 !important;
}

a.bg-teal-light:hover, a.bg-teal-light:focus,
button.bg-teal-light:hover,
button.bg-teal-light:focus {
  background-color: #41bebc !important;
}

.bg-teal-extra-light {
  background-color: #BEE4E3 !important;
}

a.bg-teal-extra-light:hover, a.bg-teal-extra-light:focus,
button.bg-teal-extra-light:hover,
button.bg-teal-extra-light:focus {
  background-color: #9ad5d3 !important;
}

.bg-violet-dark {
  background-color: #945487 !important;
}

a.bg-violet-dark:hover, a.bg-violet-dark:focus,
button.bg-violet-dark:hover,
button.bg-violet-dark:focus {
  background-color: #734269 !important;
}

.bg-violet-light {
  background-color: #AB5B9B !important;
}

a.bg-violet-light:hover, a.bg-violet-light:focus,
button.bg-violet-light:hover,
button.bg-violet-light:focus {
  background-color: #8c477e !important;
}

.bg-violet-extra-light {
  background-color: #E5CEE0 !important;
}

a.bg-violet-extra-light:hover, a.bg-violet-extra-light:focus,
button.bg-violet-extra-light:hover,
button.bg-violet-extra-light:focus {
  background-color: #d3adcb !important;
}

.bg-white {
  background-color: #fff !important;
}

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e5e5 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dddddd !important;
}

.border-top {
  border-top: 1px solid #dddddd !important;
}

.border-right {
  border-right: 1px solid #dddddd !important;
}

.border-bottom {
  border-bottom: 1px solid #dddddd !important;
}

.border-left {
  border-left: 1px solid #dddddd !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0066A3 !important;
}

.border-secondary {
  border-color: #999999 !important;
}

.border-success {
  border-color: #81AC48 !important;
}

.border-info {
  border-color: #67CBC9 !important;
}

.border-warning {
  border-color: #F0AD4E !important;
}

.border-danger {
  border-color: #D9534F !important;
}

.border-light {
  border-color: #f8f8f8 !important;
}

.border-dark {
  border-color: #505050 !important;
}

.border-primary-dark {
  border-color: #273953 !important;
}

.border-mid {
  border-color: #999999 !important;
}

.border-mid-light {
  border-color: #f8f8f8 !important;
}

.border-accent {
  border-color: #81AC48 !important;
}

.border-accent-light {
  border-color: #B5D190 !important;
}

.border-accent-extra-light {
  border-color: #DFE8D4 !important;
}

.border-aha {
  border-color: #003087 !important;
}

.border-aha-blue {
  border-color: #B9D9EB !important;
}

.border-black {
  border-color: #000 !important;
}

.border-blue-dark {
  border-color: #0066a3 !important;
}

.border-blue-light {
  border-color: #0077bf !important;
}

.border-blue-sky {
  border-color: #3CA7E6 !important;
}

.border-blue-pale {
  border-color: #56AADB !important;
}

.border-blue-pulse {
  border-color: #0076BC !important;
}

.border-blue-extra-super-light {
  border-color: #E5F2F2 !important;
}

.border-blue-extra-light {
  border-color: #C6D1E1 !important;
}

.border-blue-super-light {
  border-color: #F2F6FA !important;
}

.border-covid-blue {
  border-color: #44A4DB !important;
}

.border-gray-dark {
  border-color: #505050 !important;
}

.border-gray-light {
  border-color: #999999 !important;
}

.border-gray-100 {
  border-color: #f8f8f8 !important;
}

.border-gray-150 {
  border-color: #F0F0F1 !important;
}

.border-gray-175 {
  border-color: #EFEFEF !important;
}

.border-gray-200 {
  border-color: #e5e5e5 !important;
}

.border-gray-300 {
  border-color: #dddddd !important;
}

.border-gray-400 {
  border-color: #cccccc !important;
}

.border-gray-500 {
  border-color: #ababab !important;
}

.border-gray-600 {
  border-color: #999999 !important;
}

.border-gray-700 {
  border-color: #707070 !important;
}

.border-gray-800 {
  border-color: #505050 !important;
}

.border-gray-900 {
  border-color: #303030 !important;
}

.border-green-dark {
  border-color: #81AC48 !important;
}

.border-green-lime {
  border-color: #70963D !important;
}

.border-green-light {
  border-color: #B5D190 !important;
}

.border-green-extra-light {
  border-color: #DFE8D4 !important;
}

.border-highlight-green {
  border-color: #E2EAD8 !important;
}

.border-highlight-teal {
  border-color: #EAF7FF !important;
}

.border-orange-dark {
  border-color: #F58238 !important;
}

.border-orange-gold {
  border-color: #FF9E00 !important;
}

.border-orange-light {
  border-color: #FC9552 !important;
}

.border-orange-extra-light {
  border-color: #FCEACE !important;
}

.border-primary-light {
  border-color: #F2F6FA !important;
}

.border-primary-mid {
  border-color: #0077bf !important;
}

.border-primary-mid-light {
  border-color: #afc6ec !important;
}

.border-pulse-blue {
  border-color: #0176bd !important;
}

.border-pulse-benchmark {
  border-color: #70963D !important;
}

.border-red-dark {
  border-color: #922E2B !important;
}

.border-red-light {
  border-color: #AA3C39 !important;
}

.border-red-lighter {
  border-color: #D95350 !important;
}

.border-red-extra-light {
  border-color: #E9CECD !important;
}

.border-teal-dark {
  border-color: #43B4B2 !important;
}

.border-teal-light {
  border-color: #67CBC9 !important;
}

.border-teal-extra-light {
  border-color: #BEE4E3 !important;
}

.border-violet-dark {
  border-color: #945487 !important;
}

.border-violet-light {
  border-color: #AB5B9B !important;
}

.border-violet-extra-light {
  border-color: #E5CEE0 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0 !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0 !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.h-5 {
  height: 5% !important;
}

.h-10 {
  height: 10% !important;
}

.h-15 {
  height: 15% !important;
}

.h-20 {
  height: 20% !important;
}

.h-30 {
  height: 30% !important;
}

.h-35 {
  height: 35% !important;
}

.h-40 {
  height: 40% !important;
}

.h-45 {
  height: 45% !important;
}

.h-55 {
  height: 55% !important;
}

.h-60 {
  height: 60% !important;
}

.h-65 {
  height: 65% !important;
}

.h-70 {
  height: 70% !important;
}

.h-80 {
  height: 80% !important;
}

.h-85 {
  height: 85% !important;
}

.h-90 {
  height: 90% !important;
}

.h-95 {
  height: 95% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 0.75rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.75rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.75rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.75rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.75rem !important;
}

.m-4 {
  margin: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1rem !important;
}

.m-5 {
  margin: 1.5rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.5rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.5rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.5rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.5rem !important;
}

.m-6 {
  margin: 2rem !important;
}

.mt-6,
.my-6 {
  margin-top: 2rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 2rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 2rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 2rem !important;
}

.m-7 {
  margin: 3rem !important;
}

.mt-7,
.my-7 {
  margin-top: 3rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 3rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 3rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 0.75rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.75rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.75rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.75rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.75rem !important;
}

.p-4 {
  padding: 1rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1rem !important;
}

.p-5 {
  padding: 1.5rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.5rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.5rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.5rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.5rem !important;
}

.p-6 {
  padding: 2rem !important;
}

.pt-6,
.py-6 {
  padding-top: 2rem !important;
}

.pr-6,
.px-6 {
  padding-right: 2rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 2rem !important;
}

.pl-6,
.px-6 {
  padding-left: 2rem !important;
}

.p-7 {
  padding: 3rem !important;
}

.pt-7,
.py-7 {
  padding-top: 3rem !important;
}

.pr-7,
.px-7 {
  padding-right: 3rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 3rem !important;
}

.pl-7,
.px-7 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -0.75rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -0.75rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -0.75rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -0.75rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -0.75rem !important;
}

.m-n4 {
  margin: -1rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1rem !important;
}

.m-n5 {
  margin: -1.5rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -1.5rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -1.5rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -1.5rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -1.5rem !important;
}

.m-n6 {
  margin: -2rem !important;
}

.mt-n6,
.my-n6 {
  margin-top: -2rem !important;
}

.mr-n6,
.mx-n6 {
  margin-right: -2rem !important;
}

.mb-n6,
.my-n6 {
  margin-bottom: -2rem !important;
}

.ml-n6,
.mx-n6 {
  margin-left: -2rem !important;
}

.m-n7 {
  margin: -3rem !important;
}

.mt-n7,
.my-n7 {
  margin-top: -3rem !important;
}

.mr-n7,
.mx-n7 {
  margin-right: -3rem !important;
}

.mb-n7,
.my-n7 {
  margin-bottom: -3rem !important;
}

.ml-n7,
.mx-n7 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 0.75rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.75rem !important;
  }
  .m-sm-4 {
    margin: 1rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1rem !important;
  }
  .m-sm-5 {
    margin: 1.5rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1.5rem !important;
  }
  .m-sm-6 {
    margin: 2rem !important;
  }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 2rem !important;
  }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 2rem !important;
  }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 2rem !important;
  }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 2rem !important;
  }
  .m-sm-7 {
    margin: 3rem !important;
  }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 3rem !important;
  }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 3rem !important;
  }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 0.75rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.75rem !important;
  }
  .p-sm-4 {
    padding: 1rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1rem !important;
  }
  .p-sm-5 {
    padding: 1.5rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1.5rem !important;
  }
  .p-sm-6 {
    padding: 2rem !important;
  }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 2rem !important;
  }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 2rem !important;
  }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 2rem !important;
  }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 2rem !important;
  }
  .p-sm-7 {
    padding: 3rem !important;
  }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 3rem !important;
  }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 3rem !important;
  }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -0.75rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n4 {
    margin: -1rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1rem !important;
  }
  .m-sm-n5 {
    margin: -1.5rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n6 {
    margin: -2rem !important;
  }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -2rem !important;
  }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -2rem !important;
  }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -2rem !important;
  }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -2rem !important;
  }
  .m-sm-n7 {
    margin: -3rem !important;
  }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -3rem !important;
  }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -3rem !important;
  }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 0.75rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.75rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.75rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.75rem !important;
  }
  .m-md-4 {
    margin: 1rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1rem !important;
  }
  .m-md-5 {
    margin: 1.5rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1.5rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1.5rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1.5rem !important;
  }
  .m-md-6 {
    margin: 2rem !important;
  }
  .mt-md-6,
  .my-md-6 {
    margin-top: 2rem !important;
  }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 2rem !important;
  }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 2rem !important;
  }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 2rem !important;
  }
  .m-md-7 {
    margin: 3rem !important;
  }
  .mt-md-7,
  .my-md-7 {
    margin-top: 3rem !important;
  }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 3rem !important;
  }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 3rem !important;
  }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 0.75rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.75rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.75rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.75rem !important;
  }
  .p-md-4 {
    padding: 1rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1rem !important;
  }
  .p-md-5 {
    padding: 1.5rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1.5rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1.5rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1.5rem !important;
  }
  .p-md-6 {
    padding: 2rem !important;
  }
  .pt-md-6,
  .py-md-6 {
    padding-top: 2rem !important;
  }
  .pr-md-6,
  .px-md-6 {
    padding-right: 2rem !important;
  }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 2rem !important;
  }
  .pl-md-6,
  .px-md-6 {
    padding-left: 2rem !important;
  }
  .p-md-7 {
    padding: 3rem !important;
  }
  .pt-md-7,
  .py-md-7 {
    padding-top: 3rem !important;
  }
  .pr-md-7,
  .px-md-7 {
    padding-right: 3rem !important;
  }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 3rem !important;
  }
  .pl-md-7,
  .px-md-7 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -0.75rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.75rem !important;
  }
  .m-md-n4 {
    margin: -1rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1rem !important;
  }
  .m-md-n5 {
    margin: -1.5rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1.5rem !important;
  }
  .m-md-n6 {
    margin: -2rem !important;
  }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -2rem !important;
  }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -2rem !important;
  }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -2rem !important;
  }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -2rem !important;
  }
  .m-md-n7 {
    margin: -3rem !important;
  }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -3rem !important;
  }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -3rem !important;
  }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 0.75rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.75rem !important;
  }
  .m-lg-4 {
    margin: 1rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1rem !important;
  }
  .m-lg-5 {
    margin: 1.5rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1.5rem !important;
  }
  .m-lg-6 {
    margin: 2rem !important;
  }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 2rem !important;
  }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 2rem !important;
  }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 2rem !important;
  }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 2rem !important;
  }
  .m-lg-7 {
    margin: 3rem !important;
  }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 3rem !important;
  }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 3rem !important;
  }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 0.75rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.75rem !important;
  }
  .p-lg-4 {
    padding: 1rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1rem !important;
  }
  .p-lg-5 {
    padding: 1.5rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1.5rem !important;
  }
  .p-lg-6 {
    padding: 2rem !important;
  }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 2rem !important;
  }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 2rem !important;
  }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 2rem !important;
  }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 2rem !important;
  }
  .p-lg-7 {
    padding: 3rem !important;
  }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 3rem !important;
  }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 3rem !important;
  }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -0.75rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n4 {
    margin: -1rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1rem !important;
  }
  .m-lg-n5 {
    margin: -1.5rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n6 {
    margin: -2rem !important;
  }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -2rem !important;
  }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -2rem !important;
  }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -2rem !important;
  }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -2rem !important;
  }
  .m-lg-n7 {
    margin: -3rem !important;
  }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -3rem !important;
  }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -3rem !important;
  }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 0.75rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.75rem !important;
  }
  .m-xl-4 {
    margin: 1rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1rem !important;
  }
  .m-xl-5 {
    margin: 1.5rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1.5rem !important;
  }
  .m-xl-6 {
    margin: 2rem !important;
  }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 2rem !important;
  }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 2rem !important;
  }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 2rem !important;
  }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 2rem !important;
  }
  .m-xl-7 {
    margin: 3rem !important;
  }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 3rem !important;
  }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 3rem !important;
  }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 0.75rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.75rem !important;
  }
  .p-xl-4 {
    padding: 1rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1rem !important;
  }
  .p-xl-5 {
    padding: 1.5rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1.5rem !important;
  }
  .p-xl-6 {
    padding: 2rem !important;
  }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 2rem !important;
  }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 2rem !important;
  }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 2rem !important;
  }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 2rem !important;
  }
  .p-xl-7 {
    padding: 3rem !important;
  }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 3rem !important;
  }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 3rem !important;
  }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -0.75rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n4 {
    margin: -1rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1rem !important;
  }
  .m-xl-n5 {
    margin: -1.5rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n6 {
    margin: -2rem !important;
  }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -2rem !important;
  }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -2rem !important;
  }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -2rem !important;
  }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -2rem !important;
  }
  .m-xl-n7 {
    margin: -3rem !important;
  }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -3rem !important;
  }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -3rem !important;
  }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 500 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #0066A3 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #003657 !important;
}

.text-secondary {
  color: #999999 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #737373 !important;
}

.text-success {
  color: #81AC48 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #597631 !important;
}

.text-info {
  color: #67CBC9 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #3baba9 !important;
}

.text-warning {
  color: #F0AD4E !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #df8a13 !important;
}

.text-danger {
  color: #D9534F !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #b52b27 !important;
}

.text-light {
  color: #f8f8f8 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #d2d2d2 !important;
}

.text-dark {
  color: #505050 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #2a2a2a !important;
}

.text-primary-dark {
  color: #273953 !important;
}

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #0f151f !important;
}

.text-mid {
  color: #999999 !important;
}

a.text-mid:hover, a.text-mid:focus {
  color: #737373 !important;
}

.text-mid-light {
  color: #f8f8f8 !important;
}

a.text-mid-light:hover, a.text-mid-light:focus {
  color: #d2d2d2 !important;
}

.text-accent {
  color: #81AC48 !important;
}

a.text-accent:hover, a.text-accent:focus {
  color: #597631 !important;
}

.text-accent-light {
  color: #B5D190 !important;
}

a.text-accent-light:hover, a.text-accent-light:focus {
  color: #91bb5a !important;
}

.text-accent-extra-light {
  color: #DFE8D4 !important;
}

a.text-accent-extra-light:hover, a.text-accent-extra-light:focus {
  color: #bacda2 !important;
}

.text-aha {
  color: #003087 !important;
}

a.text-aha:hover, a.text-aha:focus {
  color: #00153b !important;
}

.text-aha-blue {
  color: #B9D9EB !important;
}

a.text-aha-blue:hover, a.text-aha-blue:focus {
  color: #7eb9da !important;
}

.text-black {
  color: #000 !important;
}

a.text-black:hover, a.text-black:focus {
  color: black !important;
}

.text-blue-dark {
  color: #0066a3 !important;
}

a.text-blue-dark:hover, a.text-blue-dark:focus {
  color: #003657 !important;
}

.text-blue-light {
  color: #0077bf !important;
}

a.text-blue-light:hover, a.text-blue-light:focus {
  color: #004773 !important;
}

.text-blue-sky {
  color: #3CA7E6 !important;
}

a.text-blue-sky:hover, a.text-blue-sky:focus {
  color: #1880bd !important;
}

.text-blue-pale {
  color: #56AADB !important;
}

a.text-blue-pale:hover, a.text-blue-pale:focus {
  color: #2886bc !important;
}

.text-blue-pulse {
  color: #0076BC !important;
}

a.text-blue-pulse:hover, a.text-blue-pulse:focus {
  color: #004670 !important;
}

.text-blue-extra-super-light {
  color: #E5F2F2 !important;
}

a.text-blue-extra-super-light:hover, a.text-blue-extra-super-light:focus {
  color: #b2d9d9 !important;
}

.text-blue-extra-light {
  color: #C6D1E1 !important;
}

a.text-blue-extra-light:hover, a.text-blue-extra-light:focus {
  color: #94a9c7 !important;
}

.text-blue-super-light {
  color: #F2F6FA !important;
}

a.text-blue-super-light:hover, a.text-blue-super-light:focus {
  color: #bbd0e5 !important;
}

.text-covid-blue {
  color: #44A4DB !important;
}

a.text-covid-blue:hover, a.text-covid-blue:focus {
  color: #227db1 !important;
}

.text-gray-dark {
  color: #505050 !important;
}

a.text-gray-dark:hover, a.text-gray-dark:focus {
  color: #2a2a2a !important;
}

.text-gray-light {
  color: #999999 !important;
}

a.text-gray-light:hover, a.text-gray-light:focus {
  color: #737373 !important;
}

.text-gray-100 {
  color: #f8f8f8 !important;
}

a.text-gray-100:hover, a.text-gray-100:focus {
  color: #d2d2d2 !important;
}

.text-gray-150 {
  color: #F0F0F1 !important;
}

a.text-gray-150:hover, a.text-gray-150:focus {
  color: #c8c8cc !important;
}

.text-gray-175 {
  color: #EFEFEF !important;
}

a.text-gray-175:hover, a.text-gray-175:focus {
  color: #c9c9c9 !important;
}

.text-gray-200 {
  color: #e5e5e5 !important;
}

a.text-gray-200:hover, a.text-gray-200:focus {
  color: #bfbfbf !important;
}

.text-gray-300 {
  color: #dddddd !important;
}

a.text-gray-300:hover, a.text-gray-300:focus {
  color: #b7b7b7 !important;
}

.text-gray-400 {
  color: #cccccc !important;
}

a.text-gray-400:hover, a.text-gray-400:focus {
  color: #a6a6a6 !important;
}

.text-gray-500 {
  color: #ababab !important;
}

a.text-gray-500:hover, a.text-gray-500:focus {
  color: #858585 !important;
}

.text-gray-600 {
  color: #999999 !important;
}

a.text-gray-600:hover, a.text-gray-600:focus {
  color: #737373 !important;
}

.text-gray-700 {
  color: #707070 !important;
}

a.text-gray-700:hover, a.text-gray-700:focus {
  color: #4a4a4a !important;
}

.text-gray-800 {
  color: #505050 !important;
}

a.text-gray-800:hover, a.text-gray-800:focus {
  color: #2a2a2a !important;
}

.text-gray-900 {
  color: #303030 !important;
}

a.text-gray-900:hover, a.text-gray-900:focus {
  color: #0a0a0a !important;
}

.text-green-dark {
  color: #81AC48 !important;
}

a.text-green-dark:hover, a.text-green-dark:focus {
  color: #597631 !important;
}

.text-green-lime {
  color: #70963D !important;
}

a.text-green-lime:hover, a.text-green-lime:focus {
  color: #476027 !important;
}

.text-green-light {
  color: #B5D190 !important;
}

a.text-green-light:hover, a.text-green-light:focus {
  color: #91bb5a !important;
}

.text-green-extra-light {
  color: #DFE8D4 !important;
}

a.text-green-extra-light:hover, a.text-green-extra-light:focus {
  color: #bacda2 !important;
}

.text-highlight-green {
  color: #E2EAD8 !important;
}

a.text-highlight-green:hover, a.text-highlight-green:focus {
  color: #bdcfa6 !important;
}

.text-highlight-teal {
  color: #EAF7FF !important;
}

a.text-highlight-teal:hover, a.text-highlight-teal:focus {
  color: #9edaff !important;
}

.text-orange-dark {
  color: #F58238 !important;
}

a.text-orange-dark:hover, a.text-orange-dark:focus {
  color: #d65a0b !important;
}

.text-orange-gold {
  color: #FF9E00 !important;
}

a.text-orange-gold:hover, a.text-orange-gold:focus {
  color: #b36f00 !important;
}

.text-orange-light {
  color: #FC9552 !important;
}

a.text-orange-light:hover, a.text-orange-light:focus {
  color: #fb6707 !important;
}

.text-orange-extra-light {
  color: #FCEACE !important;
}

a.text-orange-extra-light:hover, a.text-orange-extra-light:focus {
  color: #f8cb86 !important;
}

.text-primary-light {
  color: #F2F6FA !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #bbd0e5 !important;
}

.text-primary-mid {
  color: #0077bf !important;
}

a.text-primary-mid:hover, a.text-primary-mid:focus {
  color: #004773 !important;
}

.text-primary-mid-light {
  color: #afc6ec !important;
}

a.text-primary-mid-light:hover, a.text-primary-mid-light:focus {
  color: #719add !important;
}

.text-pulse-blue {
  color: #0176bd !important;
}

a.text-pulse-blue:hover, a.text-pulse-blue:focus {
  color: #014671 !important;
}

.text-pulse-benchmark {
  color: #70963D !important;
}

a.text-pulse-benchmark:hover, a.text-pulse-benchmark:focus {
  color: #476027 !important;
}

.text-red-dark {
  color: #922E2B !important;
}

a.text-red-dark:hover, a.text-red-dark:focus {
  color: #571b1a !important;
}

.text-red-light {
  color: #AA3C39 !important;
}

a.text-red-light:hover, a.text-red-light:focus {
  color: #712826 !important;
}

.text-red-lighter {
  color: #D95350 !important;
}

a.text-red-lighter:hover, a.text-red-lighter:focus {
  color: #b52a27 !important;
}

.text-red-extra-light {
  color: #E9CECD !important;
}

a.text-red-extra-light:hover, a.text-red-extra-light:focus {
  color: #d29a98 !important;
}

.text-teal-dark {
  color: #43B4B2 !important;
}

a.text-teal-dark:hover, a.text-teal-dark:focus {
  color: #2e7c7b !important;
}

.text-teal-light {
  color: #67CBC9 !important;
}

a.text-teal-light:hover, a.text-teal-light:focus {
  color: #3baba9 !important;
}

.text-teal-extra-light {
  color: #BEE4E3 !important;
}

a.text-teal-extra-light:hover, a.text-teal-extra-light:focus {
  color: #88cecc !important;
}

.text-violet-dark {
  color: #945487 !important;
}

a.text-violet-dark:hover, a.text-violet-dark:focus {
  color: #63385a !important;
}

.text-violet-light {
  color: #AB5B9B !important;
}

a.text-violet-light:hover, a.text-violet-light:focus {
  color: #7b3f6f !important;
}

.text-violet-extra-light {
  color: #E5CEE0 !important;
}

a.text-violet-extra-light:hover, a.text-violet-extra-light:focus {
  color: #ca9cc0 !important;
}

.text-white {
  color: #fff !important;
}

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important;
}

.text-body {
  color: #303030 !important;
}

.text-muted {
  color: #999999 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #ababab;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dddddd !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dddddd;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dddddd;
  }
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
.load,
.load:before,
.load:after {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}

.load {
  color: #dddddd;
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.load:before,
.load:after {
  content: '';
  position: absolute;
  top: 0;
}

.load:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.load:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
.medium-viewer img {
  max-width: 100%;
}

.medium-toolbar-arrow-under:after {
  top: 25px;
  border-color: #505050 transparent transparent transparent;
}

.medium-toolbar-arrow-over:before {
  top: -8px;
  border-color: transparent transparent #505050 transparent;
}

.medium-editor-toolbar {
  background-color: #505050;
}

.medium-editor-toolbar li {
  padding: 0;
}

.medium-editor-toolbar li button {
  min-width: 25px;
  height: 25px;
  border: none;
  line-height: 0 !important;
  border-right: 1px solid #838383;
  background-color: transparent;
  color: #fff;
  transition: background-color .2s ease-in, color .2s ease-in;
}

.medium-editor-toolbar li button:hover {
  background-color: #1d1d1d;
  color: #fff;
}

.medium-editor-toolbar li .medium-editor-button-active {
  background-color: #040404;
  color: #fff;
}

.medium-editor-toolbar li .medium-editor-button-last {
  border-right: none;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input {
  height: 25px;
  background: #505050;
  color: #fff;
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::-webkit-input-placeholder {
  color: #999999;
  color: rgba(153, 153, 153, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-moz-placeholder {
  /* Firefox 18- */
  color: #999999;
  color: rgba(153, 153, 153, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::-moz-placeholder {
  /* Firefox 19+ */
  color: #999999;
  color: rgba(153, 153, 153, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input:-ms-input-placeholder {
  color: #999999;
  color: rgba(153, 153, 153, 0.8);
}

.medium-editor-toolbar-form .medium-editor-toolbar-input::placeholder {
  color: #999999;
  color: rgba(153, 153, 153, 0.8);
}

.medium-editor-toolbar-form a {
  color: #fff;
}

.medium-editor-toolbar-anchor-preview {
  background: #505050;
  color: #fff;
}

.medium-editor-placeholder:after {
  color: #cccccc;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #505050;
  font-family: "Poppins", "proxima-nova", "Helvetica", "Arial", "sans-serif";
}

body {
  background: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 767.98px) {
  body {
    background: #fff;
  }
}

p,
li,
ul,
body,
text,
textarea {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 400 !important;
}

a {
  color: #0066a3;
}

strong {
  font-weight: 500;
}

input {
  font-weight: 400 !important;
}

.btn {
  border-radius: 4px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: 500;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #cccccc;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #cccccc;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #cccccc;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #cccccc;
}

.card {
  border: 1px;
  border-radius: 0;
  box-shadow: 0 0px 7px 0px rgba(0, 0, 0, 0.07);
}

.card .card-header {
  border: none;
  border-radius: 0;
}

.card .card-block,
.card .card-body,
.card .card-footer {
  padding: 1rem;
}

.overflow-auto {
  overflow: auto !important;
}

*:focus {
  outline: 0 !important;
}

ngb-progressbar .progress-bar {
  padding-left: .25rem;
}

ngb-tooltip-window.bs-tooltip-bottom .arrow::before, ngb-tooltip-window.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #505050 !important;
}

ngb-tooltip-window.bs-tooltip-right .arrow::before, ngb-tooltip-window.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #505050 !important;
}

ngb-tooltip-window.bs-tooltip-left .arrow::before, ngb-tooltip-window.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #505050 !important;
  border-right-color: #505050 !important;
}

ngb-tooltip-window.bs-tooltip-top .arrow::before, ngb-tooltip-window.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #505050 !important;
}

ngb-tooltip-window.show {
  opacity: .95 !important;
}

ngb-tooltip-window.show .arrow::before {
  opacity: 0.95;
}

ngb-tooltip-window.show .tooltip-inner {
  font-weight: 500;
  font-size: 12px;
  background-color: rgba(80, 80, 80, 0.95) !important;
  color: white;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
}

date-input-polyfill {
  z-index: 1060 !important;
}

@media (max-width: 767.98px) {
  ngb-modal-window.modal .modal-header button.close {
    font-size: 34px !important;
  }
}

li.ql-mention-list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.show-modal-overflow {
  overflow-y: initial;
  overflow: initial;
}

.show-modal-overflow > .modal-dialog > .modal-content {
  overflow-y: initial;
  overflow: initial;
}

.vis-tag .tooltip .tooltip-inner {
  min-width: 100px;
  white-space: normal;
}

.pdf-download-option .mat-progress-spinner circle, .pdf-download-option .mat-spinner circle {
  stroke: #fff;
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
body {
  color: #505050;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif" !important;
}

body.swal2-shown {
  z-index: 2000 !important;
  overflow-y: hidden;
}

body.swal2-iosfix {
  position: fixed;
  left: 0;
  right: 0;
}

.swal2-cancel {
  font-size: 14px !important;
  padding: 0.75rem !important;
  font-weight: 300 !important;
  text-align: center !important;
  color: #707070 !important;
  background: transparent !important;
  border: none !important;
  text-transform: uppercase !important;
  font-style: italic;
}

.swal2-cancel i {
  vertical-align: bottom;
}

.swal2-cancel:hover {
  background: #f8f8f8;
}

.swal2-container {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}

.swal2-container.swal2-fade {
  transition: background-color .1s;
}

.swal2-container.swal2-shown {
  z-index: 2000 !important;
  background-color: rgba(0, 0, 0, 0.4);
}

.swal2-modal {
  background-color: #fff;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  margin: auto;
  overflow-x: hidden;
  overflow-y: auto;
  display: none;
  position: relative;
}

.swal2-modal:focus {
  outline: none;
}

.swal2-modal.swal2-loading {
  overflow-y: hidden;
}

.swal2-modal .swal2-title {
  color: #505050;
  font-size: 30px;
  text-align: center;
  font-weight: 300;
  text-transform: none;
  position: relative;
  margin: 0 0 .4em;
  padding: 0;
  display: block;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
}

.swal2-modal .swal2-spacer {
  height: 10px;
  color: transparent;
  border: 0;
}

.swal2-modal .swal2-styled {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  margin: 0 5px;
  padding: 10px 32px;
  outline: none;
}

.swal2-modal .swal2-styled:not(.swal2-loading)[disabled] {
  opacity: .4;
  cursor: no-drop;
}

.swal2-modal .swal2-styled.swal2-loading {
  box-sizing: border-box;
  border: 4px solid transparent;
  border-color: transparent;
  width: 40px;
  height: 40px;
  padding: 0;
  margin: -2px 30px;
  vertical-align: top;
  background-color: transparent !important;
  color: transparent;
  cursor: default;
  border-radius: 100%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
  user-select: none;
}

.swal2-modal .swal2-styled + .swal2-styled {
  margin-top: 15px;
}

.swal2-modal :not(.swal2-styled).swal2-loading::after {
  display: inline-block;
  content: '';
  margin-left: 5px;
  vertical-align: -1px;
  height: 6px;
  width: 6px;
  border: 3px solid #999999;
  border-right-color: transparent;
  border-radius: 50%;
  animation: rotate-loading 1.5s linear 0s infinite normal;
}

.swal2-modal .swal2-image {
  margin: 20px auto;
  max-width: 100%;
}

.swal2-modal .swal2-close {
  font-size: 36px;
  line-height: 36px;
  font-family: serif;
  position: absolute;
  top: 5px;
  right: 13px;
  cursor: pointer;
  color: #cccccc;
  transition: color .1s ease;
}

.swal2-modal .swal2-close:hover {
  color: #d55;
}

.swal2-modal > .swal2-input,
.swal2-modal > .swal2-file,
.swal2-modal > .swal2-textarea,
.swal2-modal > .swal2-select,
.swal2-modal > .swal2-radio,
.swal2-modal > .swal2-checkbox {
  display: none;
}

.swal2-modal .swal2-content {
  font-size: 18px;
  text-align: center;
  font-weight: 300;
  position: relative;
  float: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  color: #505050;
}

.swal2-modal .swal2-input,
.swal2-modal .swal2-file,
.swal2-modal .swal2-textarea,
.swal2-modal .swal2-select,
.swal2-modal .swal2-radio,
.swal2-modal .swal2-checkbox {
  margin: 20px auto;
}

.swal2-modal .swal2-input,
.swal2-modal .swal2-file,
.swal2-modal .swal2-textarea {
  width: 100%;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #d9d9d9;
  font-size: 18px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.06);
}

.swal2-modal .swal2-input.swal2-inputerror,
.swal2-modal .swal2-file.swal2-inputerror,
.swal2-modal .swal2-textarea.swal2-inputerror {
  border-color: #f06e57 !important;
}

.swal2-modal .swal2-input:focus,
.swal2-modal .swal2-file:focus,
.swal2-modal .swal2-textarea:focus {
  outline: none;
  box-shadow: 0 0 3px #c4e6f5;
  border: 1px solid #b4dbed;
}

.swal2-modal .swal2-input:focus::placeholder,
.swal2-modal .swal2-file:focus::placeholder,
.swal2-modal .swal2-textarea:focus::placeholder {
  transition: opacity .3s .03s ease;
  opacity: .8;
}

.swal2-modal .swal2-input::placeholder,
.swal2-modal .swal2-file::placeholder,
.swal2-modal .swal2-textarea::placeholder {
  color: #e6e5e5;
}

.swal2-modal .swal2-range input {
  float: left;
  width: 80%;
}

.swal2-modal .swal2-range output {
  float: right;
  width: 20%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.swal2-modal .swal2-range input,
.swal2-modal .swal2-range output {
  height: 43px;
  line-height: 43px;
  vertical-align: middle;
  margin: 20px auto;
  padding: 0;
}

.swal2-modal .swal2-input {
  height: 43px;
  padding: 0 12px;
}

.swal2-modal .swal2-input[type='number'] {
  max-width: 150px;
}

.swal2-modal .swal2-file {
  font-size: 20px;
}

.swal2-modal .swal2-textarea {
  height: 108px;
  padding: 12px;
}

.swal2-modal .swal2-select {
  color: #505050;
  font-size: inherit;
  padding: 5px 10px;
  min-width: 40%;
  max-width: 100%;
}

.swal2-modal .swal2-radio {
  border: 0;
}

.swal2-modal .swal2-radio label:not(:first-child) {
  margin-left: 20px;
}

.swal2-modal .swal2-radio input,
.swal2-modal .swal2-radio span {
  vertical-align: middle;
}

.swal2-modal .swal2-radio input {
  margin: 0 3px 0 0;
}

.swal2-modal .swal2-checkbox {
  color: #505050;
}

.swal2-modal .swal2-checkbox input,
.swal2-modal .swal2-checkbox span {
  vertical-align: middle;
}

.swal2-modal .swal2-validationerror {
  background-color: #f0f0f0;
  margin: 0 -20px;
  overflow: hidden;
  padding: 10px;
  color: #807f7f;
  font-size: 16px;
  font-weight: 300;
  display: none;
}

.swal2-modal .swal2-validationerror::before {
  content: '!';
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #ea7d7d;
  color: #fff;
  line-height: 24px;
  text-align: center;
  margin-right: 10px;
}

.swal2-popup .swal2-styled:focus {
  outline: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

@supports (-ms-accelerator: true) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .swal2-range input {
    width: 100% !important;
  }
  .swal2-range output {
    display: none;
  }
}

.swal2-icon {
  width: 80px;
  height: 80px;
  border: 4px solid transparent;
  border-radius: 50%;
  margin: 20px auto 30px;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  cursor: default;
  user-select: none;
}

.swal2-icon.swal2-error {
  border-color: #D9534F;
}

.swal2-icon.swal2-error .x-mark {
  position: relative;
  display: block;
}

.swal2-icon.swal2-error .line {
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: #D9534F;
  display: block;
  top: 37px;
  border-radius: 2px;
}

.swal2-icon.swal2-error .line.left {
  transform: rotate(45deg);
  left: 17px;
}

.swal2-icon.swal2-error .line.right {
  transform: rotate(-45deg);
  right: 16px;
}

.swal2-icon.swal2-warning {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #F0AD4E;
  border-color: #f3bc6f;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
}

.swal2-icon.swal2-info {
  font-family: 'Open Sans', sans-serif;
  color: #0077bf;
  border-color: #26adff;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
}

.swal2-icon.swal2-question {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #87adbd;
  border-color: #c9dae1;
  font-size: 60px;
  line-height: 80px;
  text-align: center;
}

.swal2-icon.swal2-success {
  border-color: #81AC48;
}

.swal2-icon.swal2-success::before, .swal2-icon.swal2-success::after {
  content: '';
  border-radius: 50%;
  position: absolute;
  width: 60px;
  height: 120px;
  background: #fff;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success::before {
  border-radius: 120px 0 0 120px;
  top: -7px;
  left: -33px;
  transform: rotate(-45deg);
  transform-origin: 60px 60px;
}

.swal2-icon.swal2-success::after {
  border-radius: 0 120px 120px 0;
  top: -11px;
  left: 30px;
  transform: rotate(-45deg);
  transform-origin: 0 60px;
}

.swal2-icon.swal2-success .placeholder {
  width: 80px;
  height: 80px;
  border: 4px solid rgba(129, 172, 72, 0.2);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

.swal2-icon.swal2-success .fix {
  width: 7px;
  height: 90px;
  background-color: #fff;
  position: absolute;
  left: 28px;
  top: 8px;
  z-index: 1;
  transform: rotate(-45deg);
}

.swal2-icon.swal2-success .line {
  height: 5px;
  background-color: #81AC48;
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.swal2-icon.swal2-success .line.tip {
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
}

.swal2-icon.swal2-success .line.long {
  width: 47px;
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
}

.swal2-progresssteps {
  font-weight: 600;
  margin: 0 0 20px;
  padding: 0;
}

.swal2-progresssteps li {
  display: inline-block;
  position: relative;
}

.swal2-progresssteps .swal2-progresscircle {
  background: #0066A3;
  border-radius: 2em;
  color: #fff;
  height: 2em;
  line-height: 2em;
  text-align: center;
  width: 2em;
  z-index: 20;
}

.swal2-progresssteps .swal2-progresscircle:first-child {
  margin-left: 0;
}

.swal2-progresssteps .swal2-progresscircle:last-child {
  margin-right: 0;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
  background: #0066A3;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
  background: #0077bf;
}

.swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
  background: #0077bf;
}

.swal2-progresssteps .swal2-progressline {
  background: #0066A3;
  height: .4em;
  margin: 0 -1px;
  z-index: 10;
}

[class^='swal2'] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@keyframes showSweetAlert {
  0% {
    transform: scale(0.7);
  }
  45% {
    transform: scale(1.05);
  }
  80% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes hideSweetAlert {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.5);
    opacity: 0;
  }
}

.swal2-show {
  animation: showSweetAlert 0.3s;
}

.swal2-show.swal2-noanimation {
  animation: none;
}

.swal2-hide {
  animation: hideSweetAlert 0.15s forwards;
}

.swal2-hide.swal2-noanimation {
  animation: none;
}

@keyframes animate-success-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animate-success-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

.animate-success-tip {
  animation: animate-success-tip 0.75s;
}

.animate-success-long {
  animation: animate-success-long 0.75s;
}

.swal2-success.animate::after {
  animation: rotatePlaceholder 4.25s ease-in;
}

@keyframes animate-error-icon {
  0% {
    transform: rotateX(100deg);
    opacity: 0;
  }
  100% {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

.animate-error-icon {
  animation: animate-error-icon 0.5s;
}

@keyframes animate-x-mark {
  0% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

.animate-x-mark {
  animation: animate-x-mark 0.5s;
}

@keyframes pulse-warning {
  0% {
    border-color: #f8d486;
  }
  100% {
    border-color: #f8bb86;
  }
}

.pulse-warning {
  animation: pulse-warning 0.75s infinite alternate;
}

@keyframes rotate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.headerblock {
  margin: 0;
  padding: 20px 0;
}

.headerblock .aviablock {
  padding: 0;
  text-align: center;
  white-space: nowrap;
}

.headerblock .aviablock .avialogo {
  height: 28px;
  margin: 0;
  padding: 0;
}

.headerblock .backblock {
  margin: 0 1rem 0 auto;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}

.headerblock .backblock .backlink {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.avia-modal-full-screen .page {
  background-color: #fff;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  margin: 0;
  min-height: 100vh;
  max-width: none;
  padding: 0;
}

.avia-modal-full-screen .page .headerblock {
  margin: 0;
  padding: 20px 0;
}

.avia-modal-full-screen .page .headerblock .aviablock {
  padding: 0;
  text-align: center;
  white-space: nowrap;
}

.avia-modal-full-screen .page .headerblock .aviablock .avialogo {
  height: 28px;
  margin: 0;
  padding: 0;
}

.avia-modal-full-screen .page .headerblock .backblock {
  margin: 0 1rem 0 auto;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}

.avia-modal-full-screen .page .headerblock .backblock .backlink {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.avia-modal-full-screen .page .subpage {
  min-height: calc( 100vh - (57px + 77px));
}

.avia-modal-full-screen-inverse .page {
  background-color: #f8f8f8;
}

.avia-modal-full-screen-inverse .page .subpage {
  background-color: #fff;
}

.avia-modal {
  background: #fff !important;
}

.avia-modal .page {
  padding: 0;
}

.avia-modal .page .headerblock {
  margin: 0;
  padding: 20px 0;
}

.avia-modal .page .headerblock .aviablock {
  padding: 0;
  text-align: center;
  white-space: nowrap;
}

.avia-modal .page .headerblock .aviablock .avialogo {
  height: 28px;
  margin: 0;
  padding: 0;
}

.avia-modal .page .headerblock .backblock {
  margin: 0 1rem 0 auto;
  padding: 0;
  text-align: right;
  white-space: nowrap;
}

.avia-modal .page .headerblock .backblock .backlink {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.avia-modal .modal-content {
  border: none;
  border-radius: 0;
  outline: none;
}

.avia-modal .modal-content .modal-header {
  border: none;
  color: #505050;
  display: block;
  font-size: 25px;
  font-weight: 300;
  margin-bottom: 0 !important;
  padding: 1rem;
  text-align: center;
}

.avia-modal .modal-content .modal-header .btn-close {
  background: none;
  border: none;
  color: #0066A3;
  cursor: pointer;
  font-size: 12px;
  line-height: 37px;
  outline: none;
  padding: 0;
  text-transform: uppercase;
}

.avia-modal .modal-content .modal-header .btn-close i {
  color: #505050;
  vertical-align: middle;
}

.avia-modal .modal-content .modal-body {
  padding: 0;
}

.avia-modal .modal-content .modal-body label {
  color: #505050;
}

.avia-modal .modal-content .modal-body input[type="text"],
.avia-modal .modal-content .modal-body input[type="date"],
.avia-modal .modal-content .modal-body input[type="select"] {
  -webkit-appearance: none;
  background: none;
  border: #cccccc 1px solid;
  border-radius: none;
  -webkit-border-radius: 0px;
  color: #505050;
  font-size: 14px;
  height: 38px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
}

.avia-modal .modal-content .modal-body textarea {
  -webkit-appearance: none;
  background: none;
  border: #cccccc 1px solid;
  border-radius: none;
  -webkit-border-radius: 0px;
  color: #505050;
  font-size: 14px;
  outline: none;
  padding: 0.5rem;
  width: 100%;
}

.avia-modal .modal-content .modal-body button {
  font-weight: 300;
}

.avia-modal .modal-content .modal-body .upload-image {
  display: flex;
  margin-top: 10px;
}

.avia-modal .modal-content .modal-body .upload-image img {
  border: 1px solid #f8f8f8;
  height: 38px;
  margin-right: 10px;
}

.avia-modal .modal-content .modal-footer {
  border: none;
}

.avia-modal .modal-content .modal-footer button {
  font-weight: 300;
}

.avia-modal2 {
  height: 100%;
  width: 100%;
}

.avia-modal2 .modal-content {
  border: none;
  border-radius: 0;
  outline: none;
}

@media (min-width: 768px) {
  .avia-modal-md .modal-dialog {
    max-width: calc(100vw - 60%);
  }
}

.avia-modal-backdrop {
  border: none;
  border-radius: 0;
  margin: 0;
  outline: none;
  padding: 0;
}

.avia-modal-backdrop .modal-dialog {
  border: none;
  border-radius: 0;
  height: 65%;
  max-width: 100%;
  outline: none;
}

@media (min-width: 768px) {
  .avia-modal-backdrop .modal-dialog {
    width: 60%;
  }
}

.avia-modal-backdrop .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
  height: 100%;
  outline: none;
}

.avia-modal-fullscreen {
  border: none;
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 0;
  width: 100%;
}

.avia-modal-fullscreen .modal-dialog {
  border: none;
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 0;
}

.avia-modal-fullscreen .modal-dialog .modal-content {
  border: none;
  border-radius: 0;
  height: 100%;
  margin: 0;
  outline: none;
  padding: 0;
}

.discard-modal-backdrop {
  opacity: 0.3 !important;
}

.modal-sm {
  max-width: 450px;
}

@media (min-width: 768px) {
  .modal-md .modal-dialog {
    min-width: 650px;
  }
  .modal-md .modal-dialog .modal-content {
    min-width: 650px;
  }
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
.ql-blank::before {
  padding: initial !important;
  font-style: normal !important;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
  color: #dddddd !important;
}

.ql-blank::before {
  height: calc(0.875rem + 0.5rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ql-editor {
  padding: initial !important;
  font-style: normal !important;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
}

.input-box-lg .ql-editor {
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
  font-family: "Poppins", "Helvetica Neue", "Arial", "sans-serif";
}

.input-box-lg .ql-blank::before {
  font-style: normal !important;
  font-family: "Poppins", "Helvetica Neue", "Arial", "sans-serif";
  color: #dddddd !important;
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
}

.input-box-lg .ql-blank::before {
  overflow: hidden;
  line-height: calc(0.875rem + 0.5rem);
}

.ql-stroke {
  stroke: #dddddd !important;
}

.ql-fill {
  fill: #dddddd !important;
}

.ql-container.ql-snow {
  border: initial !important;
}

.ql-editor.ql-blank::before {
  left: 0px !important;
  right: 0px !important;
}

.ql-mention-list-item {
  padding: 0 10px !important;
}

@media (min-width: 650px) {
  .ql-mention-list-container {
    width: 400px !important;
  }
}

@media (max-width: 650px) {
  .ql-mention-list-container {
    width: 270px;
  }
}

.mention {
  background-color: initial !important;
}

span.mention a {
  background-color: rgba(0, 118, 188, 0.12) !important;
  text-decoration: none;
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
.joyride-backdrop {
  background-color: rgba(0, 0, 0, 0.25) !important;
  z-index: 1100 !important;
}

.backdrop-container {
  z-index: 1100 !important;
}

.joyride-step__holder {
  z-index: 1101 !important;
}

.joyride-step__holder .joyride-step__arrow .joyride-arrow__top {
  border-bottom: 11px solid #3DA7E7 !important;
}

.joyride-step__holder .joyride-step__container {
  width: auto !important;
  height: auto !important;
  max-width: 300px !important;
  padding: 1rem !important;
  box-shadow: none !important;
  background-color: #3DA7E7 !important;
  border-radius: 3px;
  color: #fff !important;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif" !important;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__close {
  position: absolute !important;
  right: 1rem !important;
  width: 8px !important;
  height: 8px !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__close svg line {
  stroke: #fff;
}

.joyride-step__holder .joyride-step__container .joyride-step__header {
  padding: 0 0 .5rem 0 !important;
  text-align: left !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__header .joyride-step__title {
  color: #fff !important;
  font-size: 12px !important;
  font-family: "Poppins", "Helvetica Neue", "Arial", "sans-serif" !important;
  font-weight: 500 !important;
  line-height: 16px !important;
  letter-spacing: 0 !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__body {
  text-align: left !important;
  padding: 0 0 1.5rem 0 !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__footer {
  padding: 0 !important;
  font-weight: 500 !important;
  letter-spacing: 2px !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__footer .joyride-step__buttons-container .joyride-step__button .joyride-button {
  background-color: transparent !important;
  padding: 0 !important;
  border-width: 0 !important;
}

.joyride-step__holder .joyride-step__container .joyride-step__footer .joyride-step__counter-container .joyride-step__counter {
  font-size: 12px !important;
}

.no-scroll {
  overflow: hidden;
}

.font-body {
  line-height: 20px;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  color: #505050;
}

.get-on-top {
  z-index: 1100 !important;
}

.get-on-top-2 {
  z-index: 1200 !important;
}

.get-on-top-3 {
  z-index: 1300 !important;
}

.get-on-top-4 {
  z-index: 1400 !important;
}

.get-on-top-5 {
  z-index: 1500 !important;
}

.bold-font {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  font-weight: 500 !important;
  color: #505050;
}

.copy-font {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  color: #505050;
}

.font-sm {
  font-size: 0.75rem;
}

.font-large {
  font-family: "Poppins", "proxima-nova", "Helvetica", "Arial", "sans-serif";
  font-weight: 600;
  font-style: normal;
}

.font-extra-large {
  font-family: 1.25rem;
  font-weight: 600;
  font-style: normal;
}

.blokk {
  font-family: BLOKK;
  letter-spacing: -.1em;
  font-weight: 500 !important;
}

.heading-defaults, .heading-size-13, .heading-size-14, .heading-size-15, .heading-size-16, .heading-size-18, .heading-size-20, .heading-size-24, .heading-size-28, .heading-size-32, .heading-size-36 {
  font-weight: 600;
  color: #505050;
  font-family: "Poppins", "proxima-nova", "Helvetica", "Arial", "sans-serif";
}

.heading-size-13 {
  font-size: 0.8125rem;
  line-height: calc(0.8125rem + 0.5rem);
}

.heading-size-14 {
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
}

.heading-size-15 {
  font-size: 0.9375rem;
  line-height: calc(0.9375rem + 0.5rem);
}

.heading-size-16 {
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
}

.heading-size-18 {
  font-size: 1.125rem;
  line-height: calc(1.125rem + 0.5rem);
}

.heading-size-20 {
  font-size: 1.25rem;
  line-height: calc(1.25rem + 0.5rem);
}

.heading-size-24 {
  font-size: 1.5rem;
  line-height: calc(1.5rem + 0.5rem);
}

.heading-size-28 {
  font-size: 1.75rem;
  line-height: calc(1.75rem + 0.5rem);
}

.heading-size-32 {
  font-size: 2rem;
  line-height: calc(2rem + 0.5rem);
}

.heading-size-36 {
  font-size: 2.25rem;
  line-height: calc(2.25rem + 0.5rem);
}

.font-size-8 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.6rem;
  line-height: calc(0.6rem + 0.5rem);
}

.font-size-10 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.625rem;
  line-height: calc(0.625rem + 0.5rem);
}

.font-size-12 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.75rem;
  line-height: calc(0.75rem + 0.5rem);
}

.font-size-13 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.8125rem;
  line-height: calc(0.8125rem + 0.5rem);
}

.font-size-14 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
}

.font-size-16 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
}

.font-size-18 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 1.125rem;
  line-height: calc(1.125rem + 0.5rem);
}

.font-size-20 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 1.25rem;
  line-height: calc(1.25rem + 0.5rem);
}

.font-size-24 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 1.5rem;
  line-height: calc(1.5rem + 0.5rem);
}

.font-size-36 {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 2.25rem;
  line-height: calc(2.25rem + 0.5rem);
}

.tags-drop-down {
  border: 1px solid #f8f8f8 !important;
  z-index: 99999 !important;
}

.tags-drop-down .select2-results {
  font-size: 12px;
  color: #505050;
}

.tags-drop-down .select2-results ul li {
  font-weight: 300;
  padding: .25rem 1rem .25rem .25rem;
  position: relative;
}

.tags-drop-down .select2-results ul .select2-results__option--highlighted {
  color: #505050 !important;
  background: #F2F6FA !important;
  font-weight: 300px;
}

.tags-drop-down .select2-results ul .select2-results__option[aria-selected=true] {
  background: #f8f8f8;
  color: #0066A3;
  font-weight: 300;
}

.btn-cancel {
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #707070;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
  font-style: italic;
}

.btn-cancel i {
  vertical-align: bottom;
}

.btn-cancel:hover {
  background: #f8f8f8;
}

.btn-switch {
  background: transparent;
  border: none;
  border-radius: 4px;
  color: #0066A3;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
  padding: .25rem;
}

.btn-switch i {
  vertical-align: bottom;
}

.btn-switch:hover {
  background: #F2F6FA;
  border: none;
  border-radius: 4px;
  color: #0066A3;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
  padding: .25rem;
}

.btn-switch:hover i {
  vertical-align: bottom;
}

.btn-switch-active {
  background: #e5e5e5;
  padding: .25rem;
}

.btn-switch-active:hover {
  background: #e5e5e5;
  padding: .25rem;
}

.btn-success {
  background: #273953;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
}

.btn-success i {
  vertical-align: bottom;
}

.btn-success:disabled {
  background: #cccccc;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
}

.btn-success:disabled i {
  vertical-align: bottom;
}

.btn-success:hover {
  background: #0077bf;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
}

.btn-success:hover i {
  vertical-align: bottom;
}

.btn-success:focus {
  background: #0077bf;
  border: none;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
  -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 144, 188, 0.5);
  box-shadow: 0 0 0 0.2rem rgba(117, 144, 188, 0.5);
}

.btn-success:focus i {
  vertical-align: bottom;
}

.btn-round {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  width: 25px;
  height: 25px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  z-index: 5;
  border: none;
  outline: none !important;
  background: #81AC48;
  color: #fff;
}

.btn-round span {
  position: absolute;
  left: 0;
  top: 50%;
  height: 100%;
  width: 100%;
  text-align: center;
  margin-top: -15px;
}

.btn-round span i {
  font-size: 20px;
  line-height: 29px;
  color: #fff;
}

.card .card-block {
  padding: 1rem;
}

.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default;
}

.move {
  cursor: move;
}

select {
  outline: none;
  -webkit-appearance: none;
}

medium-editor {
  outline: none;
  width: 100%;
}

.lockable-input {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.unlocked-border:hover,
.unlocked-border:active,
.unlocked-border:focus {
  background: rgba(0, 0, 0, 0.03) !important;
}

.unlocked-underline:hover {
  background: rgba(0, 0, 0, 0.03) !important;
}

.unlocked-underline,
.unlocked-underline::placeholder {
  text-decoration-color: #0077bf !important;
}

.unlocked-underline-light:hover,
.unlocked-underline-light:focus {
  background: rgba(255, 255, 255, 0.1) !important;
}

:host ::ng-deep input::-webkit-datetime-edit-fields-wrapper {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.acc-header {
  background-color: #e5e5e5;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
}

.header-under-dash .dash {
  width: 30px;
  right: 0;
  left: 0;
  margin: auto;
  border-bottom: 2px solid #dddddd;
}

.header-under-dash .dash-long {
  width: 75px;
  border-bottom: 2px solid #dddddd;
}

.section-header-font {
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 24px;
  color: #505050;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.tab-heading {
  border-bottom: 1px solid #dddddd;
  padding: 0;
  font-weight: 500;
  width: 100%;
  padding-bottom: 0.5rem;
}

.section-heading {
  padding: 0;
  font-weight: 500;
  width: 100%;
}

label {
  color: #505050;
  font-weight: 500;
}

.avia-label-sm {
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-weight: 300;
}

.avia-label-md {
  text-transform: uppercase;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  letter-spacing: 1.75px;
  font-weight: 500;
}

.search-container {
  height: calc(100% - 40px);
  overflow: auto;
  padding: 1rem;
  position: absolute;
  width: 100%;
}

.search-container .result {
  cursor: pointer;
  line-height: 20px;
}

.search-container .result .title {
  color: #81AC48;
  font-size: 16px;
  overflow: hidden;
  padding: 0.25rem, 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-container .result .description,
.search-container .result .cl_card_details {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-container .result .divider {
  border-bottom: solid 1px #e5e5e5;
  margin: 0.5rem, 0;
}

.search-container .result a {
  color: #505050;
  text-decoration: inherit;
}

.bottom-line {
  border-bottom: 1px solid #dddddd;
  padding: 0;
}

.pill-list {
  display: inline-flex;
  margin-right: 0.5rem;
  margin-top: 0.5rem;
  max-width: 100%;
}

.pill-list .pill-list-body {
  align-items: center !important;
  background: #e5e5e5;
  border-radius: 0.25rem !important;
  display: flex;
  max-width: 100%;
}

.pill-list .pill-list-body .pill-list-icon {
  border-radius: 0.25rem !important;
  height: 22px;
  overflow: hidden;
  margin-left: 2px;
  width: 22px;
}

.pill-list .pill-list-body .pill-list-icon > img {
  height: inherit;
  width: inherit;
}

.pill-list .pill-list-body .pill-list-text {
  color: #505050;
  font-size: 0.75rem;
  overflow: hidden;
  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.pill-list .pill-list-body .pill-list-text > i.material-icons {
  font-size: 12px;
  margin-right: 0.25rem;
  position: relative;
  top: 2px;
}

.pill-list .pill-list-body .pill-list-close {
  color: #505050;
  cursor: pointer;
  font-size: 1rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.material-icons.md-8 {
  font-size: 8px;
}

.material-icons.md-10 {
  font-size: 10px;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-14 {
  font-size: 14px;
}

.material-icons.md-16 {
  font-size: 16px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-20 {
  font-size: 20px;
}

.material-icons.md-22 {
  font-size: 22px;
}

.material-icons.md-30 {
  font-size: 30px;
}

.material-icons.md-32 {
  font-size: 32px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.simple-notification.error {
  background: #D9534F !important;
}

.simple-notification.error .sn-progress-loader span {
  background: #c9302c !important;
}

.simple-notification.info {
  background: #0066A3 !important;
}

.simple-notification.info .sn-progress-loader span {
  background: #004670 !important;
}

.simple-notification.success {
  background: #81AC48 !important;
}

.simple-notification.success .sn-progress-loader span {
  background: #668839 !important;
}

.simple-notification.warning {
  background: #F0AD4E !important;
}

.simple-notification.warning .sn-progress-loader span {
  background: #668839 !important;
}

.no-search-results-filler {
  height: 50vh;
  position: relative;
  text-align: center;
}

.no-search-results-filler .title {
  bottom: 0;
  height: 100px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
}

.no-search-results-filler .title i {
  font-size: 50px;
}

.coming-soon {
  font-size: 32px;
  margin: 25px;
  text-align: center;
}

.search-box-wrapper {
  margin-left: 0;
  margin-right: 0;
}

.search-box-wrapper .no-text-section,
.search-box-wrapper .text-section {
  display: inline-flex;
}

.search-box-wrapper .text-section .text-wrapper {
  width: calc(100% - 200px);
  word-wrap: break-word;
}

.search-box-wrapper .text-section .text-wrapper p {
  line-height: 26px;
}

.search-box-wrapper .img-wrapper-text {
  position: relative;
  height: 150px;
  width: 200px;
}

.search-box-wrapper img {
  height: 120px;
  width: auto;
}

.search-box-wrapper .img-large {
  height: 200px;
  width: auto;
}

.search-box-wrapper .img-xl {
  height: 250px;
  width: auto;
  left: 0;
  right: 0;
  margin: auto;
}

.search-box-wrapper .img-1 {
  position: absolute;
}

.search-box-wrapper .img-2 {
  position: absolute;
  top: 10px;
  left: 30px;
}

@media (max-width: 575.98px) {
  .search-box-wrapper .text-section {
    display: block;
  }
  .search-box-wrapper .text-section .img-wrapper-text {
    left: 0;
    right: 0;
    margin: auto;
  }
  .search-box-wrapper .text-section .text-wrapper {
    width: 100%;
  }
  .search-box-wrapper .img-wrapper-no-text {
    height: auto;
    width: 300px;
  }
  .search-box-wrapper .no-text-section .img-large {
    height: 120px;
    width: auto;
  }
  .search-box-wrapper .no-text-section .img-xl {
    height: auto;
    width: 300px;
  }
}

.depth-0 {
  padding-left: 0em;
}

.depth-1 {
  padding-left: .5em;
}

.depth-2 {
  padding-left: 1em;
}

.depth-3 {
  padding-left: 1.5em;
}

.depth-4 {
  padding-left: 2em;
}

.depth-5 {
  padding-left: 2.5em;
}

.depth-6 {
  padding-left: 3em;
}

.force-scrollbar::-webkit-scrollbar {
  width: 9px;
}

.force-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.08);
}

.force-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  -webkit-border-radius: 5px;
}

.force-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.4);
}

.force-scrollbar::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.05);
}

.force-scrollbar.force-scrollbar-x {
  overflow-x: auto;
}

.force-scrollbar.force-scrollbar-y {
  overflow-y: auto;
}

.tag-success {
  background: #81AC48;
}

.text-truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.text-truncate-fade {
  max-height: 6em;
  overflow: hidden;
  position: relative;
  display: block;
}

.text-truncate-fade::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
  content: '\200C';
  display: block;
  position: absolute;
  right: 0;
  top: 4.5em;
  width: 75%;
  text-align: center;
}

.multi-select-wrapper {
  height: 34px;
}

.multi-select-wrapper i {
  line-height: 34px;
}

.alt-gray-rows {
  background: #fff;
}

.alt-gray-rows:nth-child(even) {
  background: #f8f8f8;
}

.resize-none {
  resize: none;
}

h6 {
  font-size: 14px;
  font-weight: 500;
}

.line-row {
  border-bottom: 1px solid #dddddd;
}

.strike-text {
  text-decoration: line-through;
}

table:not(#rolesDisplay) {
  font-size: 12px !important;
}

table:not(#rolesDisplay) tr th,
table:not(#rolesDisplay) tr td {
  border: none !important;
}

.trbl-0 {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.collapse-box ul {
  list-style-type: none;
  padding: 0;
}

.collapse-box ul li {
  border-left: 4px solid #e5e5e5;
  padding-left: .5rem;
  margin-bottom: .5rem;
}

.can-rotate {
  transition: transform 300ms ease-out !important;
}

.rotate-90-cw {
  transform: rotate(90deg);
}

.more {
  color: #cccccc;
  width: 24px;
  height: 24px;
  right: 16px;
}

.more .dropdown-item {
  color: #707070;
  cursor: pointer;
}

.more .dropdown-item i {
  vertical-align: text-bottom;
  font-size: 15px;
  color: #cccccc;
}

.more .dropdown-toggle::after {
  display: none;
}
