@import "./variables";

.ql-blank::before {
  padding: initial !important;
  font-style: normal !important;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
  color: $gray-300 !important;
}

.ql-blank::before {
  height: calc(0.875rem + 0.5rem);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ql-editor {
  padding: initial !important;
  font-style: normal !important;
  font-family: "Roboto", "Helvetica Neue", "Arial", "sans-serif";
  font-size: 0.875rem;
  line-height: calc(0.875rem + 0.5rem);
}

.input-box-lg .ql-editor {
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
  font-family: $fonttype_poppins;
}

.input-box-lg .ql-blank::before {
  font-style: normal !important;
  font-family: $fonttype_poppins;
  color: $gray-300 !important;
  font-size: 1rem;
  line-height: calc(1rem + 0.5rem);
}

.input-box-lg .ql-blank::before {
  overflow: hidden;
  line-height: calc(0.875rem + 0.5rem);
}


.ql-stroke {
  stroke: #dddddd !important;
}


.ql-fill {
  fill: #dddddd !important;
}

.ql-container.ql-snow {
  border: initial !important;
}

.ql-editor.ql-blank::before {
  left: 0px !important;
  right: 0px !important;
}

.ql-mention-list-item {
  padding: 0 10px !important;
}

.ql-mention-list-container{
    @media (min-width: 650px){
      width: 400px !important;
    }
    @media (max-width: 650px){
      width: 270px;
    }
}

.mention {
  background-color: initial!important;
}

span.mention a{
  background-color: rgba(0,118,188,0.12)!important;
  text-decoration: none;
}
