@import "./variables";

.joyride-backdrop {
  background-color: rgba(0,0,0,0.25) !important;
  z-index: 1100 !important;
}

.backdrop-container {
  z-index: 1100 !important;
}

.joyride-step__holder {
  z-index: 1101 !important;

  .joyride-step__arrow {
    .joyride-arrow__top{
      border-bottom: 11px solid #3DA7E7 !important;
    }
  }

  .joyride-step__container {
    width: auto !important;
    height: auto !important;
    max-width: 300px !important;

    padding: 1rem !important;
    box-shadow: none !important;
    background-color: #3DA7E7 !important;
    border-radius: 3px;

    // text
    color: $white !important;
    font-family: $fonttype_aviaconnect !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0 !important;

    .joyride-step__close {
      position: absolute !important;
      right: 1rem !important;
      width: 8px !important;
      height: 8px !important;

      svg {
        line {
          stroke: $white;
        }
      }
    }
    .joyride-step__header {
      padding: 0 0 .5rem 0 !important;
      text-align: left !important;
      .joyride-step__title {
        color: $white !important;
        font-size: 12px !important;
        font-family: $fonttype_poppins !important;
        font-weight: 500 !important;
        line-height: 16px !important;
        letter-spacing: 0 !important;
      }
    }
    .joyride-step__body {
      text-align: left !important;
      padding: 0 0 1.5rem 0 !important;
    }
    .joyride-step__footer {
      padding: 0 !important;
      font-weight: 500 !important;
      letter-spacing: 2px !important;

      .joyride-step__buttons-container {
        .joyride-step__button {
          .joyride-button {
            background-color: transparent !important;
            padding: 0 !important;
            border-width: 0 !important;
          }
        }
      }

      .joyride-step__counter-container {
        .joyride-step__counter {
          font-size: 12px !important;
        }
      }
    }
  }
}
