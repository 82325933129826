.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: $gray-800;
  font-family: $fonttype_avia_large;
}

// BODY
body {
  background: $gray-100;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include media-breakpoint-down(sm) {
    background: $white;
  }
}

p,
li,
ul,
body,
text,
textarea {
  font-family: $fonttype_aviaconnect;
  font-weight: $font-weight-normal !important;
}

//LINK
a {
  color: $blue-dark;
}

// Strong defaults to 700 font-weight which is a significant difference from our standard bold of font-weight 500 (see $font-weight-bold)
strong {
  font-weight: $font-weight-bold;
}

input {
  font-weight: $font-weight-normal !important;
}

.btn {
  border-radius: 4px;
  cursor: pointer;
  text-transform: capitalize;
  font-weight: $font-weight-bold;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $gray-400;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: $gray-400;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: $gray-400;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: $gray-400;
}

// BOOTSTRAP
.card {
  border: $border-width;
  border-radius: 0;
  box-shadow: 0 0px 7px 0px rgba(0,0,0,.07);
  .card-header {
    border: none;
    border-radius: 0;
  }
  .card-block,
  .card-body,
  .card-footer {
    padding: 1rem;
  }
}

.overflow-auto {
  overflow: auto !important
}

// No Outlines
*:focus {
  outline: 0 !important;
}

//ngb-progressbar overrides
ngb-progressbar {
  .progress-bar {
    padding-left: .25rem;
  }
}


// ngb-tooltip overrides (black to gray-900)
ngb-tooltip-window.bs-tooltip-bottom {
  .arrow::before {
    border-bottom-color: $gray-800 !important;
  }
}

ngb-tooltip-window.bs-tooltip-right {
  .arrow::before {
    border-right-color: $gray-800 !important;
  }
}

ngb-tooltip-window.bs-tooltip-left {
  .arrow::before {
    border-left-color: $gray-800 !important;
    border-right-color: $gray-800 !important;
  }
}

ngb-tooltip-window.bs-tooltip-top {
  .arrow::before {
    border-top-color: $gray-800 !important;
  }
}

ngb-tooltip-window.show {
  opacity: .95 !important;
  .arrow::before {
    opacity: 0.95;
  }
  .tooltip-inner {
    font-weight: $font-weight-bold;
    font-size: 12px;
    background-color: rgba(80, 80, 80, 0.95) !important;
    color: white;
    padding: 0.25rem 0.5rem;
    border-radius: 3px;
  }
}

date-input-polyfill{
  z-index: 1060 !important;
}

@include media-breakpoint-down(sm) {
  ngb-modal-window.modal {
    .modal-header {
      button.close {
        font-size: 34px !important;
      }
    }
  }
}


li.ql-mention-list-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


.show-modal-overflow {
  overflow-y: initial;
  overflow: initial;
}

.show-modal-overflow > .modal-dialog > .modal-content {
  overflow-y: initial;
  overflow: initial;
}

.vis-tag .tooltip .tooltip-inner {
  min-width: 100px;
  white-space: normal;
}

.pdf-download-option {
  .mat-progress-spinner circle, .mat-spinner circle {
    stroke: $white;
  }
}
