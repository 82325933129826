@import "./variables";
//--------------------------- Medium Editor Overrides ---------------------------
// theme settings
$medium-editor-bgcolor: $gray-800;
$medium-editor-border-color: $gray-800;
$medium-editor-button-size: 25px;
$medium-editor-button-active-text-color: $white;
$medium-editor-link-color: $white;
$medium-editor-placeholder-color: $gray-600;
.medium-viewer {
  img {
    max-width: 100%;
  }
}
// theme rules
.medium-toolbar-arrow-under:after {
  top: $medium-editor-button-size;
  border-color: $medium-editor-bgcolor transparent transparent transparent;
}

.medium-toolbar-arrow-over:before {
  top: -8px;
  border-color: transparent transparent $medium-editor-bgcolor transparent;
}

.medium-editor-toolbar {
  background-color: $medium-editor-bgcolor;
  li {
    padding: 0;
    button {
      min-width: $medium-editor-button-size;
      height: $medium-editor-button-size;
      border: none;
      line-height: 0 !important;
      border-right: 1px solid lighten($medium-editor-bgcolor, 20);
      background-color: transparent;
      color: $medium-editor-link-color;
      transition: background-color .2s ease-in, color .2s ease-in;
      &:hover {
        background-color: darken($medium-editor-bgcolor, 20);
        color: $medium-editor-button-active-text-color;
      }
    }
    .medium-editor-button-active {
      background-color: darken($medium-editor-bgcolor, 30);
      color: $medium-editor-button-active-text-color;
    }
    .medium-editor-button-last {
      border-right: none;
    }
  }
}

.medium-editor-toolbar-form {
  .medium-editor-toolbar-input {
    height: $medium-editor-button-size;
    background: $medium-editor-bgcolor;
    color: $medium-editor-link-color;
    &::-webkit-input-placeholder {
      color: $medium-editor-placeholder-color;
      color: rgba($medium-editor-placeholder-color, .8);
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: $medium-editor-placeholder-color;
      color: rgba($medium-editor-placeholder-color, .8);
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: $medium-editor-placeholder-color;
      color: rgba($medium-editor-placeholder-color, .8);
    }
    &:-ms-input-placeholder {
      color: $medium-editor-placeholder-color;
      color: rgba($medium-editor-placeholder-color, .8);
    }
    &::placeholder {
      color: $medium-editor-placeholder-color;
      color: rgba($medium-editor-placeholder-color, .8);
    }
  }
  a {
    color: $medium-editor-link-color;
  }
}

.medium-editor-toolbar-anchor-preview {
  background: $medium-editor-bgcolor;
  color: $medium-editor-link-color;
}

.medium-editor-placeholder:after {
  color: lighten($medium-editor-placeholder-color, 20);
}
