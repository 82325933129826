//--------------------------- VARIABLES (Please keep alphabetized, within groupings!) ---------------------------
$APP_CONTENT_MAX_WIDTH: 1280px;

$font0: 10px;
$font1: $font-size-sm;
$font2: $font-size-base;
$font3: $font-size-lg;
$font4: 18px;
$font5: 24px;
$font6: 36px;
$font-body: $font2;
$font-h1: $font6;
$font-h2: $font5;
$shadow : 0 1px 0 0 rgba(229, 229, 229, 0.5);
$shadow-card: 0 0px 7px 0px rgba(0,0,0,.07);
$shadow-paginator: 0 0px 7px 0px rgba(0,0,0,0.15);
$underline: 1px solid $gray-300; // Avia standard underline (and sometimes border-bottom)

//--------------------------- FUNCTIONS (Please keep alphabetized!) ---------------------------
/// Convert angle
/// @author Chris Eppstein
/// @param {Number} $value - Value to convert
/// @param {String} $unit - Unit to convert to
/// @return {Number} Converted angle
@function convert-angle($value, $unit) {
  $convertable-units: deg grad turn rad;
  $conversion-factors: 1 (10grad/9deg) (1turn/360deg) (3.1415926rad/180deg);
  @if index($convertable-units, unit($value)) and index($convertable-units, $unit) {
    @return $value / nth($conversion-factors, index($convertable-units, unit($value))) * nth($conversion-factors, index($convertable-units, $unit));
  }
  @warn "Cannot convert `#{unit($value)}` to `#{$unit}`.";
}

/// Test if `$value` is an angle
/// @param {*} $value - Value to test
/// @return {Bool} Is `$value` an angle
@function is-direction($value) {
  $is-direction: index((to top, to top right, to right top, to right, to bottom right, to right bottom, to bottom, to bottom left, to left bottom, to left, to left top, to top left), $value);
  $is-angle: type-of($value)=='number' and index('deg' 'grad' 'turn' 'rad', unit($value));
  @return $is-direction or $is-angle;
}

/// Convert a direction to legacy syntax
/// @param {Keyword | Angle} $value - Value to convert
/// @require {function} is-direction
/// @require {function} convert-angle
@function legacy-direction($value) {
  @if is-direction($value)==false {
    @warn "Cannot convert `#{$value}` to legacy syntax because it doesn't seem to be an angle or a direction";
  }
  $conversion-map: ( to top: bottom, to top right: bottom left, to right top: left bottom, to right: left, to bottom right: top left, to right bottom: left top, to bottom: top, to bottom left: top right, to left bottom: right top, to left: right, to left top: right bottom, to top left: bottom right);
  @if map-has-key($conversion-map, $value) {
    @return map-get($conversion-map, $value);
  }
  @return 90deg - convert-angle($value, 'deg');
}


//--------------------------- MIXINS (Please keep alphabetized!) ---------------------------
@mixin alert($bg, $color) {
  background: $bg;
  color: $color;
  font-size: 16px;
}

@mixin btn($bg, $color:$white) {
  background: $bg;
  border: none;
  border-radius: 4px;
  color: $color;
  font-size: 14px;
  font-weight: $font-weight-bold;
  padding: 0.75rem;
  text-align: center;
  text-transform: capitalize;
  i {
    vertical-align: bottom;
  }
}

@mixin circle_icon($size, $icon_size) {
  min-width: $size;
  width: $size;
  min-height: $size;
  height: $size;
  position: relative;
  border-radius: 50%;
  i {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: $icon_size;
    width: $icon_size;
    font-size: $icon-size;
  }
}

@mixin criss-cross($hue: 205, $saturation: 70%, $lightness: 48%, $offset: 30%) {
  /*
    Creates Andy's dual-diagonal, linear gradient background pattern.
    (Defaults to the 'cyan' color from Invision if the HSL params are not supplied.)
  */
  --bg-hue: #{$hue};
  --bg-saturation: #{$saturation};
  --bg-lightness: #{$lightness};

  /* set angles for the tint and shade gradients */
  --bg-tint-angle: -15deg;
  --bg-shade-angle: 5deg;

  /* set amounts for default tint & shade */
  --bg-tint-amount: 20%;
  --bg-shade-amount: -30%;

  /* set the gradient positions all at once */
  --bg-gradient-stops: #{$offset};

  /* override tint/shade saturation & lightness directly */
  --bg-tint-saturation: var(--bg-saturation);
  --bg-tint-lightness: calc(var(--bg-lightness) + var(--bg-tint-amount));
  --bg-shade-saturation: var(--bg-saturation);
  --bg-shade-lightness: calc(var(--bg-lightness) - var(--bg-shade-amount));

  /* this is the internal logic that creates your angled tint/shade background */
  --bg-image:
    linear-gradient(
      var(--bg-tint-angle),
      hsla(var(--bg-hue), var(--bg-tint-saturation), var(--bg-tint-lightness), 0.25) var(--bg-gradient-stops),
      transparent var(--bg-gradient-stops)
    ),
    linear-gradient(
      var(--bg-shade-angle),
      hsla(var(--bg-hue), var(--bg-shade-saturation), var(--bg-shade-lightness), 0.25) var(--bg-gradient-stops),
      transparent var(--bg-gradient-stops)
    )
  ;

  /* Creating a final "output" variable acts like a function return */
  --bg: var(--bg-image) hsl(var(--bg-hue), var(--bg-saturation), var(--bg-lightness));

  /* Applying that value to a property creates a mixin */
  /* Since the initial return is invalid, nothing happens unless we set a --bg-hue */
  background: var(--bg);
}

/* Use a hex to RGB converter to pass in the respective RGB values with the sanitizer. */
@mixin crisscross($height: 100px, $red: 1, $green: 118, $blue: 189){
  height: $height;
  overflow: hidden;
  position: relative;

  --red: #{$red};
  --green: #{$green};
  --blue: #{$blue};

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: $height solid rgba(var(--red), var(--green), var(--blue), 0.7);
    border-right: 100vw solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-bottom: calc(#{$height} * 1.5) solid rgba(var(--red), var(--green), var(--blue), 0.75);
    border-left: 100vw solid transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 40%;
  }

  // Small devices (landscape phones, 576px and up)
  // @media (min-width: 576px) { ... }

  // Medium devices (tablets, 768px and up)
    @media (min-width: 768px) {
      &:after {
        border-bottom: calc(#{$height} * 1.75) solid rgba(var(--red), var(--green), var(--blue), 0.75);
      }
    }

    // Large devices (desktops, 992px and up)
    @media (min-width: 992px) {
      &:after {
        border-bottom: calc(#{$height} * 2) solid rgba(var(--red), var(--green), var(--blue), 0.75);
      }
    }

    // Extra large devices (large desktops, 1200px and up)
    @media (min-width: 1200px) {
      &:after {
        border-bottom: calc(#{$height} * 2.2) solid rgba(var(--red), var(--green), var(--blue), 0.75);
      }
      &:before {
        border-bottom: calc(#{$height} * 1.2) solid rgba(var(--red), var(--green), var(--blue), 0.7);
      }
    }
}

@mixin nav-crisscross($height: 100px, $red: 1, $green: 118, $blue: 189){
  height: $height;
  overflow: hidden;
  position: relative;

  --red: #{$red};
  --green: #{$green};
  --blue: #{$blue};

  &:before {
    content: "";
    width: 0;
    height: 0;
    border-bottom: calc(#{$height} * 10) solid rgba(var(--red), var(--green), var(--blue), 0.7);
    border-right: 100vw solid transparent;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-bottom: calc(#{$height} * 18) solid rgba(var(--red), var(--green), var(--blue), 0.75);
    border-left: 100vw solid transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    margin-left: 40%;
  }
}

// Display treatment for an Inactive user or Org
//  - Passing 'false' to $fade will disable the 'fade back' effect
@mixin inactive($fade:true) {
  background-image: url(/assets/inactive_bg.png);
  background-repeat: repeat;
  @if $fade==true {
    .fade-target {
      opacity: 0.3;
    }
  }
}

// Mixin printing a linear-gradient
// as well as a plain color fallback
// and the `-webkit-` prefixed declaration
// @param {String | List | Angle} $direction - Linear gradient direction
// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  @if is-direction($direction)==false {
    $color-stops: ($direction, $color-stops);
    $direction: 180deg;
  }
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

// Display treatment for an Unpublished (Draft) Topic, Activity, or Workspace
//  - Passing 'true' to $fade will enable the 'fade back' effect
@mixin not-published($fade:false) {
  background-image: url(/assets/inactive_bg_dark.png);
  background-repeat: repeat;
  @if $fade==true {
    opacity: 0.6;
  }
}

// Display treatment for a Pending user or Org
@mixin pending() {
  .fade-target {
    opacity: 0.3;
  }
}

@mixin responsive-header-size($size) {
  font-size: $size;
  line-height: calc(#{$size} + 0.5rem);
  // removing bc we are not resizing our font anymore.....
  // @include media-breakpoint-down(sm) {
  //   font-size: calc(#{$size} + 0.125rem);
  //   line-height: calc(#{$size} + 0.625rem);
  // }
}
