// Mixin created so as not to have to duplicate code!  :)
@mixin Headerblock() {
  margin: 0;
  padding: 20px 0; // if change, you'll need to update subpage min-height
  .aviablock {
    // margin-top: 20px;
    // margin-bottom: 20px;
    padding: 0;
    text-align: center;
    white-space: nowrap;
    .avialogo {
      height: 28px; // if change, you'll need to update subpage min-height
      margin: 0;
      padding: 0;
    }
  }
  .backblock {
    margin: 0 $spacer 0 auto;
    padding: 0;
    text-align: right;
    white-space: nowrap;
    .backlink {
      font-size: 14px;
      margin: 0;
      padding: 0;
    }
  }
}

.headerblock {
  @include Headerblock();
}

// NOTE: REALLY BAD NAMING - This is NOT the same as the '.avia-modal-fullscreen' seen at the bottom of this file
.avia-modal-full-screen {
  .page {
    background-color: $white;
    font-family: $fonttype_aviaconnect;
    font-size: $font-body;
    margin: 0;
    min-height: 100vh;
    max-width: none;
    padding: 0;
    .headerblock {
      @include Headerblock();
    }
    .subpage {
      min-height: calc( 100vh - (57px + 77px));
    }
  }
}

.avia-modal-full-screen-inverse {
  .page {
    background-color: $gray-100;

    .subpage {
      background-color: $white;
    }
  }
}

// use the default for modals which dont cover the screen...
// ngb-modal-window {
//    background: none;
// }
// for a modal that looks fullscreen BUT actually has a backdrop which is white, it places a box in the middle of the screen (the backdrop sidebars keep the centered box from reaching the edges).
// use in your bootstrap modal open( { windowsClass: "avia-modal-backdrop", ... )
// for other styling, you can place in your component's scss file
.avia-modal {
  background: $white !important;
  .page {
    padding: 0;
    .headerblock {
      @include Headerblock();
    }
  }
  .modal-content {
    border: none;
    border-radius: 0;
    outline: none;
    .modal-header {
      border: none;
      color: $gray-800;
      display: block;
      font-size: 25px;
      font-weight: 300;
      margin-bottom: 0 !important;
      padding: $spacer;
      text-align: center;
      .btn-close {
        background: none;
        border: none;
        color: $primary;
        cursor: pointer;
        font-size: 12px;
        line-height: 37px;
        outline: none;
        padding: 0;
        text-transform: uppercase;
        i {
          color: $gray-800;
          vertical-align: middle;
        }
      }
    }
    .modal-body {
      padding: 0;
      label {
        color: $gray-800;
      }
      input[type="text"],
      input[type="date"],
      input[type="select"] {
        -webkit-appearance: none;
        background: none;
        border: $gray-400 1px solid;
        border-radius: none;
        -webkit-border-radius: 0px;
        color: $gray-800;
        font-size: 14px;
        height: 38px;
        outline: none;
        padding: map-get($spacers, 2);
        width: 100%;
      }
      textarea {
        -webkit-appearance: none;
        background: none;
        border: $gray-400 1px solid;
        border-radius: none;
        -webkit-border-radius: 0px;
        color: $gray-800;
        font-size: 14px;
        outline: none;
        padding: map-get($spacers, 2);
        width: 100%;
      }
      button {
        font-weight: 300;
      }
      .upload-image {
        display: flex;
        margin-top: 10px;
        img {
          border: 1px solid $gray-100;
          height: 38px;
          margin-right: 10px;
        }
      }
    }
    .modal-footer {
      border: none;
      button {
        font-weight: 300;
      }
    }
  }
}

// for a modal that looks fullscreen BUT actually has a backdrop which is white, it places a box in the middle of the screen (the backdrop sidebars keep the centered box from reaching the edges).
// use in your bootstrap modal open( { windowsClass: "avia-modal-backdrop", ... )
// for other styling, you can place in your component's scss file
.avia-modal2 {
  height: 100%;
  width: 100%;
  .modal-content {
    border: none;
    border-radius: 0;
    outline: none;
  }
}

// ng-bootstrap has 'sm' | 'lg' options. This is creating a 'md' class for modal size.
// Use { windowClass: 'avia-modal-md' }. Can be used with other modal classes.
.avia-modal-md {
  .modal-dialog {
    @include media-breakpoint-up(md) {
      max-width: calc(100vw - 60%);
    }
  }
}

// for a modal with a backdrop which dims the screen 50%, and places a small box in the middle
// use in your bootstrap modal open( { windowsClass: "avia-modal-backdrop", ... )
// for other styling, you can place in your component's scss file
.avia-modal-backdrop {
  border: none;
  border-radius: 0;
  margin: 0;
  outline: none;
  padding: 0;
  .modal-dialog {
    border: none;
    border-radius: 0;
    height: 65%;
    @include media-breakpoint-up(md) {
      width: 60%;
    }
    max-width: 100%;
    outline: none;
    .modal-content {
      border: none;
      border-radius: 0;
      height: 100%;
      outline: none;
    }
  }
}

// for a modal which covers the entire window
// use in your bootstrap modal open( { windowsClass: "avia-modal-fullscreen", ... )
// for other styling, you can place in your component's scss file
.avia-modal-fullscreen {
  border: none;
  border-radius: 0;
  height: 100%;
  margin: 0;
  max-width: 100%;
  outline: none;
  padding: 0;
  width: 100%;
  .modal-dialog {
    border: none;
    border-radius: 0;
    height: 100%;
    margin: 0;
    max-width: 100%;
    outline: none;
    padding: 0;
    .modal-content {
      border: none;
      border-radius: 0;
      height: 100%;
      margin: 0;
      outline: none;
      padding: 0;
    }
  }
}

.discard-modal-backdrop {
  opacity: 0.3 !important;
}

.modal-sm {
  max-width: 450px;
}

@media (min-width: 768px) {
  .modal-md {
    .modal-dialog {
      min-width: 650px;
      .modal-content{
        min-width: 650px;
      }
    }
  }
}
