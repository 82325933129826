//////////////////////////////////////////////////////////////////////////////////////
////// DO NOT IMPORT THIS INTO YOUR COMPONENT .SCSS, IT RESULTS IN DUPLICATION ///////
////// INSTEAD IMPORT _common.scss which gives variables, functions, mixins    ///////
//////////////////////////////////////////////////////////////////////////////////////

@import "./_common"; // common variables
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "./styles/load";
@import "./styles/medium";
@import "./styles/overrides";
@import "./styles/swal2";
@import "./styles/modal";
@import "./styles/calendar";
@import "./styles/quill";
@import "./styles/joyride";

.no-scroll {
  overflow: hidden;
}

// FONTS
.font-body {
  line-height: 20px;
  font-family: $fonttype_aviaconnect;
  font-size: $font-body;
  color: $gray-800;
}

.get-on-top {
  z-index: 1100 !important;
}
.get-on-top-2 {
  z-index: 1200 !important;
}
.get-on-top-3 {
  z-index: 1300 !important;
}
.get-on-top-4 {
  z-index: 1400 !important;
}
.get-on-top-5 {
  z-index: 1500 !important;
}

.bold-font {
  font-family: $fonttype_aviaconnect;
  font-size: $font-body;
  font-weight: $font-weight-bold !important; //500;
  color: $gray-800;
}

.copy-font {
  font-family: $fonttype_aviaconnect;
  font-size: $font-body;
  color: $gray-800;
}

.font-sm {
  font-size: $font-size-sm;
}

.font-large {
  font-family: $fonttype_avia_large;
  font-weight: 600;
  font-style: normal;
}

.font-extra-large {
  font-family: $font-size-xl;
  font-weight: 600;
  font-style: normal;
}

.blokk {
  font-family: BLOKK;
  letter-spacing: -.1em;
  font-weight: $font-weight-bold !important;
}

.heading-defaults {
  font-weight: 600;
  color: $gray-800;
  font-family: $fonttype_avia_large;
}

.heading-size-13 {
  @extend .heading-defaults;
  @include responsive-header-size(0.8125rem);
}

.heading-size-14 {
  @extend .heading-defaults;
  @include responsive-header-size(0.875rem);
}

.heading-size-15 {
  @extend .heading-defaults;
  @include responsive-header-size(.9375rem);
}

.heading-size-16 {
  @extend .heading-defaults;
  @include responsive-header-size(1rem);
}

.heading-size-18 {
  @extend .heading-defaults;
  @include responsive-header-size(1.125rem);
}

.heading-size-20 {
  @extend .heading-defaults;
  @include responsive-header-size(1.25rem);
}

.heading-size-24 {
  @extend .heading-defaults;
  @include responsive-header-size(1.5rem);
}

.heading-size-28 {
  @extend .heading-defaults;
  @include responsive-header-size(1.75rem);
}

.heading-size-32 {
  @extend .heading-defaults;
  @include responsive-header-size(2rem);
}

.heading-size-36 {
  @extend .heading-defaults;
  @include responsive-header-size(2.25rem);
}



.font-size-8 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(0.6rem);
}
.font-size-10 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(0.625rem);
}
.font-size-12 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(0.75rem);
}
.font-size-13 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(0.8125rem);
}
.font-size-14 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(0.875rem);
}

.font-size-16 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(1rem);
}

.font-size-18 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(1.125rem);
}

.font-size-20 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(1.25rem);
}

.font-size-24 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(1.5rem);
}

.font-size-36 {
  font-family: $fonttype_aviaconnect;
  @include responsive-header-size(2.25rem);
}


//--------------------------- OVERRIDES Please keep alphabetize! ---------------------------

// avia-tags
// See avia-tags-component - possible refactor in future to include
// CSS in component or with Select2 options additions
.tags-drop-down {
  border: 1px solid $gray-100 !important;
  z-index: 99999 !important;
  .select2-results {
    font-size: 12px;
    color: $gray-800;

    ul {
      li {
        font-weight: 300;
        padding: .25rem 1rem .25rem .25rem;
        position: relative;
      }

      .select2-results__option--highlighted {
        color: $gray-800 !important;
        background: $primary-light !important;
        font-weight: 300px;
      }

      .select2-results__option[aria-selected=true] {
        background: $gray-100;
        color: $primary;
        font-weight: 300;
      }
    }
  }
}

// BUTTONS
.btn-cancel {
  @include btn(transparent, $gray-700);
  font-style: italic;

  &:hover {
    background: $gray-100;
  }
}

.btn-switch {
  @include btn(transparent, $primary);
  padding: .25rem;

  &:hover {
    @include btn($primary-light, $primary);
    padding: .25rem;

  }
}

.btn-switch-active {
  background: $gray-200;
  padding: .25rem;

  &:hover {
    background: $gray-200;
    padding: .25rem;

  }
}

.btn-success {
  @include btn($primary-dark);

  &:disabled {
    @include btn($gray-400);
  }

  &:hover {
    @include btn($primary-mid);
  }

  &:focus {
    @include btn($primary-mid);
    -webkit-box-shadow: 0 0 0 0.2rem rgba(117, 144, 188, 0.5);
    box-shadow: 0 0 0 0.2rem rgba(117, 144, 188, 0.5);
  }
}

.btn-round {
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-border-radius: 50%;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  width: 25px;
  height: 25px;
  line-height: 1;
  position: relative;
  cursor: pointer;
  z-index: 5;
  border: none;
  outline: none !important;
  background: $accent;
  color: $white;

  span {
    position: absolute;
    left: 0;
    top: 50%;
    height: 100%;
    width: 100%;
    text-align: center;
    margin-top: -15px;

    i {
      font-size: 20px;
      line-height: 29px;
      color: $white;
    }
  }
}

// CARDS
.card {
  .card-block {
    padding: $spacer;
  }
}

// CURSOR
.pointer {
  cursor: pointer;
}

.no-pointer {
  cursor: default;
}

.move {
  cursor: move;
}

// DROPDOWNS
//.dropdown-item {
//  outline: none;
//}

// INPUTS
//input {
//  outline: none;
//  // text-transform: capitalize;
//}

select {
  outline: none;
  -webkit-appearance: none;
  // text-transform: capitalize;
}

medium-editor {
  outline: none;
  width: 100%;
}

// EDITING
.lockable-input {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}

.unlocked-border:hover,
.unlocked-border:active,
.unlocked-border:focus {
  background: rgba(0, 0, 0, .03) !important;
}

.unlocked-underline:hover {
  background: rgba(0, 0, 0, .03) !important;
}

.unlocked-underline,
.unlocked-underline::placeholder {
  text-decoration-color: $primary-mid !important;
}

.unlocked-underline-light:hover,
.unlocked-underline-light:focus {
  background: rgba(255, 255, 255, 0.1) !important;
}

:host ::ng-deep input::-webkit-datetime-edit-fields-wrapper {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// HEADERS
.acc-header {
  background-color: $gray-200;
  cursor: pointer;
  margin-bottom: 5px;
  padding: 5px;
  width: 100%;
}

.header-under-dash {
  .dash {
    width: 30px;
    right: 0;
    left: 0;
    margin: auto;
    border-bottom: 2px solid $gray-300;
  }

  .dash-long {
    width: 75px;
    border-bottom: 2px solid $gray-300;
  }
}

.section-header-font {
  font-family: $fonttype_aviaconnect;
  font-size: $font-h2;
  color: $gray-800;
}

// (TEXT) HIGHLIGHTING OFF

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; //Chrome & Opera
}

// LABELS
.tab-heading {
  border-bottom: 1px solid $gray-300;
  padding: 0;
  font-weight: $font-weight-bold;
  width: 100%;
  padding-bottom: map-get($spacers, 2);
}

.section-heading {
  padding: 0;
  font-weight: $font-weight-bold;
  width: 100%;
}


label {
  color: $gray-800;
  font-weight: $font-weight-bold;
}

.avia-label-sm {
  text-transform: uppercase;
  font-size: $font1;
  letter-spacing: 1.5px;
  font-family: $fonttype_aviaconnect;
  font-weight: 300;
}

.avia-label-md {
  text-transform: uppercase;
  font-family: $fonttype_aviaconnect;
  font-size: $font2;
  letter-spacing: 1.75px;
  font-weight: $font-weight-bold;
}

// LIST GROUPS
.search-container {
  // background: $gray-100;
  height: calc(100% - 40px);
  overflow: auto;
  padding: $spacer;
  position: absolute;
  width: 100%;

  .result {
    cursor: pointer;
    line-height: 20px;

    .title {
      color: $accent;
      font-size: 16px;
      overflow: hidden;
      padding: map-get($spacers, 1), 0;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .description,
    .cl_card_details {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .divider {
      border-bottom: solid 1px $gray-200;
      margin: map-get($spacers, 2), 0;
    }

    a {
      color: $gray-800;
      text-decoration: inherit;
    }
  }
}

.bottom-line {
  border-bottom: 1px solid $gray-300;
  padding: 0;
}

.pill-list {
  display: inline-flex; //.d-inline-flex
  margin-right: map-get($spacers, 2); //.mr-2
  margin-top: map-get($spacers, 2); //.mt-2
  max-width: 100%; //.mw-100

  .pill-list-body {
    align-items: center !important; //.align-items-center
    background: $gray-200; //.bg-gray-200
    border-radius: map-get($spacers, 1) !important; //.rounded
    display: flex; //.d-flex
    max-width: 100%; //.mw-100

    .pill-list-icon {
      border-radius: map-get($spacers, 1) !important; //.rounded
      height: 22px;
      overflow: hidden;
      margin-left: 2px;
      width: 22px;

      & > img {
        height: inherit;
        width: inherit;
      }
    }

    .pill-list-text {
      color: $gray-800; //.text-gray-800
      font-size: $font-size-sm;
      overflow: hidden; //.text-truncate
      padding: map-get($spacers, 1) map-get($spacers, 1) map-get($spacers, 1) map-get($spacers, 2); //.p-1 .pl-2
      text-overflow: ellipsis; //.text-truncate
      white-space: nowrap; //.text-truncate

      & > i.material-icons {
        font-size: 12px; //.md-12
        margin-right: map-get($spacers, 1);
        position: relative;
        top: 2px;
      }
    }

    .pill-list-close {
      color: $gray-800; //.text-gray-800
      cursor: pointer; //.pointer
      font-size: $font-size-lg;
      padding-left: map-get($spacers, 1); //.px-1
      padding-right: map-get($spacers, 1); //.px-1
    }
  }
}

// MATERIAL ICONS
.material-icons {
  &.md-8 {
    font-size: 8px;
  }

  &.md-10 {
    font-size: 10px;
  }

  &.md-12 {
    font-size: 12px;
  }

  &.md-14 {
    font-size: 14px;
  }

  &.md-16 {
    font-size: 16px;
  }

  &.md-18 {
    font-size: 18px;
  }

  &.md-20 {
    font-size: 20px;
  }

  &.md-22 {
    font-size: 22px;
  }

  //&.md-24 { font-size: 24px; } // DEFAULT

  &.md-30 {
    font-size: 30px;
  }

  &.md-32 {
    font-size: 32px;
  }

  &.md-36 {
    font-size: 36px;
  }

  &.md-48 {
    font-size: 48px;
  }
}

// NOTIFICATIONs
.simple-notification.error {
  background: $danger !important;

  .sn-progress-loader span {
    background: darken($danger, 10%) !important;
  }
}

.simple-notification.info {
  background: $primary !important;

  .sn-progress-loader span {
    background: darken($primary, 10%) !important;
  }
}

.simple-notification.success {
  background: $accent !important;

  .sn-progress-loader span {
    background: darken($accent, 10%) !important;
  }
}

.simple-notification.warning {
  background: $warning !important;

  .sn-progress-loader span {
    background: darken($accent, 10%) !important;
  }
}

.no-search-results-filler {
  height: 50vh;
  position: relative;
  text-align: center;

  .title {
    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;

    i {
      font-size: 50px;
    }
  }
}

.coming-soon {
  font-size: 32px;
  margin: 25px;
  text-align: center;
}

// Simple search UI please note there is both mobile and desktop here. Also use the appropriate classed for text/no-text
.search-box-wrapper {
  margin-left: 0;
  margin-right: 0;

  .no-text-section,
  .text-section {
    display: inline-flex;
  }

  .text-section {
    .text-wrapper {
      width: calc(100% - 200px);
      word-wrap: break-word;

      p {
        line-height: 26px;
      }
    }
  }

  //.no-text-section {}
  .img-wrapper-text {
    position: relative;
    height: 150px;
    width: 200px;
  }

  img {
    height: 120px;
    width: auto;
  }

  .img-large {
    height: 200px;
    width: auto;
  }

  .img-xl {
    height: 250px;
    width: auto;
    left: 0;
    right: 0;
    margin: auto;
  }

  .img-1 {
    position: absolute;
  }

  .img-2 {
    position: absolute;
    top: 10px;
    left: 30px;
  }
}

@include media-breakpoint-down(xs) {
  .search-box-wrapper {
    .text-section {
      display: block;

      .img-wrapper-text {
        left: 0;
        right: 0;
        margin: auto;
      }

      .text-wrapper {
        width: 100%;
      }
    }

    .img-wrapper-no-text {
      height: auto;
      width: 300px;
    }

    .no-text-section {
      .img-large {
        height: 120px;
        width: auto;
      }

      .img-xl {
        height: auto;
        width: 300px;
      }
    }
  }
}

// Padding

.depth-0 {
  padding-left: 0em;
}

.depth-1 {
  padding-left: .5em;
}

.depth-2 {
  padding-left: 1em;
}

.depth-3 {
  padding-left: 1.5em;
}

.depth-4 {
  padding-left: 2em;
}

.depth-5 {
  padding-left: 2.5em;
}

.depth-6 {
  padding-left: 3em;
}

// SCROLLBARS
.force-scrollbar {
  &::-webkit-scrollbar {
    width: 9px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.08);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 5px;
    -webkit-border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, 0.05);
  }
}

.force-scrollbar.force-scrollbar-x {
  overflow-x: auto;
}

.force-scrollbar.force-scrollbar-y {
  overflow-y: auto;
}

// TAGS
.tag-success {
  background: $accent;
}

// TEXT FORMATTING
.text-truncate-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

.text-truncate-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
}

// fade out the content, instead of ellipsis
.text-truncate-fade {
  max-height: 6em;
  overflow: hidden;
  position: relative;
  display: block;
  //line-height: 1.5em;
  //outline: solid 1px red;
  //background-color: white;
}

// fade out the content, instead of ellipsis
.text-truncate-fade::before {
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 50%);
  content: '\200C';
  display: block;
  position: absolute;
  right: 0;
  top: 4.5em;
  width: 75%;
  text-align: center;
}

.multi-select-wrapper {
  height: 34px;

  i {
    line-height: 34px;
  }
}

.alt-gray-rows {
  background: $white;
}

.alt-gray-rows:nth-child(even) {
  background: $gray-100;
}

.resize-none {
  resize: none;
}

h6 {
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.line-row {
  border-bottom: 1px solid $gray-300;
}

.strike-text {
  text-decoration: line-through;
}

table:not(#rolesDisplay) {
  font-size: 12px !important;

  tr {

    th,
    td {
      border: none !important;
    }
  }
}

// top-right-bottom-left
.trbl-0 {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.collapse-box {
  ul {
    list-style-type: none;
    padding: 0;

    li {
      border-left: 4px solid $gray-200;
      padding-left: .5rem;
      margin-bottom: .5rem;
    }
  }
}

.can-rotate {
  transition: transform 300ms ease-out !important;
}
.rotate-90-cw {
  transform: rotate(90deg)
}

.more {
  color: $gray-400;
  width: 24px;
  height: 24px;
  right: 16px;
  .dropdown-item {
    color: $gray-700;
    cursor: pointer;
    i {
      vertical-align: text-bottom;
      font-size: 15px;
      color: $gray-400;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
}
